import React from "react";
import i18n from "../i18n/index.js";
import "../scss/DropdownProducto.scss";
import "../scss/bootstrap.scss";

function DropdownProducto(props) {
  return (
    <div className="DropdownProducto">
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left collapsed d-flex justify-content-space-between align-items-center align-items-md-start pl-0"
                type="button"
                data-toggle="collapse"
                data-target={`#${props.dataTarget}`}
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                {props.titleDropProduct}
                <img
                  src="/assets/arrow-bottom-blue.svg"
                  alt="flecha"
                  className="d-md-none arrow-mobile"
                />
                <div className=" d-none  d-md-flex justify-content-space-between align-items-start">
                  <div className="buttonDrop">
                    <p className="d-flex align-items-center">
                      {i18n.t("product.sectionThree.btna")}
                      <img src="/assets/arrow-bottom.svg" alt="flecha" />
                    </p>
                  </div>
                  <div className="buttonClose">
                    <p>X</p>
                  </div>
                </div>
              </button>
            </h2>
          </div>
          <div
            id={props.dataTarget}
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div className="card-body text-left">
              <div className="row">
                <div className="col-md-6">
                  <p className="bajada">{props.bajada}</p>
                  <p className="mani-conf">{props.titleOne}</p>
                  <p className="mani-bajada">{props.subtitleOne}</p>
                  <p>{props.textOne}</p>
                </div>
                <div className="col-md-6">
                  <div className="row modulo-mani mb-4">
                    <div className="col-5">
                      <p className="title-modulo">
                        {i18n.t("product.sectionThree.column01Title")}
                      </p>
                      <img
                        src="/assets/mani-varios.png"
                        className="manies"
                        alt="mani"
                      />
                    </div>
                    <div className="col-5">
                      <p className="modulo-info">
                        {i18n.t("product.sectionThree.column01box01")}
                      </p>
                      <p className="modulo-info">
                        {i18n.t("product.sectionThree.column01box02")}
                      </p>
                    </div>
                  </div>
                  <p className="divisor">
                    {i18n.t("product.sectionThree.divisor")}
                  </p>
                  <div className="row modulo-mani">
                    <div className="col-3">
                      <p className="title-modulo mb-0 align-items-center h-100">
                        {i18n.t("product.sectionThree.column01Title01")}
                        <br />
                        {i18n.t("product.sectionThree.column01Title02")}
                        <br />
                        {i18n.t("product.sectionThree.column01Title03")}
                      </p>
                    </div>
                    <div className="col-7">
                      <div className="row modulo-info align-items-center">
                        <div className="col-12 pr-0 d-flex d-md-none">
                          <p className="mb-0">
                            {i18n.t("product.sectionThree.column01box01")}
                          </p>
                        </div>
                        <div className="col-5 pr-0 d-none d-md-flex">
                          <p className="mb-0">
                            {i18n.t("product.sectionThree.divisor")}
                          </p>
                        </div>
                        <div className="col-6 col-md-3">
                          <img
                            src="/assets/mani-tachado.png"
                            className="mani-div"
                            alt="mani"
                          />
                          <img
                            src="/assets/flecha-mani.png"
                            className="icono-manidiv"
                            alt="mani"
                          />
                        </div>
                        <div className="col-6 col-md-4">
                          <p className="mb-0"> 7.5 mm</p>
                        </div>
                      </div>
                      <div className="flex-column flex-md-row position-relative">
                        <p className="position-absolute zaranda">
                          {i18n.t("product.sectionThree.zaranda")}
                        </p>
                        <div className="borde-punteado"></div>
                      </div>
                      <div className="row modulo-info align-items-center">
                        <div className="col-12 d-flex d-md-none">
                          <p className="mb-0">
                            {i18n.t("product.sectionThree.column01box02")}
                          </p>
                        </div>
                        <div className="col-5 d-none d-md-flex">
                          <p className="mb-0">
                            {i18n.t("product.sectionThree.column01box02")}
                          </p>
                        </div>
                        <div className="col-6 col-md-3">
                          <img
                            src="/assets/mani-tachado.png"
                            className="mani-div"
                            alt="mani"
                          />
                          <img
                            src="/assets/flecha-mani.png"
                            className="icono-manidiv"
                            alt="mani"
                          />
                        </div>
                        <div className="col-6 col-md-4">
                          <p className="mb-0"> 7.5 mm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <h3>Análisis</h3>
                  <p></p>
                  <img
                    className="analisis d-none d-md-flex"
                    src={i18n.t("product.sectionThree.img")}
                    alt="analisis"
                  />
                  <img
                    className="analisis-mobile d-md-none "
                    src={i18n.t("product.sectionThree.imgmobile")}
                    alt="analisis"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropdownProducto;
