import React from "react";
import CropmixButton from "./CropmixButton";
import { Bars } from "./CropmixBars";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Highlights from "./Highlights";
import i18n from "../i18n/index.js";

const CropmixFormPageTwo = ({
  submitReport,
  handleGoBack,
  calculatedData,
  setMailContacto,
  mailContacto,
  setReportModalOpen,
  isReportModalOpen,
  isSendingReport,
  setIsSendingReport,
  sendReportStatus,
  setSendReportStatus,
}) => {
  const handleModalOpen = (e) => {
    e.preventDefault();
    setReportModalOpen(true);
  };
  const handleModalClose = (e) => {
    e.preventDefault();
    setReportModalOpen(false);
  };
  const handleMailing = (e) => {
    const { target } = e;
    setMailContacto(target.value);
  };
  const handleChangeMail = () => {
    setSendReportStatus({
      status: "initial",
      message: "",
    });
    setIsSendingReport(false);
  };
  const handleSendReport = (e) => {
    e.preventDefault();
    submitReport();
  };

  const graphicBarsData1 = [
    {
      label: i18n.t("cropmix.page02.graph.bar01"),
      value: calculatedData.ingresoBrutoActual,
      color: "#598659",
    },
    {
      label: i18n.t("cropmix.page02.graph.bar02"),
      value: calculatedData.costosActual + calculatedData.alquilerActual,
      color: "#723333",
      rentValue: calculatedData.alquilerActual,
    },
    {
      label: i18n.t("cropmix.page02.graph.bar03"),
      value: calculatedData.margenBrutoActual,
      color: "#694711",
    },
  ];
  const graphicBarsData2 = [
    {
      label: i18n.t("cropmix.page02.graph.bar01"),
      value: calculatedData.ingresoBrutoMani,
      color: "#598659",
      secondValue: calculatedData.deltaIngresoBruto,
    },
    {
      label: i18n.t("cropmix.page02.graph.bar02"),
      value: calculatedData.costosMani + calculatedData.alquilerMani,
      color: "#723333",
      secondValue: calculatedData.deltaCostos + calculatedData.deltaAlquiler,
      rentValue: calculatedData.alquilerMani,
      //rentDelta: calculatedData.deltaAlquiler
    },
    {
      label: i18n.t("cropmix.page02.graph.bar03"),
      value: calculatedData.margenBrutoMani,
      color: "#694711",
      secondValue: calculatedData.deltaMargenBruto,
    },
  ];

  const createBar = (bars) => {
    return bars.map((barHeight) => {
      return (
        <span
          className="col-sm-4 barValue"
          key={barHeight.label + barHeight.value}
        >
          {barHeight.value <= 430 && (
            <div className="colValue">{barHeight.value}</div>
          )}
          {(barHeight.secondValue || barHeight.secondValue > 0) && (
            <div
              className="pt-2"
              style={{
                height: barHeight.secondValue / 15,
                backgroundColor: "#deab5f",
              }}
            ></div>
          )}
          {(barHeight.rentValue || barHeight.rentValue > 0) && (
            <div
              className=""
              style={{
                height: barHeight.rentValue / 15,
                backgroundColor: "#a24848",
              }}
            ></div>
          )}
          <div
            className="pt-1"
            style={{
              height: barHeight.secondValue
                ? (barHeight.value - barHeight.secondValue) / 15
                : barHeight.value / 15,
              backgroundColor: barHeight.color,
            }}
          >
            {barHeight.value > 430 && barHeight.value}
          </div>
          <p className="mt-2 mb-0 barValue" style={{ minHeight: "50px" }}>
            {barHeight.label}
          </p>
        </span>
      );
    });
  };

  return (
    <>
      <h5 className="mb-mt-5 pb-3 text-left">
        {i18n.t("cropmix.page02.title")}
      </h5>
      <div className="row pageTwo">
        <div className="col-md-6">
          <div className="crop-2 d-flex flex-column align-items-center p-0 mt-4">
            <Bars
              widths={[calculatedData.sojaPer, calculatedData.maizPer]}
              labels={[
                i18n.t("cropmix.page02.bars.soy"),
                i18n.t("cropmix.page02.bars.corn"),
              ]}
              colors={["#0052b5", "#152c39"]}
              labelColor={["aliceblue", "aliceblue"]}
            />
            <img
              src="/assets/arrow-bottom-blue.svg"
              alt="arrow"
              className="my-4"
              style={{ width: "50px" }}
            />
            <div
              className="row my-2 align-items-end reportBars"
              style={{ height: "300px" }}
            >
              {createBar(graphicBarsData1)}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="crop-2 d-flex flex-column align-items-center p-0 mt-4">
            <Bars
              widths={[
                calculatedData.sojaManiPer,
                calculatedData.maizManiPer,
                calculatedData.maniPer,
              ]}
              labels={[
                i18n.t("cropmix.page02.bars.soy"),
                i18n.t("cropmix.page02.bars.corn"),
                i18n.t("cropmix.page02.bars.peanut"),
              ]}
              colors={["#0052b5", "#152c39", "#deab5f"]}
              labelColor={["aliceblue", "aliceblue", "#152c39"]}
            />
            <img
              src="/assets/arrow-bottom-blue.svg"
              alt="arrow"
              className="my-4"
              style={{ width: "50px" }}
            />
            <div
              className="row my-2 align-items-end reportBars"
              style={{ height: "300px" }}
            >
              {createBar(graphicBarsData2)}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-10 my-3 pt-3 px-0 mx-0">
        <ul className="cropmixFacts">
          <li>
            <small>
              <i>{i18n.t("cropmix.page02.details.line01")}</i>
            </small>
          </li>
          <li>
            <small>
              <i>{i18n.t("cropmix.page02.details.line02")}</i>
            </small>
          </li>
          <li>
            <small>
              <i>{i18n.t("cropmix.page02.details.line03")}</i>
            </small>
          </li>
          <li>
            <small>
              <i>{i18n.t("cropmix.page02.details.line04")}</i>
            </small>
          </li>
        </ul>
      </div>
      <span className="d-flex buttonContainer" id="filtrar">
        <CropmixButton
          label={i18n.t("cropmix.page02.backButton")}
          containerClassName="d-flex flex-fill mt-4 justify-content-start w-75"
          size="w-50 p-2"
          buttonAction={handleGoBack}
          buttonDirection="row-reverse"
        />
        <CropmixButton
          label={i18n.t("cropmix.page02.sendButton")}
          containerClassName="d-flex flex-fill mt-4 justify-content-end w-75"
          color="dark"
          size="w-50 p-2"
          buttonAction={handleModalOpen}
        />
      </span>

      <Dialog
        open={isReportModalOpen}
        onClose={handleModalClose}
        aria-labelledby="form-dialog-title"
        className="reportModalInput"
      >
        <DialogTitle id="form-dialog-title">
          <Highlights
            classNameH="interna"
            firstLineH={i18n.t("cropmix.page02.dialog.titleFirstLineHighlight")}
            firstLine={i18n.t("cropmix.page02.dialog.titleFirstLine")}
          />
        </DialogTitle>
        <DialogContent style={{ maxWidth: "none" }}>
          {isSendingReport || sendReportStatus.status !== "initial" ? (
            <DialogContentText>
              <div
                className="py-2 d-flex justify-content-between fetchMessage"
                style={{ width: "100%" }}
              >
                <span className="text-left">{sendReportStatus.message}</span>
                {sendReportStatus.status === "error" && (
                  <span
                    className="text-right changeMail"
                    onClick={handleChangeMail}
                  >
                    {i18n.t("cropmix.page02.dialog.changeMailButton")}
                  </span>
                )}
              </div>
              <div className="py-2" style={{ width: "100%" }}>
                <LinearProgress
                  style={{
                    backgroundColor:
                      sendReportStatus.status === "pending"
                        ? "#deab5f"
                        : sendReportStatus.status === "ok"
                        ? "#28a745"
                        : "#dc3545",
                    height: "5px",
                  }}
                  variant={
                    sendReportStatus.status !== "pending"
                      ? "determinate"
                      : "indeterminate"
                  }
                  value={sendReportStatus.status === "pending" ? 0 : null}
                />
              </div>
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                {i18n.t("cropmix.page02.dialog.contentText")}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                onChange={handleMailing}
                value={mailContacto}
              />
            </>
          )}
          <section className="Cropmix report" id="element-to-print">
            <div className="space"></div>
            <div className="container mb-2 pb-2">
              <div className="d-md-flex justify-content-between image">
                <div className="ml-5">
                  {i18n.language === "es" ? (
                    <img
                      src="/assets/logo.svg"
                      alt="Logo Maniseros"
                      style={{ width: "300px" }}
                    />
                  ) : (
                    <img
                      className="logomaniseros-en"
                      src="/assets/logo-en.svg"
                      alt="Maniseros Logo"
                      style={{ width: "300px" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="container mb-5 pb-3 graphContainer">
              <div className="formContainer bg-white mb-5">
                <div className="col">
                  <div className="row pageTwo">
                    <div className="col-md-6">
                      <div className="crop-2 d-flex flex-column align-items-center p-0 mt-4">
                        <Bars
                          widths={[
                            calculatedData.sojaPer,
                            calculatedData.maizPer,
                          ]}
                          labels={[
                            i18n.t("cropmix.page02.bars.soy"),
                            i18n.t("cropmix.page02.bars.corn"),
                          ]}
                          colors={["#0052b5", "#152c39"]}
                          labelColor={["aliceblue", "aliceblue"]}
                        />
                        <img
                          src="/assets/arrow-bottom-blue.svg"
                          alt="arrow"
                          className="my-4"
                          style={{ width: "50px" }}
                        />
                        <div
                          className="row my-2 align-items-end reportBars"
                          style={{ height: "300px" }}
                        >
                          {createBar(graphicBarsData1)}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="crop-2 d-flex flex-column align-items-center p-0 mt-4">
                        <Bars
                          widths={[
                            calculatedData.sojaManiPer,
                            calculatedData.maizManiPer,
                            calculatedData.maniPer,
                          ]}
                          labels={[
                            i18n.t("cropmix.page02.bars.soy"),
                            i18n.t("cropmix.page02.bars.corn"),
                            i18n.t("cropmix.page02.bars.peanut"),
                          ]}
                          colors={["#0052b5", "#152c39", "#deab5f"]}
                          labelColor={["aliceblue", "aliceblue", "#152c39"]}
                        />
                        <img
                          src="/assets/arrow-bottom-blue.svg"
                          alt="arrow"
                          className="my-4"
                          style={{ width: "50px" }}
                        />
                        <div
                          className="row my-2 align-items-end reportBars"
                          style={{ height: "300px" }}
                        >
                          {createBar(graphicBarsData2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 my-3 pt-3 px-0 mx-0">
                  <ul className="cropmixFacts">
                    <li>
                      <small>
                        <i>{i18n.t("cropmix.page02.details.line01")}</i>
                      </small>
                    </li>
                    <li>
                      <small>
                        <i>{i18n.t("cropmix.page02.details.line02")}</i>
                      </small>
                    </li>
                    <li>
                      <small>
                        <i>{i18n.t("cropmix.page02.details.line03")}</i>
                      </small>
                    </li>
                    <li>
                      <small>
                        <i>{i18n.t("cropmix.page02.details.line04")}</i>
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </DialogContent>
        <DialogActions>
          <CropmixButton
            label={i18n.t("cropmix.page02.dialog.backButton")}
            containerClassName="d-flex flex-fill mt-4 justify-content-start"
            size="w-50 p-2"
            buttonAction={handleModalClose}
            buttonDirection="row-reverse"
          />
          <CropmixButton
            label={i18n.t("cropmix.page02.dialog.sendButton")}
            containerClassName="d-flex flex-fill mt-4 justify-content-end"
            color="dark"
            size="w-50 p-2"
            disabled={!mailContacto || isSendingReport ? true : false}
            buttonAction={handleSendReport}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropmixFormPageTwo;
