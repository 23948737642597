import LinearProgress from "@material-ui/core/LinearProgress";
import Highlights from "./Highlights";
import i18n from "../i18n/index.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import '../scss/Alerts.scss'

const Alerts = ({isLoading, isOK, message, toggleAlert }) => {

  console.log('open modal');

  return (
    <Dialog
        open={toggleAlert}
        onClose={console.log('Alert closed')}
        aria-labelledby="form-dialog-title"
        className="reportModalInput Alert"
      >
        <DialogTitle id="form-dialog-title">
          <Highlights
            classNameH="interna"
            firstLineH='Enviando In'
            firstLine='formación'
          />
        </DialogTitle>
        <DialogContent style={{ maxWidth: "none" }}>
          
            <DialogContentText>
              <div
                className="py-2 d-flex justify-content-between fetchMessage"
                style={{ width: "100%" }}
              >
                <span className="text-left">
                  {isLoading ? message.pending : isOK ? message.success : message.error}
                </span>
              </div>
              <div className="py-2" style={{ width: "100%" }}>
                <LinearProgress
                  style={{
                    backgroundColor:
                      isLoading? "#deab5f"
                      : 
                      isOK  ? "#28a745"
                      : "#dc3545",
                    height: "5px",
                  }}
                  variant={
                    isLoading ? "indeterminate"
                    : 
                    "determinate"
                  }
                  value={isLoading ? 0 : null}
                />
              </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>
  )
}

export default Alerts