import React from "react";
import Home from "./components/Home";
import SobreMani from "./components/SobreMani";
import Programas from "./components/Programas";
import Producto from "./components/Producto";
import Contacto from "./components/Contacto";
import Fedea from "./components/Fedea";
import Basf from "./components/Basf";
import Bayer from "./components/Bayer";
import Corteva from "./components/Corteva";
import Nova from "./components/Nova";
import OroVerde from "./components/OroVerde";
import Summit from "./components/Summit";
import Syngenta from "./components/Syngenta";
import Sipcam from "./components/Sipcam";
import Stoller from "./components/Stoller";
import Cropmix from "./components/Cropmix";
import CropmixReport from "./components/CropmixReport";
import Blog from "./components/Blog";
import BlogNote from "./components/BlogNote";
import AviacionProdeman from "./components/AviacionProdeman";
import Upl from "./components/Upl";
import Fmc from "./components/Fmc";

import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";

function Routes() {
  return (
    <div className="Routes">
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/sobremani">
            <SobreMani />
          </Route>
          <Route path="/programas">
            <Programas />
          </Route>
          <Route path="/producto">
            <Producto />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
          <Route path="/fedea">
            <Fedea />
          </Route>
          <Route path="/basf">
            <Basf />
          </Route>
          <Route path="/bayer">
            <Bayer />
          </Route>
          <Route path="/corteva">
            <Corteva />
          </Route>
          <Route path="/nova">
            <Nova />
          </Route>
          <Route path="/oroverde">
            <OroVerde />
          </Route>
          <Route path="/summit">
            <Summit />
          </Route>
          <Route path="/syngenta">
            <Syngenta />
          </Route>
          <Route path="/sipcam">
            <Sipcam />
          </Route>
          <Route path="/stoller">
            <Stoller />
          </Route>
          <Route path="/upl">
            <Upl />
          </Route>
          <Route path="/fmc">
            <Fmc />
          </Route>
          <Route path="/cropmix">
            <Cropmix />
          </Route>
          <Route path="/report">
            <CropmixReport />
          </Route>
          <Route path="/blog/note/1">
            <BlogNote postNumber={0} />
          </Route>
          <Route path="/blog/note/2">
            <BlogNote postNumber={1} />
          </Route>
          <Route path="/blog/note/3">
            <BlogNote postNumber={2} />
          </Route>
          <Route path="/blog/note/4">
            <BlogNote postNumber={3} />
          </Route>
          <Route path="/blog/note/5">
            <BlogNote postNumber={4} />
          </Route>
          <Route path="/blog/note/6">
            <BlogNote postNumber={5} />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          {
            <Route path="/aviacion">
              <AviacionProdeman />
            </Route>
          }
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
export default Routes;
