import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
// import CarouselAliados from './CarouselAliados'
import Footer from "./Footer";
import { Link } from "react-router-dom";
// import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/Aliados.scss";

function Basf() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img className="banner-hero" src="/assets/hero-nova.png" alt="hero" />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo-nova.png"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.nova.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t("partners.nova.sectionOne.titleFirstLine")}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">
                  {i18n.t("partners.nova.sectionTwo.title01")}
                </p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.nova.sectionTwo.desc01")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.nova.sectionTwo.desc02")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.nova.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.nova.sectionThree.box01.title")}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa4=" "
                  imgpartner="nova-semilla"
                  imgparter01="/assets/nova-01.png"
                  href01="https://laboratorios-nova.com/web/terapicodesemillas/origen/"
                  imgpartner1="nova-semilla"
                  imgparter02="/assets/nova-08.png"
                  href02="https://laboratorios-nova.com/web/inoculantes/pronova/"
                  imgpartner2="nova-semilla"
                  imgparter03="/assets/nova-09.png"
                  href03="https://laboratorios-nova.com/web/otros/grower/"
                  imgpartner3="nova-semilla"
                  imgparter04="/assets/nova-10.png"
                  href04="https://laboratorios-nova.com/web/terapicodesemillas/options-advance-fs/"
                  imgpartner4="optionadvance"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.nova.sectionThree.box02.title")}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa12=" "
                  imgpartner="nova-one"
                  imgparter01="/assets/nova-02.png"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.nova.sectionThree.box03.title")}
                  imgEtapa="../assets/planta3.jpg"
                  claseImgEtapa12=" "
                  imgpartner="nova-two"
                  imgparter01="/assets/nova-03.png"
                  href01="https://laboratorios-nova.com/web/herbicida/catch/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa="V6 - R3"
                  imgEtapa="../assets/planta6.jpg"
                  claseImgEtapa7=" "
                  imgpartner="nova-semilla nova-space"
                  imgparter01="/assets/nova-04.png"
                  href01="https://laboratorios-nova.com/web/fungicida/abrigo/"
                  imgparter02="/assets/nova-14.png"
                  href02="https://laboratorios-nova.com/web/fungicida/douglas/"
                  imgparter03="/assets/nova-15.png"
                  href03="https://laboratorios-nova.com/web/fungicida/highness-2/"
                  imgparter04="/assets/nova-16.png"
                  href04="https://laboratorios-nova.com/web/fungicida/linx/"
                  imgparter05="/assets/nova-17.png"
                  href05="https://laboratorios-nova.com/web/fungicida/freedom/"
                  imgparter06="/assets/nova-18.png"
                  href06="https://laboratorios-nova.com/web/fungicida/paradise-fit-2/"
                  imgparter07="/assets/nova-19.png"
                  href07="https://laboratorios-nova.com/web/fungicida/midget/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa="V2 - V4"
                  imgEtapa="../assets/planta4.jpg"
                  claseImgEtapa4=" "
                  imgparter01="/assets/nova-05.png"
                  href01="https://laboratorios-nova.com/web/herbicida/fokker/"
                  imgpartner1="nova-semilla"
                  imgparter02="/assets/nova-11.png"
                  href02="https://laboratorios-nova.com/web/herbicida/velocity/"
                  imgpartner2="nova-semilla"
                  imgparter03="/assets/nova-12.png"
                  href03="https://laboratorios-nova.com/web/herbicida/xcalibur/"
                  imgpartner3="nova-semilla"
                  imgparter04="/assets/nova-13.png"
                  href04="https://laboratorios-nova.com/web/herbicida/cletodim-nova/"
                  imgpartner4="cletodim"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa="R1 - R2"
                  imgEtapa="../assets/planta6.jpg"
                  claseImgEtapa12=" "
                  imgpartner="nova-two"
                  imgparter01="/assets/nova-06.png"
                  href01="https://laboratorios-nova.com/web/fungicida/iridium-3/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa="R1 - R4"
                  imgEtapa="../assets/planta8.jpg"
                  claseImgEtapa12=" "
                  imgpartner="nova-two"
                  imgparter01="/assets/nova-07.png"
                  href01="https://laboratorios-nova.com/web/bioestimulante/tonic/"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Basf;
