import React from "react";
import Video from "./Video";
import "../scss/SobreMani.scss";
import i18n from "../i18n/index.js";

function SobreMani() {
  return (
    <>
      <div className="SobreMani">
        <div className="space"></div>
        <div className="position-relative">
          <div className="wave-01">
            <img src="/assets/wave-gold.svg" alt="wave" />
          </div>
          <div className="wave-02">
            <img src="/assets/wave-gold.svg" alt="wave" />
          </div>
        </div>
        <div className="video">
          <Video />
        </div>
        <div className="container">
          <p className="nameSection">{i18n.t("lyfecycle.title")}</p>
        </div>
      </div>
    </>
  );
}

export default SobreMani;
