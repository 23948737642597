import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/Aliados.scss";

function Stoller() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img
            className="banner-hero"
            src="/assets/stoller-hero.png"
            alt="hero"
          />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo_stoller.svg"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.stoller.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t("partners.stoller.sectionOne.titleFirstLine")}
                secondLineH={i18n.t(
                  "partners.stoller.sectionOne.titleSecondLineHighlight"
                )}
                secondLine={i18n.t(
                  "partners.stoller.sectionOne.titleSecondLine"
                )}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="row px-3">
                <div className="col-12 col-md-6 px-0 pl-md-3 d-flex align-items-center">
                  <p className="title-presentacion mb-3 mb-md-0">
                    {i18n.t("partners.stoller.sectionTwo.title01")}
                    <br />
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.stoller.sectionTwo.desc01")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.stoller.sectionTwo.desc02")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.stoller.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.stoller.sectionThree.box01.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa32
                  imgpartner="stoller-partner"
                  imgparter01={i18n.t(
                    "partners.stoller.sectionThree.box01.img01"
                  )}
                  href01="https://stoller.com.ar/productos/stimulate-mani/"
                  imgparter02={i18n.t(
                    "partners.stoller.sectionThree.box01.img02"
                  )}
                  href02="https://stoller.com.ar/productos/masterfix-max-mani/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.stoller.sectionThree.box02.title"
                  )}
                  imgEtapa="../assets/planta0.jpg"
                  claseImgEtapa12
                  imgpartner="stoller-partner"
                  imgparter01={i18n.t(
                    "partners.stoller.sectionThree.box02.img01"
                  )}
                  href01="https://stoller.com.ar/productos/masterfix-l-mani/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.stoller.sectionThree.box03.title"
                  )}
                  imgEtapa="../assets/planta4.jpg"
                  claseImgEtapa12
                  imgpartner="stoller-partner"
                  imgparter01={i18n.t(
                    "partners.stoller.sectionThree.box03.img01"
                  )}
                  href01="https://stoller.com.ar/productos/stimulate/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.stoller.sectionThree.box04.title"
                  )}
                  imgEtapa="../assets/planta6.jpg"
                  claseImgEtapa12
                  imgpartner="stoller-partner"
                  imgparter01={i18n.t(
                    "partners.stoller.sectionThree.box04.img01"
                  )}
                  href01="https://stoller.com.ar/productos/x-cyte-calcium/"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.stoller.sectionThree.box05.title"
                  )}
                  imgEtapa="../assets/planta6.jpg"
                  claseImgEtapa12
                  imgpartner="stoller-partner"
                  imgparter01={i18n.t(
                    "partners.stoller.sectionThree.box05.img01"
                  )}
                  href01="https://stoller.com.ar/productos/nitrate-balancer/"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Beneficios py-5">
          <img
            src="/assets/gold-beneficios.svg"
            alt="deco"
            className="gold-beneficios"
          />
          <div className="container">
            <h4 className="mb-4">
              {i18n.t("partners.stoller.sectionFour.title")}
            </h4>
            <div className="row">
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <img src="/assets/logo_virtualfield.svg" alt="multiplica" />
                    <p>
                      {i18n.t("partners.stoller.sectionFour.box01.content")}
                    </p>
                    <a
                      className="dest vervideo"
                      href="https://www.youtube.com/watch?v=ktbRZjmKwyE"
                    >
                      {i18n.t("partners.stoller.sectionFour.box01.cta")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <a
                  href="https://wa.me/5493517687995"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="cuadro-container">
                    <div className="d-flex flex-column">
                      <img src="/assets/whatsapp.png" alt="multiplica" />
                      <p>
                        {i18n.t("partners.stoller.sectionFour.box02.content")}
                      </p>
                      <p className="dest">
                        {i18n.t("partners.stoller.sectionFour.box02.cta")}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Stoller;
