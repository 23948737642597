import i18n from '../i18n/index.js'

const sobremaniinfo = [
    {
        "id": 1,
        "idDropdown":"uno",
        "Name": i18n.t('lyfecycle.day00.title'),
        "Gdu":"0°",
        "Base":"11°",
        "info":"",
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja01.svg",
        "Progreso": "0%",
        "BeneficioTitle":"",
        "BeneficioInfo":"",
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
        
    },
    {
        "id": 2,
        "idDropdown":"dos",
        "Name":i18n.t('lyfecycle.day01.title'),
        "Gdu":"0°",
        "Base":"11°",
        "info":"",
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja01.svg",
        "Progreso": "0%",
        "BeneficioTitle":"",
        "BeneficioInfo":"",
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },{
        "id": 3,
        "idDropdown":"tres",
        "Name":i18n.t('lyfecycle.day12.title'),
        "Gdu":"150°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day12.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja05.svg",
        "Progreso": "7%",
        "BeneficioTitle":"",
        "BeneficioInfo":"",
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id": 4,
        "idDropdown":"cuatro",
        "Name":i18n.t('lyfecycle.day15.title'),
        "Gdu":"180°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day15.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja10.svg",
        "Progreso": "9%",
        "BeneficioTitle":"",
        "BeneficioInfo":"",
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":5,
        "idDropdown":"cinco",
        "Name":i18n.t('lyfecycle.day18.title'),
        "Gdu":"220°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day18.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja13.svg",
        "Progreso": "15%",
        "BeneficioTitle":i18n.t('lyfecycle.day18.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day18.benefics'),
        "SolucionesTitle":"Soluciones de mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":6,
        "idDropdown":"seis",
        "Name":i18n.t('lyfecycle.day35.title'),
        "Gdu":"390°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day35.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja15.svg",
        "Progreso": "21%",
        "BeneficioTitle":i18n.t('lyfecycle.day35.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day35.benefics'),
        "SolucionesTitle":"Soluciones de mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":7,
        "idDropdown":"siete",
        "Name":i18n.t('lyfecycle.day40.title'),
        "Gdu":"390°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day40.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja18.svg",
        "Progreso": "22%",
        "BeneficioTitle":i18n.t('lyfecycle.day40.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day40.benefics'),
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":8,
        "idDropdown":"ocho",
        "Name":i18n.t('lyfecycle.day50.title'),
        "Gdu":"390°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day50.cont'),
        "Apartado":"",
        "LogoApartado":"",
        "Progresoimg":"/assets/maniporcetaja20.svg",
        "Progreso": "31%",
        "BeneficioTitle":i18n.t('lyfecycle.day50.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day50.benefics'),
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":9,
        "idDropdown":"nueve",
        "Name":i18n.t('lyfecycle.day60.title'),
        "Gdu":"390°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day60.cont'),
        "Apartado":"",
        "LogoApartado":"",
        "Progresoimg":"/assets/maniporcetaja30.svg",
        "Progreso": "38%",
        "BeneficioTitle":i18n.t('lyfecycle.day60.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day60.benefics'),
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":10,
        "idDropdown":"diez",
        "Name":i18n.t('lyfecycle.day70.title'),
        "Gdu":"780°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day70.cont'),
        "Apartado":"",
        "LogoApartado":"",
        "Progresoimg":"/assets/maniporcetaja40.svg",
        "Progreso": "43%",
        "BeneficioTitle":i18n.t('lyfecycle.day70.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day70.benefics'),
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":11,
        "idDropdown":"once",
        "Name":i18n.t('lyfecycle.day80.title'),
        "Gdu":"780°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day80.cont'),
        "Apartado":"",
        "LogoApartado":"",
        "Progresoimg":"/assets/maniporcetaja50.svg",
        "Progreso": "50%",
        "BeneficioTitle":"",
        "BeneficioInfo":"",
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":12,
        "idDropdown":"doce",
        "Name":i18n.t('lyfecycle.day85.title'),
        "Gdu":"970°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day85.cont'),
        "Apartado":"",
        "LogoApartado":"",
        "Progresoimg":"/assets/maniporcetaja60.svg",
        "Progreso": "53%",
        "BeneficioTitle":i18n.t('lyfecycle.day85.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day85.benefics'),
        "SolucionesTitle":"Soluciones de Mercado",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":13,
        "idDropdown":"trece",
        "Name":i18n.t('lyfecycle.day120.title'),
        "Gdu":"970°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day120.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja70.svg",
        "Progreso": "75%",
        "BeneficioTitle":"",
        "BeneficioInfo":"",
        "SolucionesTitle":"",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    },
    {
        "id":14,
        "idDropdown":"catorce",
        "Name":i18n.t('lyfecycle.day160.title'),
        "Gdu":"1660°",
        "Base":"11°",
        "info":i18n.t('lyfecycle.day160.cont'),
        "Apartado":"Sanidad Vegetal",
        "LogoApartado":"/assets/logo-oro-verde-gris.png",
        "Progresoimg":"/assets/maniporcetaja100.svg",
        "Progreso": "100%",
        "BeneficioTitle":i18n.t('lyfecycle.day160.titlebenefits'),
        "BeneficioInfo":i18n.t('lyfecycle.day160.benefics'),
        "SolucionesTitle":"",
        "Logo01":"/assets/logo-basf.png",
        "Logo02":"/assets/logo-bayer.png",
        "Logo03":"/assets/logo-corteva.png",
        "Logo04":"/assets/logo-nova.png",
        "Logo05":"/assets/logo-summit.svg",
        "Logo06":"/assets/logo-syngenta.svg",
        "LogoTo01":"/basf",
        "LogoTo02":"/bayer",
        "LogoTo03":"/corteva",
        "LogoTo04":"/nova",
        "LogoTo05":"/summit",
        "LogoTo06":"/syngenta",
        "Programas":"Programas",
        "fedea":"/assets/logo-fedea.svg",
        "Logo-Programas":"/assets/"
    }
    // ,
    // {
    //     "id":15,
    //     "idDropdown":"quince",
    //     "Name":i18n.t('lyfecycle.day00.title'),
    //     "Gdu":"-",
    //     "Base":"-",
    //     "info":"Un fruto no dañado mostrando una coloración anaranjada de la testa y/o deterioro del clavo.",
    //     "Apartado":"",
    //     "LogoApartado":"",
    //     "Progresoimg":"/assets/maniporcetaja100.svg",
    //     "Progreso": "100%",
    //     "BeneficioTitle":"",
    //     "BeneficioInfo":"",
    //     "SolucionesTitle":"",
    //     "Logo01":"/assets/logo-basf.png",
    //     "Logo02":"/assets/logo-bayer.png",
    //     "Logo03":"/assets/logo-corteva.png",
    //     "Logo04":"/assets/logo-nova.png",
    //     "Logo05":"/assets/logo-summit.svg",
    //     "Logo06":"/assets/logo-syngenta.svg",
    //     "LogoTo01":"/basf",
    //     "LogoTo02":"/bayer",
    //     "LogoTo03":"/corteva",
    //     "LogoTo04":"/nova",
    //     "LogoTo05":"/summit",
    //     "LogoTo06":"/syngenta",
    //     "Programas":"Programas",
    //     "fedea":"/assets/logo-fedea.svg",
    //     "Logo-Programas":"/assets/"
    // }
  ]
export default sobremaniinfo;