import Note_1_img from "./Note_1_img.jpg";
import Note_2_img from "./Note_2_img.jpg";
import Note_3_img from "./Note_3_img.jpg";
import Note_4_img from "./Note_4_img.jpg";
import Note_5_img from "./Note_5_img.jpg";
import Note_6_img from "./Note_6_img.jpg";

export const blogContent = [
  {
    id: "Nota-1",
    title: "Nutrición del Sistema de Cultivos",
    subtitle:
      "Un certero diagnóstico de fertilidad, basado en el muestreo y el análisis de suelo, nos permite manejar la nutrición de un Sistema de Cultivos, maximizando la rentabilidad y cumpliendo las normativas para un cuidado óptimo de la superficie.",
    contentPreview:
      "En Prodeman, la fertilización es clave a la hora de optimizar la eficiencia de los recursos y alcanzar los máximos rendimientos. Así como también lo es, la cantidad exacta de fertilizante a utilizar y el manejo responsable de nutrientes, ajustando la fuente, dosis, momento y forma correcta.",
    content: {
      leftContent: (
        <p className="text-left pr-4 w-100">
          En Prodeman, la fertilización es clave a la hora de optimizar la
          eficiencia de los recursos y alcanzar los máximos rendimientos. Así
          como también lo es, la cantidad exacta de fertilizante a utilizar y el
          manejo responsable de nutrientes, ajustando la fuente, dosis, momento
          y forma correcta.
          <br />
          <br />
          Nuestros Programas de Fertilidad se inician con un diagnóstico
          preciso, basado en el muestreo y en el análisis de suelo.
          <br />
          <br />
          El correcto muestreo y análisis de suelo, además de indicar las
          características del mismo y la disponibilidad de nutrientes,
          contribuye a la predicción de las probabilidades de respuesta y a la
          sustentabilidad mediante la auditoría edáfica permanente. Contamos con
          numerosas herramientas digitales que ayudan a las tareas de muestreo y
          a la mejor comprensión de los datos analíticos. Para Prodeman, un buen
          diagnóstico de fertilidad es el comienzo y final de una recomendación
          concreta y acertada.
          <br />
          <br />
          Inicialmente, el Líder Técnico de Prodeman, revisa la situación
          nutricional de cada ambiente, apuntando a fertilizar el Sistema de
          Cultivos y no cada cultivo en particular. La herramienta de muestreo
          utilizada es un iPad para el apoyo digital de la dirección, acompañado
          de un equipo hidráulico de calado de quinta generación con conexión
          inalámbrica o Wireless. Dicho equipo puede accionarse desde la cabina
          de la camioneta, sin necesidad de bajarse en cada punto. Esto, otorga
          mayor concentración en la operatoria del muestreo y más precisión en
          el trabajo.
        </p>
      ),
      rightContent: (
        <p className="text-left pl-4 w-100">
          En el caso del muestreo por ambientes en las áreas de similar
          productividad, la capa resultante posterior se recorre con apps desde
          el iPad. Esto, permite realizar un sub-muestreo intensivo dentro de
          cada área o ambiente.
          <br />
          <br />
          En lo que respecta al muestreo en grilla, los Técnicos deben tomar la
          decisión de dónde colocar los puntos que serán "históricos y
          trazados". Esto quiere decir que cada vez que se realice esta
          operación el punto no cambia y se vuelve a re-muestrear con un
          intervalo de 2-4 años, con el objetivo de ver la evolución de los
          nutrientes en el suelo y poder tomar decisiones y recomendaciones de
          enmiendas con dosis variable.
          <br />
          <br />
          Con respecto a las profundidades de muestreo, en las variables como
          Fósforo, MO, pH, Azufre, Conductividad Eléctrica, Calcio y Zinc,
          utilizamos 0-20 cm, con la bondad que siempre el equipo hidráulico va
          a ingresar al suelo en la misma inclinación vertical y hasta los 20
          cm. En cambio en un muestreo manual, el cansancio y la compactación
          del suelo hacen que éste sea menos preciso. En esta etapa, muchas
          veces se realizan entre 1 o 2 determinaciones de agua útil hasta los
          1,5 mts e indirectamente, en cada determinación, el equipo va
          registrando resistencia mecánica a la penetración (compactación).
          <br />
          <br />
          Para gramíneas, se realiza un muestreo hasta los 60 cm para determinar
          los nutrientes móviles y ajustar las dosis variables de Nitrógeno.
          <br />
          <br />
          Para nosotros es importante ver la rotación y no cada cultivo en
          particular. Para ello invertimos mucho en el muestreo y diagnóstico y
          de esa manera, tener una mirada detallada del pilar fundamental para
          el sistema de cultivos junto con una auto auditoria del uso del
          recurso suelo.
        </p>
      ),
    },
    embed: (
      <iframe
        src="https://flo.uri.sh/visualisation/6092974/embed"
        title="Interactive or visual content"
        className="flourish-embed-iframe"
        frameborder="0"
        scrolling="no"
        style={{ width: "100%", height: "100%" }}
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      ></iframe>
    ),
    author: "Ing. Agr. José Folgera",
    role: "Agricultura Digital y Líder Técnico de Prodeman",
    preview: "https://public.flourish.studio/visualisation/6092974/",
    images: [Note_1_img],
  },
  {
    id: "Nota-2",
    title: "Manejo de las enfermedades en la agricultura moderna",
    subtitle:
      "El monitoreo intensivo con documentación digital, el analytics y primordialmente la agronomía como pilares de generar información útil para la toma de decisiones rentables.",
    contentPreview:
      "La viruela es una enfermedad que se puede controlar con el correcto uso de fungicidas foliares. Los productores cuentan con una gran cantidad de activos y combinaciones de fungicidas, que junto con un diagnóstico preciso del momento de la primera aplicación y de las aplicaciones posteriores, logran altas eficiencias de control. ",
    content: {
      leftContent: (
        <p className="text-left pr-4 w-100">
          El cultivo de Maní es afectado por numerosas enfermedades fúngicas que
          atacan el follaje y a órganos subterráneos en contacto con el suelo.
          En este sentido, el monitoreo intensivo con documentación digital, el
          analytics y principalmente la agronomía, son claves para un manejo más
          eficiente y seguro.
          <br />
          <br />
          En el gráfico dinámico, se observa la prevalencia de las diferentes
          enfermedades presentes en los lotes monitoreados a lo largo de la
          última campaña. En el cultivo de Maní existen numerosas enfermedades
          que afectan su productividad y calidad. Entre las enfermedades de
          suelo más importantes podemos encontrar: Sclerotinia sclerotium y S.
          minor (Tizón del Maní), Fusarium solani (Podredumbre parda de la raíz
          del Maní) y Thecaphora frezii (Carbón del Maní).
          <br />
          <br />
          Oro Verde Servicios brinda a los productores un diagnóstico preciso de
          patógenos de suelo presentes en el lote, a través de la cuantificación
          de los mismos en muestras, a fin de recomendar prácticas concretas de
          manejo. Conocer con anticipación la presencia e intensidad de los
          patógenos de suelo permite tomar decisiones sobre el manejo sanitario
          de estos lotes. Por ejemplo: 1) la elección de los lotes más
          apropiados para la producción del cultivo, 2) la elección de
          variedades más aptas en función de su comportamiento sanitario, y 3)
          especialmente las diferentes técnicas culturales y prácticas de manejo
          que existen para estas enfermedades.
          <br />
          <br />
          Por otra parte, el gráfico muestra el progreso de la viruela de Maní
          Nothopassalora personata (Cercosporidium personatum, Viruela tardía)
          de esta campaña, la enfermedad foliar más importante que tiene el
          cultivo en nuestro país.
        </p>
      ),
      rightContent: (
        <p className="text-left pl-4 w-100">
          La viruela es una enfermedad que se puede controlar con el correcto
          uso de fungicidas foliares. Los productores cuentan con una gran
          cantidad de activos y combinaciones de fungicidas, que junto con un
          diagnóstico preciso del momento de la primera aplicación y de las
          aplicaciones posteriores, logran altas eficiencias de control.
          <br />
          <br />
          Oro Verde Servicios brinda a los productores el servicio de
          cuantificación de incidencia y severidad de viruela a lo largo de todo
          el cultivo para determinar los momentos óptimos de aplicación.
          <br />
          <br />
          Es importante destacar que todos los años, en mayor o menor medida, se
          hacen presentes estas enfermedades en el cultivo. Lo importante es
          detectarlas y diagnosticarlas a tiempo para lograr una rápida y
          efectiva estrategia de manejo, asegurando la producción y la calidad
          en el cultivo.
          <br />
          <br />
          La misión de Oro Verde Servicios es brindar información útil para la
          toma de decisiones. Por eso, trabajamos en conjunto y a la par de
          Productores y Técnicos para lograr el manejo sanitario más eficiente
          para el cultivo de Maní. En equipo y cooperando unos con otros
          obtendremos los mejores resultados.
        </p>
      ),
    },
    embed: (
      <iframe
        src="https://flo.uri.sh/visualisation/6446331/embed"
        title="Interactive or visual content"
        className="flourish-embed-iframe"
        frameborder="0"
        scrolling="no"
        style={{ width: "100%", height: "100%" }}
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      ></iframe>
    ),
    author: "Ing. Agr. Julián García",
    role: "Director Oro verde Servicios Fitosanitarios",
    preview: "https://public.flourish.studio/visualisation/6446331/ ",
    images: [Note_2_img],
  },
  {
    id: "Nota-3",
    title: "Dinámica de las Malezas",
    subtitle:
      "Las malezas compiten con el cultivo por nutrientes, agua y luz; hospedan insectos y patógenos.",
    contentPreview:
      "Las malezas compiten con el cultivo por nutrientes, agua y luz; hospedan insectos y patógenos. Además, interfieren con la cosecha e incrementan los costos, reduciendo severamente el rendimiento y calidad del cultivo. El monitoreo con documentación digital y su posterior analítica, nos permite ajustar las decisiones de manejo con el objetivo de bajar los costos e insertar al Maní en un Sistema de Cultivos, de forma tal que sea beneficioso en los cultivos subsiguientes.",
    content: {
      leftContent: (
        <p className="text-left pr-4 w-100">
          Las malezas compiten con el cultivo por nutrientes, agua y luz;
          hospedan insectos y patógenos. Además, interfieren con la cosecha e
          incrementan los costos, reduciendo severamente el rendimiento y
          calidad del cultivo. El monitoreo con documentación digital y su
          posterior analítica, nos permite ajustar las decisiones de manejo con
          el objetivo de bajar los costos e insertar al Maní en un Sistema de
          Cultivos, de forma tal que sea beneficioso en los cultivos
          subsiguientes.
          <br />
          <br />
          A pesar de contar con un amplio abanico de herbicidas en el mercado,
          el manejo de malezas no deja de ser un desafío rutinario de todos los
          años. Muchos de estos productos no los podemos utilizar por cuestiones
          de registro para el uso en Maní, por ciertas exigencias de calidad o
          porque dejan residuos en grano (entre los más conocidos el 2,4 DB). A
          estas causas se le suman la baja rotación de herbicidas, la propia
          evolución de las malezas, la ayuda de maquinarias en su diseminación
          y, el objetivo de bajar el costo del cultivo utilizando la cantidad
          eficiente de cada herbicida.
          <br />
          <br />
          Al pie de la nota, podrán interactuar con el Top 20 de presencia de
          malezas en nuestros lotes y, conocer cómo se dieron los picos de
          aparición por quincena durante la campaña 2020/21. Entre ellas
          contamos con el Yuyo Colorado, Sorgo de Alepo y Eleusine, dentro de
          las principales. El Yuyo Colorado está ampliamente distribuido por
          todas las zonas, siendo la principal en encontrar. El Sorgo de Alepo y
          Eleusine están ocasionando muchos problemas, en particular las
          especies resistentes. También, ocupando los primeros lugares, está el
          Maíz Guacho y la Soja Guacha.
          <br />
          <br />
          La herramienta principal con la cual contamos para el manejo de
          malezas es el pre-emergente bien realizado. En esta campaña obtuvimos
          muy buenos resultados con el uso de flumioxazin, que nos protege muy
          bien del Yuyo Colorado, y piroxasulfone para combatir el Sorgo de
          Alepo de semilla y Eleusine. Es fundamental que los principios activos
          se incorporen con una lluvia mayor a los 15 mm. También se aconseja
          hacerlo antes de la siembra para evitar cualquier tipo de
          fitotoxicidad y garantizar la incorporación del producto al suelo. Con
          esto logramos detener las primeras camadas de nacimiento.
        </p>
      ),
      rightContent: (
        <p className="text-left pl-4 w-100">
          En post-emergencia tuvimos buenos resultados agregando una dosis de
          s-metolaclor junto al lactofen, controlando los escapes del
          pre-emergente para Yuyo Colorado y solapando residuales con el
          s-metolaclor, prolongando así el control de malezas.
          <br />
          <br />
          Para Sorgo de Alepo y Eleusine, contamos con graminicidas de los
          “DIM”, usando principalmente cletodim para su control.
          <br />
          <br />
          Cabe destacar que el éxito de todos estos tratamientos está muy ligado
          a las condiciones ambientales y a una muy buena calidad de aplicación.
          En esta campaña tuvimos una discontinuidad en las lluvias desde fines
          de noviembre hasta finales de diciembre, afectando directamente la
          efectividad de estos tratamientos, llegando en algunos casos a tener
          nuevos escapes y tener que reaplicar.
          <br />
          <br />
          Las malezas son y serán un tema de gran importancia para la producción
          de Maní. Nosotros, aceptamos el desafío y nos comprometemos a ganarlo
          apoyando nuestras decisiones en datos de monitoreo, la documentación
          digital y haciendo eficientes las aplicaciones.
        </p>
      ),
    },
    embed: (
      <iframe
        src="https://flo.uri.sh/visualisation/6014606/embed"
        title="Interactive or visual content"
        className="flourish-embed-iframe"
        frameborder="0"
        scrolling="no"
        style={{ width: "100%", height: "100%" }}
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      ></iframe>
    ),
    author:
      "Darío Marcelino, Daniel Buffa, Juan Manuel Fernández, Fabián Faucher, Edgar Cerioni, Jorge Ficco",
    role: "Líderes Técnicos",
    preview: "https://public.flourish.studio/visualisation/6014606/",
    images: [Note_3_img],
  },
  {
    id: "Nota-4",
    title: "Variedades de maní",
    subtitle: "Genética adecuada para cada situación.",
    contentPreview:
      "Por más de 20 años se sembró en Argentina una misma variedad de maní en el 80% de la superficie destinada para ello. En Prodeman, buscamos desde 2014 nuevas variedades para tener el material indicado en cada tipo de ambiente. ",
    content: {
      leftContent: (
        <p className="text-left pr-4 w-100">
          Por más de 20 años se sembró en Argentina una misma variedad de maní
          en el 80% de la superficie destinada para ello. En Prodeman, buscamos
          desde 2014 nuevas variedades para tener el material indicado en cada
          tipo de ambiente.
          <br />
          <br />
          Existen variedades de ciclo corto que llegan al óptimo de madurez a
          los 135 días, variedades de ciclo intermedio las cuales llegan a su
          óptimo a los 145-150 días y también, variedades de ciclo largo con
          madurez a 160-170 días.
          <br />
          <br />
          Sabiendo las necesidades que genera moverse a nuevas zonas productivas
          y, en búsqueda de este objetivo, trabajamos con variedades de ciclo
          corto (algunas tolerantes a enfermedades) con resultados altamente
          satisfactorios.
        </p>
      ),
      rightContent: (
        <p className="text-left pl-4 w-100">
          El destino de las variedades de ciclo corto son zonas estratégicas
          tanto de ciclo como de producción y tienen la ventaja de poder
          realizar entregas tempranas de maní a la industria, aprovechar el
          transporte disponible para esa época, descomprimir el secado y la
          descarga e incluso contar con ventas anticipadas de maní.
          <br />
          <br />
          Generalmente la masificación de las variedades se produce a los 3 o 4
          años de pruebas a campo luego de evaluar rendimiento, arquitectura de
          planta, índice de cosecha, comportamiento sanitario y calidad
          industrial. En Prodeman contamos con 3 o 4 variedades de diverso
          comportamiento dependiendo de la necesidad del cliente y dos nuevas
          variedades, incorporadas durante esta campaña, con diferentes
          estrategias de posicionamiento.
        </p>
      ),
    },
    embed: (
      <iframe
        src="https://flo.uri.sh/visualisation/6455349/embed"
        title="Interactive or visual content"
        className="flourish-embed-iframe"
        frameborder="0"
        scrolling="no"
        style={{ width: "100%", height: "100%" }}
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      ></iframe>
    ),
    author: "Lorena Bricci",
    role: "I+D",
    preview: "https://public.flourish.studio/visualisation/6455349/",
    images: [Note_4_img],
  },
  {
    id: "Nota-5",
    title: "Aviación Agrícola",
    subtitle:
      "Para la producción de cultivos extensivos como el maní, maíz y soja en distintas zonas geográficas, no solo hay que tener una planificación acertada, sino que también hay que ejecutarla de manera eficaz y por sobre todo eficientemente.",
    contentPreview:
      "Para la producción de cultivos extensivos como el maní, maíz y soja en distintas zonas geográficas, no solo hay que tener una planificación acertada, sino que también hay que ejecutarla de manera eficaz y por sobre todo eficientemente.",
    content: {
      leftContent: (
        <p className="text-left pr-4 w-100">
          Para la producción de cultivos extensivos como el maní, maíz y soja en
          distintas zonas geográficas, no solo hay que tener una planificación
          acertada, sino que también hay que ejecutarla de manera eficaz y por
          sobre todo eficientemente.
          <br />
          <br />
          Prodeman no es la excepción y dentro de todas las labores que
          realizamos para la producción, se destaca la protección de cultivos.
          <br />
          <br />
          Contamos con un departamento de aviación agrícola y aviones de última
          generación que están preparados para realizar grandes superficies por
          día y recorrer grandes distancias, con el fin de mantener los cultivos
          con la sanidad adecuada para expresar todo su potencial genético en el
          ambiente elegido.
          <br />
          <br />
          El trabajo mancomunado de los ingenieros agrónomos en el campo y el
          equipo aeronáutico se potencian llegando en los momentos oportunos a
          realizar los controles, garantizando una muy buena calidad de
          aplicación, respetando las dosis adecuadas y los mejores momentos del
          día.
        </p>
      ),
      rightContent: (
        <p className="text-left pl-4 w-100">
          Cuando se acercan los tiempos de cosecha, los aviones se preparan para
          salir a sembrar.
          <br />
          <br />
          Los cultivos de servicio son un pilar fundamental en la producción por
          todas las bondades que tienen y el avión puede sembrarlos en tiempo
          óptimo para que, inmediatamente después de la cosecha, ya comiencen a
          cumplir los objetivos planteados.
          <br />
          <br />
          Dada la capacidad operativa de estas aeronaves nos permitimos brindar
          servicios a cualquier persona interesada. Con la tecnología instalada,
          se puede seguir el avión en tiempo real y monitorear la labor.
          <br />
          <br />
          Próximamente estará disponible una página web en donde no solo se
          podrá solicitar una aplicación o siembra georreferenciada, sino
          también seleccionar los productos y semillas que tengamos disponibles,
          para acompañar en todas las etapas: asesoramiento-abastecimiento de
          insumos-ejecución de labor.
        </p>
      ),
    },
    embed: (
      <iframe
        src="https://flo.uri.sh/visualisation/6944594/embed"
        title="Interactive or visual content"
        className="flourish-embed-iframe"
        frameborder="0"
        scrolling="no"
        style={{ width: "100%", height: "100%" }}
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      ></iframe>
    ),
    author: "Adriano Scalise",
    role: "",
    preview: "https://public.flourish.studio/visualisation/6944594/",
    images: [Note_5_img],
  },
  {
    id: "Nota-6",
    title: "Descuento de cheques",
    subtitle:
      "El descuento de cheques es una herramienta de financiamiento que te permite, a quién lo hace, acceder a fondos de manera ágil y fácil sin la necesidad de esperar a su vencimiento.",
    contentPreview:
      "El descuento de cheques es una herramienta de financiamiento que te permite, a quién lo hace, acceder a fondos de manera ágil y fácil sin la necesidad de esperar a su vencimiento. ",
    content: {
      leftContent: (
        <p className="text-left pr-4 w-100">
          <strong>¿Qué es un cheque?</strong>
          <br />
          El cheque es una orden de pago escrita por una persona o empresa (el
          dueño del cheque) que lo entrega prometiendo el pago de cierta
          cantidad de dinero, el cual se encuentra expreso en dicho documento.
          Cuando el pago del monto se promete en un momento futuro se trata de
          un cheque de pago diferido. Por ejemplo, se establece que el cheque
          puede ser cobrado en 30 días. Quien escribe el cheque se llama
          librador y debe tener dinero disponible en la cuenta bancaria para
          poder hacer frente al mismo ya que, de lo contrario, el cheque será
          rechazado al momento de su cobro.
          <br />
          <br />
          <strong>Descuento de cheques</strong>
          <br />
          El descuento de cheques consiste en una operación en la que se permite
          cobrar un cheque diferido en el instante, sin tener que esperar a la
          fecha del cobro. Esto permite que quien recibe el cheque «adelante» su
          pago sin tener que aguardar a la fecha establecida. Esto es muy útil
          para las empresas o personas que tienen problemas de liquidez y
          necesitan el dinero para hacer frente a sus operaciones o deudas.
          Existen empresas o bancos que descuentan cheques y a cambio del mismo
          establecen una «tasa de descuento». Es decir, cobran una cierta tasa
          (o también interés) por pagar el cheque antes de su vencimiento. Por
          ejemplo, si querés descontar un cheque de $1.000 que vence en 30 días
          y el banco ofrece el descuento a una tasa de 10%, obtendrás $900
          ($1.000 del cheque netos de $100 que cobra el banco).
          <br />
          <br />
          <strong>Ventajas de descontar un cheque diferido</strong>
          <br />
          Dentro de las ventajas que tiene esta herramienta de descuento de
          cheques, se encuentra el hecho de que se accede al dinero en el acto,
          de manera rápida y a bajo costo financiero. Es una herramienta útil si
          se tiene en cuenta el costo de oportunidad del dinero y las
          expectativas de inflación.
        </p>
      ),
      rightContent: (
        <p className="text-left pl-4 w-100">
          <strong>Desventajas de descontar un cheque</strong>
          <br />
          Muchas veces se debe recurrir a bancos, estos solicitan que uno se
          registre como cliente y en algunos casos tienen muchas condiciones, lo
          cual puede enlentecer las operaciones. Se genera una relación con esa
          entidad que puede traer costos asociados. En otras ocasiones, el
          interés que se cobra por el descuento del cheque puede ser realmente
          alto, por lo que se obtiene menor dinero del que se espera o necesita
          en ese momento.
          <br />
          <br />
          <strong>¿Quiénes descuentan cheques?</strong>
          <br />
          Las entidades que ofrecen esta herramienta de descuento de cheques son
          las entidades bancarias, pero también se puede descontar cheques en
          mercados bursátiles «conocido como descuento de cheques en bolsa»,
          teniendo este último distintos sistemas de negociación.
          <br />
          <br />
          <strong>Algunos comentarios finales</strong>
          <br />
          Descontar un cheque diferido puede ser la solución cuando se necesita
          dinero en el momento. Antes de ir corriendo al banco o cualquier otra
          entidad financiera, es importante conocer qué otras opciones se tienen
          para obtener ese dinero que se necesita. No tiene mucho sentido tener
          varias opciones y elegir la más costosa o que genere otros costos.
          Quizás sea mejor utilizar un pagaré, recurrir a un préstamo, etc.
          Siempre deberá analizarse cada caso en particular teniendo en cuenta
          aspectos específicos de cada uno, tales como la necesidad y urgencia
          de liquidez, el costo financiero de las distintas opciones para
          hacerse de dinero, el acceso a cada herramienta, etc. También es
          importante tener una adecuada planificación financiera o un fondo de
          emergencia, para poder hacer frente a esos problemas de liquidez.
        </p>
      ),
    },
    embed: (
      <iframe
        src="https://flo.uri.sh/visualisation/6939870/embed"
        title="Interactive or visual content"
        className="flourish-embed-iframe"
        frameborder="0"
        scrolling="no"
        style={{ width: "100%", height: "100%" }}
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      ></iframe>
    ),
    author: <a href="http://www.pinberai.com">Pinberai</a>,
    role: "",
    preview: "https://public.flourish.studio/visualisation/6939870/",
    images: [Note_6_img],
  },
];
