export const esp = {
  menu: {
    item1: "SobreManí",
    item2: "Programas",
    item3: "Producto",
    item4: "Aliados",
    item5: "Cropmix",
    item6: "Blog",
    item7: "Contacto",
  },
  formSimple: {
    field1: "Nombre",
    field2: "Apellido",
    field3: "Email",
    field4: "Telefono",
    send: "Enviar",
    title: "Motivo de la Consulta",
    box01: "PROGRAMAS",
    box02: "INVERSIONES",
    box03: "SOBRE MANÍ",
    box04: "SOBRE MANISEROS",
  },
  footer: {
    subtitle01: "Contacto",
    desc01: "contacto@maniseros.com",
    subtitle02: "Redes",
    desc02: "¡Seguinos!",
  },
  // ------------------- HOME -------------------
  home: {
    Banner: {
      titleFirstLineHighlight: "MANÍ. LA EVOLUC",
      titleFirstLine: "IÓN,",
      titleSecondLineHighlight: "de nuestr",
      titleSecondLine: "o campo",
    },
    sectionOne: {
      subtitle: "CICLO DE VIDA",
      titleFirstLineHighlight: "MANÍ. TIERR",
      titleFirstLine: "A Y AGUA,",
      titleSecondLineHighlight: "el balance perfecto para log",
      titleSecondLine: "rar",
      titleThirdLineHightlight: "un mix sustenta",
      titleThirdLine: "ble",
    },
    sectionTwo: {
      subtitle: "PROGRAMA MANISEROS",
      titleFirstLineHighlight: "UNA PROPUESTA SUPERA",
      titleFirstLine: "DORA,",
      titleSecondLineHighlight: "Y 2 plan",
      titleSecondLine: "es para lograr el éxito.",
    },
    sectionThree: {
      subtitle: "PRODUCTO",
      titleFirstLineHighlight: "UN CULTIVO QUE CRECE",
      titleFirstLine: " ",
      titleSecondLineHighlight: "en rendi",
      titleSecondLine: "miento y productividad",
    },
    sectionFour: {
      subtitle: "ALIANZAS PARA CRECER",
      href: "VER MÁS",
      titleFirstLineHighlight: "LA OPORTUNIDAD DE TRA",
      titleFirstLine: "NSITAR",
      titleSecondLineHighlight: "EL CAMI",
      titleSecondLine: "NO junto a los mejores.",
      mobile: {
        titleFirstLineHighlight: "LA OPORTUNIDA",
        titleFirstLine: "D DE",
        titleSecondLineHighlight: " TRANSITAR E",
        titleSecondLine: "L CAMINO",
        titleThirdLineHightlight: "junto a los mejo",
        titleThirdLine: "res.",
      },
    },
    sectionFive: {
      subtitle: "CONTACTO",
      titleFirstLineHighlight: "El mejor man",
      titleFirstLine: "í del mundo se",
      titleSecondLineHighlight: "cultiva en nuestra tierr",
      titleSecondLine: "a.",
      mobile: {
        titleFirstLineHighlight: "El mejor man",
        titleFirstLine: "í del",
        titleSecondLineHighlight: "mund",
        titleSecondLine: "o se cultiva",
        titleThirdLineHightlight: "en nuestra tierr",
        titleThirdLine: "a.",
      },
    },
  },
  // ------------------- SOBRE MANI -------------------
  lyfecycle: {
    title: "Ciclo de Vida",
    progress: "Progreso Cultivo",
    sanity: "Sanidad Vegetal",
    solutions: "Soluciones de Mercado",
    program: "Programas",
    day: "Día",
    day00: {
      title: "PRE SIEMBRA",
    },
    day01: {
      title: "PRE EMERGENCIA",
    },
    day12: {
      title: "VE EMERGENCIA",
      cont: "Cotiledones cerca de la superficie del suelo y plántula mostrando alguna parte.",
    },
    day15: {
      title: "V0 Cotiledonar",
      title02: "V0 COTILEDONAR",
      cont: "Cotiledones abiertos y horizontales en,o por debajo, de la superficie del suelo.",
    },
    day18: {
      title: "V1 Primera hoja ",
      title02: "V1 PRIMERA HOJA",
      subtitle: "tetrafoliada",
      cont: "Primer nudo desarrollado sobre el eje principal con su hoja tetrafoliada desplegada y folíolos horizontales.",
      titlebenefits: "FIJACIÓN N /Beneficio",
      benefics:
        "Al ser una leguminosa, el maní fija nitrógeno del aire mediante los nódulos en sus raíces independizando de la utilización de fertilizantes nitrogenados y favoreciendo la rotación con gramíneas.",
    },
    day35: {
      title: "Vn “n”",
      title02: "Vn “n” hoja tetrafoliada",
      subtitle: "",
      cont: "“n” nudos desarrollados sobre el eje principal con o sin sus hojas tetrafoliadas desplegadas y foliolos horizontales.",
      titlebenefits: "MICROCLIMA/Beneficio",
      benefics:
        "Cuando llueve, la forma de la planta de maní hace que se genere un microclima bajo el follaje y sobre el suelo permitiendo de este modo, incrementar la eficiencia del uso del agua.",
    },
    day40: {
      title: "R1 floración",
      title02: "R1 Inicio de floración",
      subtitle: "",
      cont: "Una flor abierta en algún nudo.",
      titlebenefits: "FLORACIÓN/Beneficio",
      benefics:
        "Cuando llueve, el maní tiene la capacidad de florecer indeterminadamente haciendo que el ciclo del cultivo y lafructificación se ajusten a las condiciones climáticas.",
    },
    day50: {
      title: "R2 Inicio de formación de clavo",
      title02: "R2 FORMACION CLAVO",
      subtitle: "",
      cont: "Un clavo (ginóforo) elongándose.",
      titlebenefits: "CRECIMIENTO /Beneficio",
      benefics:
        "Cuando se dan condiciones prolongadas de lluvias o periodos de sequías, la planta detiene el crecimiento y por ende baja al mínimo la demanda de agua.",
    },
    day60: {
      title: "R3 Inicio de formación de fruto",
      title02: "R3 FORMACION FRUTO",
      subtitle: "",
      cont: "Un clavo introducido en el suelo con el extremo (ovario) de un diámetro igual a dos veces el diámetro del clavo.",
      titlebenefits: "RAICES /Beneficio",
      benefics:
        "Las raíces de las plantas de maní pueden llegar a tener hasta 2 metros. Esto hace que la exploración sea eficiente en captar agua y nutrientes, como así también mejorar la oxigenación del suelo.",
    },
    day70: {
      title: "R4 Fruto completamente desarrollado",
      title02: "R4 FRUTO COMPLETO",
      subtitle: "",
      cont: "Un fruto completamente desarrollado, con las dimensiones características del cultivar.",
      titlebenefits: "FRUTO>BIO/Beneficio",
      benefics:
        "La relación entre la biomasa y el fruto es baja, es decir hay más frutos que follaje. Esto, hace que el consumo de agua por parte del cultivo sea menor comparado con otros.",
    },
    day80: {
      title: "R5 Inicio de formación de semillas",
      title02: "R5 FORMACIÓN SEMILLAS",
      subtitle: "",
      cont: "Un fruto completamente desarrollado, con crecimiento visible de los cotiledones de la semilla, al efectuar un corte transversal del fruto (pasada la fase de endosperma líquido).",
    },
    day85: {
      title: "R6 Semilla completamente desarrollada",
      title02: "R6 SEMILLA",
      subtitle: "",
      cont: "Un fruto con semillas que llenan las cavidades de éste.",
      titlebenefits: "NPK /Beneficio",
      benefics:
        "El cultivo dadas las características de su sistema radicular y la fijación biológica de nitrógeno no necesita la utilización de fertilizantes como fuente de macro y meso nutrientes.",
    },
    day120: {
      title: "R7 Inicio de madurez",
      title02: "R7 MADUREZ",
      subtitle: "",
      cont: "Un fruto mostrando coloración canela o marrón en la cara interna del pericarpio en el 50% de las plantas del cultivo.",
    },
    day160: {
      title: "R8 Madurez de cosecha o arrancado",
      title02: "R8 COSECHA",
      subtitle: "",
      cont: "Igual a R7 dependiendo el porcentaje de plantas del cultivar sembrado: 70 a 75% en tipos Virginia y Español, 80% en tipo Valencia.",
      titlebenefits: "DESCOMPACTA /Beneficio",
      benefics:
        "Tanto el sistema exploratorio de sus raíces como así también prácticas de manejo conservadoras para favorecer la exploración, hacen al cultivo un excelente medio para descompactar capas de suelo subsuperficiales y proveer de oxigenación y mineralizaciónal mismo.",
    },
  },
  // ------------------- PROGRAMAS -------------------
  programs: {
    sectionOne: {
      subtitle: "Programas Maniseros",
      titleFirstLineHighlight: "Ventaj",
      titleFirstLine: "as y beneficios",
      titleSecondLineHighlight: "de una propu",
      titleSecondLine: "esta",
      titleThirdLineHightlight: "superad",
      titleThirdLine: "ora.",
      desc: "Los productores que participen descubrirán todo el potencial que representa el sumarse a la familia manisera, logrando incrementar la productividad de su agricultura y los beneficios para el suelo de su campo, mejorando la sustentabilidad del sistema productivo al incorporar este apasionante cultivo. Dos tentadoras propuestas pensadas a la medida del perfil del productor agropecuario que se necesita para ampliar la producción del cultivo y satisfacer la creciente demanda, manteniendo el standard de calidad premium reconocido al maní argentino y requerido internacionalmente.",
      cuadro01: "FEDEA & PRODEMAN",
      bajada01: "Sé parte de la familia internacional de productores de maní",
      boton01: "CONOCER MÁS",
      cuadro02: "Llave en Mano",
      bajada02: "Solución integral según áreas de interés.",
    },
    sectionTwo: {
      titleFirstLine: "Program",
      titleFirstLineHighlight: "a Fedea",
      titleSecondLineHighlight: "& Prode",
      titleSecondLine: "man",
      desc: "FEDEA y PDM participan conjuntamente con Maniseros en la elaboración e implementación de este innovador programa que acompaña al productor desde la dirección técnica integral, hasta la cosecha final, brindándole soporte en cada etapa y las garantías necesarias para que pueda incorporar el maní llevando adelante una estrategia exitosa en su mix de cultivos mejorando su productividad.",
    },
    sectionThree: {
      subtitle: "Compromisos con el Productor",
      box01: "1. DIRECCIÓN TÉCNICA INTEGRAL DEL CULTIVO",
      desc01:
        "A cargo de un staff de expertos agrónomos maniseros, quienes le brindarán la DT integral del cultivo desde la elección del lote, preparación del suelo, protección del cultivo, y la decisión del momento y formas para con las mejores prácticas de manejo del cultivo.",
      box02: "2. PROVISIÓN DE LAS MEJORES SEMILLAS",
      desc02:
        "Las cuales han sido tratadas con Azoxistrobina 0.6%, Carboxin 20%, Metiltiofanato 10%, Metalaxil 1.33% y Molibdeno (Mo) 4%, recibiendo un tratamiento único. Que garantizarán el éxito de la cosecha.",
      box03: "3. ORGANIZACIÓN DE LA LOGÍSTICA DEL CULTIVO",
      desc03:
        "Los mejores colaboradores en cada proceso de la producción, mediante nuestra red de contratistas experimentados, para llegar en tiempo y forma a cumplimentar cada una de las labores que demanda el cultivo de maní.",
      box04: "4. CAPACITACIÓN INTEGRAL",
      desc04:
        "A través de la cual los productores recibirán todo el conocimiento y les iremos contagiando nuestra pasión, capacitándolos sobre la forma correcta de hacer productivo el cultivo de maní. Viviendo una experiencia que los llevará a lograr una cosecha verdaderamente exitosa.",
      box05: "COMPRA TOTAL GARANTIZADA",
      desc05:
        "Asegurando de esta manera el éxito sobre la producción obtenida de cada lote sembrado con maní, al valor de mercado de referencia y organizando la recepción total con mínimos contratiempos de mercadería.",
    },
    sectionFour: {
      subtitle: "Beneficios para el productor",
      boxTitle01: "Garantía de cosecha",
      desc01:
        "Bonificación total de la Cosecha ante un rendimiento inferior al Base referenciado en caja limpio y seco.",
      boxTitle02: "Garantía de semillas",
      desc02:
        "Bonificación parcial de la Semilla para la campaña siguiente ante un rendimiento inferior al Base referenciado en caja limpio y seco.",
      boxTitle03: "Fidelidad Contratistas",
      desc03:
        "Plus de tarifa para los contratistas avocados a las tareas de cosecha en productores bajo los programas de maní.",
    },
    sectionFive: {
      titleFirstLineHighlight: "Program",
      titleFirstLine: "a",
      titleSecondLineHighlight: "Llav",
      titleSecondLine: "e en Mano",
      bajada:
        "Una tentadora propuesta (“all included”) destinada a un grupo limitado de productores que quieren probar el cultivo por primera vez.",
      desc01:
        "El Programa Llave en Mano , una verdadera solución integral, será gerenciado en un 100% por PDM e incluye la oferta del servicio de dirección técnica y ejecución total del cultivo de maní, contemplando desde la elección del lote hasta la cosecha.",
      desc02:
        "Maniseros, junto al productor, ejecutará y llevará adelante el proceso integral del cultivo con todo su conocimiento y buenas prácticas, haciéndose cargo de la dirección técnica, la protección del cultivo, las labores y hasta la cosecha, momento en que PDM se cobrará por su participación en canje sobre lo producido, descontando el costo total del cultivo ejecutado.",
    },
    sectionSix: {
      subtitle: "Ventajas de Llave en Mano",
      box01: "El dueño del campo mantiene su rol de productor",
      box02: "Realización total del cultivo con la dirección técnica de PDM",
      box03: "No hay cargos de administración del cultivo",
      box04: "Planificación competitiva del cultivo",
      box05: "No hay componente financiero en el canje",
      box06: "Apalancamiento total del cultivo",
      box07: "Gestión digital de datos",
      box08: "Documentación total del cultivo y reportes quincenales",
    },
    sectionSeven: {
      subtitle:
        "Asegurando el éxito de los cultivos bajo el siguiente programa",
      box01: "SOPORTE",
      desc01:
        "Un equipo multidisciplinario capacitado, con 40 años de experiencia.",
      box02: "LABORES",
      desc02:
        "La red de contratistas y maquinaria más grande y eficiente del país.",
      box03: "SEMILLA",
      desc03:
        "Nuestro mejor socio para cada uno de tus lotes y para el éxito de la cosecha.",
      box04: "PROCESO Y LOGÍSTICA",
      desc04:
        "Recepción, análisis , procesamiento y acopio monitoreado con sistemas de trazabilidad digital.",
      boton: "¿TE INTERESA ALGÚN PLAN? HABLANOS",
    },
    contacto: {
      subtitle: "Contacto",
      title: "El mejor maní del mundo se cultiva en nuestra tierra.",
      campo01: "Nombre",
      campo02: "Apellido",
      campo03: "Email",
      send: "Enviar",
    },
  },
  // ------------------- PRODUCTO -------------------
  product: {
    sectionOne: {
      subtitle: "Producto",
      titleFirstLineHighlight: "El mejo",
      titleFirstLine: "r maní del",
      titleSecondLineHighlight: "mund",
      titleSecondLine: "o. Orgullo de",
      titleThirdLineHightlight: "nues",
      titleThirdLine: "tra tierra.",
      desc01:
        "Argentina se ha consolidado a nivel mundial como el mayor exportador de maní de calidad, y es precisamente ese prestigio logrado por su gran calidad, lo que distingue al Maní Argentino como el mejor maní del mundo y le ha abierto puertas en los principales mercados.",
      title01: "EXPORTACIONES",
      number01: "+ 400.000 Tn",
      number02: "+ 100 Destinos",
      number03: "+ 1,000 u$Millones",
      desc02:
        "95% del total del maní producido en todo el país se destina al comercio exterior, siendo la Unión Europea el principal mercado de interés.",
    },
    sectionTwo: {
      title: "Entre las manufacturas elaboradas que se exportan se destacan:",
      box01: "Maní Confitería",
      little01: "(Shelled, Blanched, Splits, Chopped, Sliced)",
      box02: "Aceite de maní, crudo y refinado",
      box03: "Maní Preparado y/o Saborizado ",
      little02: "(Snacks)",
      box04: "Harina de maní",
      box05: "Pasta de maní",
      box06: "Pallets y expellers de maní",
      box07: "Manteca de maní",
    },
    sectionThree: {
      subtitle: "¿Cómo se mide el rendimiento?",
      boton: "CONOCER MÁS",
      title01: "UN CULTÍVO SELECCIONADO",
      subtitle01:
        "Para desarrollar un alimento saludable y de producción sustentable.",
      desc0101:
        "El maní es un cultígeno, un cultivo domesticado históricamente por el hombre, elaborándolo originalmente con un fin alimenticio y su posterior desarrollo comercial.",
      desc0102:
        "Como especie leguminosa enriquece el suelo con nitrógeno, brindando ventajas con su rol en la rotación de cultivos por siembra directa, contribuyendo a mantener un balance nutricional equilibrado colaborando a la sustentabilidad del sistema productivo.",
      title02: "RENDIMIENTO CONFITERÍA",
      desc0201:
        "Se indica en relación porcentual cuanto representa el peso de los granos en el total del peso de las cajas que llegan a PDM.",
      desc0202:
        "Contrariamente a lo que muchos suponen, el Maní Confitería no es un commodity como erróneamente suele considerarse, sino un producto alimentario elaborado, una manufactura de origen agrícola que cuenta en su proceso final con un extraordinario componente de valor agregado.",
      subtitle02: "Maní confitería",
      desc03:
        "Un producto alimentario elaborado de extraordinario valor agregado",
      column01Title: "GRANOS",
      column01box01: "GRANOS SUELTOS",
      column01box02: "DENTRO DE LA VAINA",
      divisor: "Rendimiento Confitería",
      column01Title01: "DENTRO",
      column01Title02: "DE LA",
      column01Title03: "VAINA",
      // column01box01: "CONFITERÍA",
      // column01box02: "FINO",
      zaranda: "ZARANDA ",
      btna: "CONOCÉ MÁS",
      img: "/assets/analisis.png",
      imgmobile: "/assets/analisis-mobile.png",
    },
    sectionFour: {
      titleFirstLineHighlight: "MÁS DE 10",
      titleFirstLine: "0 AÑOS DE HISTORIA",
      titleSecondLineHighlight: "Para producir un cultivo de excel",
      titleSecondLine: "encia.",
      subtitle: "Rinde Histórico",
      desc01:
        "Hoy estamos escribiendo uno de los capítulos más importantes en la historia de la producción del cultivo de maní en nuestro país, frente a una gran demanda internacional y el desafío de mantener una calidad superior distintiva, lo cual representa una verdadera oportunidad para que nuevos productores sean también partícipes del éxito y sumen su pasión, uniéndose a la gran familia manisera argentina, reconocida a nivel internacional por producir nada menos que “el mejor maní del mundo”.",
      desc02:
        "Esta experiencia inicialmente tuvo su origen en nuestro país con un grupo de apasionados agricultores que, hacia fines de 1896, apostaron al potencial de nuestro rico suelo amalgamado con las propiedades únicas del cultivo de maní. Desarrollándose, superando los desafíos, mejorando los procesos de la cosecha, invirtiendo en tecnología para lograr soluciones innovadoras hasta alcanzar la perfección del cultivo con los maníes Alto Oleico, siendo los más requeridos en los mercados internacionales.",
      titlegraph01: "Precios en USD/Ton de caja liquidados en PDM",
      titlegraph02: "Rinde de Argentina en Tn/Ha de caja",
      titleFirstLineHighlightMobile: "MÁS DE 10",
      titleFirstLineMobile: "0 AÑOS DE",
      titleSecondLineHighlightMobile: "HISTORI",
      titleSecondLineMobile: "A. Para",
      titleThirthLineHighlightMobile: "producir un cultiv",
      titleThirthLineMobile: "o",
      titleForthLineHighlightMobile: " ",
      titleForthLineMobile: "de excelencia.",
    },
    sectionFive: {
      title01: "¡Comenzá a cultivar maní!",
      desc01:
        "¡Comenzá a cultivar maní! Tenemos todas las opciones para el éxito de tu inicio en el cultivo.",
      boton01: "PROGRAMAS",
      title02: "Alquilá",
      desc02:
        "¿Querés tener maní en tu campo pero no quieres mantener el cultivo? Llamanos y te alquilamos el campo",
      boton02: "CONTACTANOS",
    },
    sectionSix: {
      box01: "Caja Rinde (Tn/Ha):",
      box02: "Precio U$D/Ton:",
      box03: "(Tn/Ha) Caja ",
      box01Mobile: "Año",
    },
  },
  // ------------------- PARTENER - FEDEA -------------------
  partners: {
    cta: "SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS",
    fedea: {
      sectionOne: {
        titleFirstLineHighlight: "Calidad en el ser",
        titleFirstLine: "vicio",
        titleSecondLineHighlight: "al client",
        titleSecondLine: "e",
      },
      sectionTwo: {
        title01: "Fedea te acompaña",
        title02: "todo el camino",
        desc01:
          "Somos una empresa con más de 25 años de experiencia en el mercado agropecuario. Con 13 sucursales en La Pampa, oeste de Buenos Aires y sur de Córdoba acompañamos a los productores a lo largo de todo el ciclo productivo. Sumado al Programa Maní Fedea Prodeman tenemos otras 6 unidades de negocio: protección de cultivos, semillas, fertilizantes, acopio de granos, combustibles y nutrición animal.",
        desc02:
          "Con un equipo de más de 170 personas brindamos asesoramiento técnico, comercial, servicios financieros, logística integral a campo, entre otros servicios. Además contamos con plantas de fertilizantes, acopio de granos y combustibles que nos permite estar cerca de nuestros clientes en las necesidades de su día a día.",
      },
      sectionThree: {
        title: "Servicios para cada etapa del crecimiento",
        box01: {
          title: "Pre siembra",
          item01: "- Elección del lote",
          item02: "- Planificación del barbecho",
          item03: "- Muestreo de Suelo",
          item04: "- Estrategia genética y fecha de siembra",
        },
        box02: {
          title: "Pre emergencia",
          item01: "- Estrategias pre emergentes",
          item02: "- Definición de Siembra",
          item03: "- Logística de siembra",
          item04: "- Logística de semilla",
        },
        box03: {
          title: "Ve V0",
          item01: "- Informe de implantación",
          item02: "- Definición de stand",
          item03: "- Evaluación inicial",
        },
        box04: {
          title: "V1 - Vn - R1 - R6 - R7",
          item01: "- Monitoreo intensivo",
          item02: "- Asesoramiento técnico",
          item03: "- Logística de labores",
          item04: "- Recomendaciones Fenotipado",
        },
        box05: {
          title: "Cosecha",
          item01: "- Logística de cosecha",
          item02: "- Soporte Informe de Pos Cosecha",
          item03: "- Contribución del cultivo Rotación y/o secuencia.",
        },
      },
      sectionFour: {
        title: "Fedea te ofrece",
        box01: "Mejora la rentabilidad de tu sistema productivo",
        box02: "Gestión de labores específicas",
        box03: "Asesoramiento y asistencia técnica especializada",
        box04: "Respaldo de empresas sólidas y confiables",
        box05: "Incorporación de un nuevo cultivo a la rotación",
        box06: "Red de Soporte",
        dest: "Ver mapa",
      },
      sectionSix: {
        title: "Beneficios",
        box01:
          "Encontrá todos los productos de CORTEVA con el mejor precio y financiación.",
        box02:
          "Te ofrecemos calidad y tecnología en Combustibles Axion Agro y Lubricantes Castrol.",
        box03:
          "Semillas de maíz Brevant. Exelentes rendimientos en maíz temprano y tardío.",
        box04:
          "Acopiamos y comercializamos todos los granos y cereales. Buscamos la mejor alternativa de negocio, ya sea con entrega en nuestras plantas o en puertos.",
      },
    },
    basf: {
      sectionOne: {
        titleFirstLineHighlight: " ",
        titleFirstLine: " ",
        titleSecondLineHighlight: " ",
        titleSecondLine: " ",
      },
      sectionTwo: {
        title01:
          "Buscando juntos el equilibrio ideal para el éxito de los agricultores, la agricultura y las generaciones futuras",
        title02: "",
        desc01:
          "La agricultura tiene que ver con el equilibrio. El equilibrio natural que impulsa las campañas, el clima y los ciclos de cultivo. Nunca ha habido un momento más desafiante en la agricultura. Pero tampoco nunca ha habido un momento de mayor oportunidad. BASF, a través de su experiencia mundial sabe lo importante que es conseguir un equilibrio ideal para el éxito de la agricultura. Por eso Maniseros cuenta con BASF como partner destacado para su red de productores",
        desc02:
          "asociados, en este mundo dinámico, BASF acepta el desafío creando soluciones innovadoras para la agricultura y la higiene ambiental, permitiéndonos cultivar y llevar adelante las buenas costumbres que ayuden a mejorar el planeta, buscando lograr el equilibrio ideal para el éxito de los agricultores, la agricultura y las generaciones futuras.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "0 a 10 días antes de la siembra",
        },
        box02: {
          title: "Siembra",
        },
        box03: {
          title: "V2",
        },
        box04: {
          title: "V6 - R1",
        },
        box05: {
          title: "R3",
        },
      },
      sectionSix: {
        title: "Beneficios",
        box01:
          "Comprando productos del portafolio de BASF podés acumular puntos para canjear por camionetas, tecnología y mucho más en nuestro catálogo de Multiplica, el programa de beneficios y descuentos más importante del Agro Argentino.",
        box01reg: " Registrate",
        box01link: " acá ",
        box01final: "y sé parte.",
        box02:
          "Un espacio de capacitación, charlas interactivas, materiales descargables para las distintas problemáticas del campo y su gestión.",
        box02link: "Conocé más...",
        box03:
          "Si tenés dudas de alguno de nuestros productos podés contactarte vía WhatsApp con el centro de atención al cliente de BASF y recibir más información.",
        box04:
          "xarvio™, la plataforma soluciones digitales más completa para el campo. Conocenos y digitalizá tu campo",
      },
    },
    bayer: {
      sectionOne: {
        titleFirstLineHighlight: "#AliadosDeTuMan",
        titleFirstLine: "í",
      },
      sectionTwo: {
        title01: "Holding Global e",
        title02: "Innovador",
        desc01:
          "Somos una empresa líder en la provisión de soluciones tecnológicas y productos agrícolas que mejoran la productividad agropecuaria y la calidad de los alimentos. Nuestro objetivo es permitir que pequeños y",
        desc02:
          "grandes agricultores produzcan más en sus tierras y al mismo tiempo conserven los recursos naturales de nuestro planeta, tales como el agua y la energía.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Pre - Emergencia",
        },
        box02: {
          title: "V5 - R3",
        },
      },
      sectionSix: {
        title: "Beneficios",
        box01: "Climate FieldView",
        box0101:
          " interpreta los datos de tu campo de una forma simple para que puedas aprovechar al máximo cada una de tus hectáreas.",
        box02:
          "Una verdadera red de relacionamiento que potencia el desarrollo de cada integrante. Una propuesta que llega para sumar.",
        box03title: "Charlas con expertos",
        box03:
          "Soluciones de sustentabilidad con los programas Yo Aplico Responsablemente y Forward Farming Bayer. Charlas con referentes del control de malezas,enfermedades, aplicaciones y Buenas Prácticas Agrícolas [BPA]",
      },
    },
    corteva: {
      sectionOne: {
        titleFirstLineHighlight: "Sigamos creciend",
        titleFirstLine: "o",
      },
      sectionTwo: {
        title01: "Dedicados a la",
        title02: "agricultura",
        desc01:
          "Corteva Agriscience™ es una compañía dedicada 100% a la agricultura y con 200 años de experiencia, ofreciendo soluciones para semillas, protección de cultivos y agricultura a lo largo de todo el ciclo de vida de los cultivos. Nos enfocamos en la innovación tecnológica y en elaborar productos de protección de cultivos y semillas para",
        desc02:
          "aumentar la productividad y el rendimiento de los agricultores, minimizando el riesgo para su negocio. Nuestras herramientas digitales proporcionan información regular a nuestros socios para que crezcan hoy y sigan haciéndolo en el futuro.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Pre Siembra",
        },
        box02: {
          title: "Pre emergencia",
        },
        box03: {
          title: "V1 Vn",
        },
        box04: {
          title: "R1 - R6",
        },
      },
      sectionSix: {
        title: "Beneficios",
        box01:
          "MiLote es una aplicación web desarrollada por Corteva Agriscience que le permite al usuario gestionar todos sus lotes, seguir su evolución en base a datos de índices verdes, ver productividades, históricas en base a 18 años de información.",
        box02:
          "Corteva Flight permite hacer más eficientes y más rápidas las mediciones en los distintos estadios fenológicos de los cultivos. Nuestra característica única, Edge Computing, permite procesar toda la información al momento a través de algoritmos de inteligencia artificial para entregarte un reporte preciso, rápido y fiable.",
        box03:
          "Acceso a  bonificaciones exclusivas por la compra de mix de productos del pack maní (pack 50has) en estos distribuidores Emblema:",
      },
    },
    nova: {
      sectionOne: {
        titleFirstLineHighlight: "Calidad en tus cultiv",
        titleFirstLine: "os",
      },
      sectionTwo: {
        title01: "Acompañando tus cultivos",
        title02: "",
        desc01:
          "NOVA es una empresa argentina nacida hace 35 años en Cañada de Gómez, provincia de Santa Fe, Argentina. Formula, desarrolla y comercializa las principales líneas de productos para la protección y el crecimiento de los cultivos y cuenta con una unidad de biotecnología dedicada al desarrollo y producción de enzimas industriales de aplicación en diversas industrias.",
        desc02:
          "Desde el año 2010 opera bajo la certificación de normas ISO. Durante el 2018 obtuvo la Certificación ISO 14001:2015 en Sistemas de Gestión Ambiental y recertificó ISO 9001:2015 en Sistemas de Gestión de Calidad.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Tratamiento de semillas",
        },
        box02: {
          title: "Presiembra",
        },
        box03: {
          title: "PRE - POE temprana",
        },
        box04: {
          title: "V6 - R3",
        },
        box05: {
          title: "V2 - V4",
        },
        box06: {
          title: "R1 - R2",
        },
        box07: {
          title: "R1 - R4",
        },
      },
    },
    oroverde: {
      sectionOne: {
        titleFirstLineHighlight: "Vemos el camp",
        titleFirstLine: "o de",
        titleSecondLineHighlight: "Otro colo",
        titleSecondLine: "r",
      },
      sectionTwo: {
        title01: "Nosotros",
        desc01:
          "Oro Verde Servicios es una empresa familiar dedicada a prestar servicios a la cadena agroalimentaria, fundamentalmente servicios de sanidad vegetal, orientados al desarrollo de tecnologías de procesos y",
        desc02:
          "manejo integral de los cultivos, abarcando desde la sanidad y calidad de la semilla al monitoreo de cultivos brindando información para la toma de decisiones; disminuyendo el riesgo y la incertidumbre.",
      },
      sectionThree: {
        title: "Servicios para cada etapa del crecimiento",
        box01: {
          title: "Pre siembra",
          item01: "- Elección del lote.",
          item02: "- Análisis de patógenos de suelo.",
          item03: "- Análisis de semillas.",
          item04: "- Elección de variedades.",
          item05: "- Asesoramiento en Barbechos.",
        },
        box02: {
          title: "Pre Siembra",
          item01: "- Asesoramiento en Barbechos.",
          item02: "- Calidad de implantación y emergencia.",
        },
        box03: {
          title: "Ve V0",
          item01: "- Control de malezas post-emergencia (recomendación)",
        },
        box04: {
          title: "V1 - Vn",
          item01: "- Monitoreo de viruela.",
          item02: "- Diagnósticos de enfermedades.",
          item03: "- Diagnósticos de plagas.",
          item04: "- Visitas técnicas o recorrido .",
          item05: "- Técnico de lotes.",
          item06: "- Sistema de alerta de viruela.",
        },
        box05: {
          title: "R1 - R6",
          item01: "- Monitoreo de viruela.",
          item02: "- Diagnósticos de enfermedades.",
          item03: "- Diagnósticos de plagas.",
          item04: "- Visitas técnicas o recorrido.",
          item05: "- Técnico de lotes.",
          item06: "- Sistema de alerta de viruela.",
        },
        box06: {
          title: "R7",
          item01: "- Sistesma de alerta de viruela.",
        },
        box07: {
          title: "Cosecha",
          item01: "- Calidad de semilla",
        },
      },
    },
    summit: {
      sectionOne: {
        titleFirstLineHighlight: "Tecnologí",
        titleFirstLine: "a",
        titleSecondLineHighlight: "japonesa líd",
        titleSecondLine: "er",
      },
      sectionTwo: {
        title01: "Una nueva forma de concebir la",
        title02: "protección de nuestros cultivos",
        desc01:
          "La evolución comienza con el cambio y el cambio necesita decisión. El mayor desafío emergente implica desarrollar productos para la protección de cultivos cada vez más efectivos, pero que a su vez protejan debidamente a quienes los aplican, a quienes los consumen y al medio ambiente. Por esa razón Summit Agro es un partner destacado de Maniseros para su red de productores asociados, por su fuerte compromiso con el medio ambiente y con la calidad de vida de",
        desc02:
          "las personas. Las actividades internas y externas desarrolladas por Summit Agro están dentro de un contexto sustentable, aplicando medidas concretas en pos del objetivo, brindando soluciones confiables frente a las necesidades y oportunidades de la comunidad agrícola y su sostenible crecimiento.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Pre Siembra",
        },
        box02: {
          title: "Pre emergencia",
        },
        box03: {
          title: "V1 - Vn",
        },
        box04: {
          title: "R1 - R6",
        },
      },
      sectionSix: {
        title: "Beneficios",
        box01: "Ahora estamos más cerca, hablemos por whatsapp",
      },
    },
    syngenta: {
      sectionOne: {
        titleFirstLineHighlight: "",
        titleFirstLine: "",
        titleSecondLineHighlight: "",
        titleSecondLine: "",
      },
      sectionTwo: {
        title01: "Acerca de",
        title02: "",
        desc01:
          "Syngenta es una de las principales empresas agrícolas del mundo. Nuestra ambición es ayudar a alimentar al mundo de manera segura mientras cuidamos el planeta. Nuestro objetivo es mejorar la sustentabilidad, la calidad y la seguridad de la agricultura con ciencia de clase mundial y soluciones innovadoras para cultivos. Nuestras tecnologías permiten a millones de agricultores de todo el mundo hacer un mejor uso de los recursos agrícolas limitados. Syngenta es parte del Syngenta Group con 48.000 personas en más de 100 países",
        desc02:
          "y está trabajando para transformar como se producen los cultivos. A través de asociaciones, la colaboración y el programa The Good Growth Plan, estamos comprometidos en acelerar la innovación para los agricultores y la naturaleza, esforzándonos por una agricultura neutra en carbono, ayudando a las personas a mantenerse seguras y saludables y asociándonos para lograr un impacto.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Pre Siembra",
        },
        box02: {
          title: "Pre Emergencia",
        },
        box03: {
          title: "Ve - V0",
        },
        box04: {
          title: "V1 - Vn",
        },
        box05: {
          title: "R1 - R6",
        },
      },
    },
    sipcam: {
      sectionOne: {
        titleFirstLineHighlight: "Cultivam",
        titleFirstLine: "os",
        titleSecondLineHighlight: "Crecimien",
        titleSecondLine: "to",
      },
      sectionTwo: {
        title01: "Sosteniendo la agricultura desde 1946.",
        desc01:
          "Tenemos la mentalidad y creatividad de una empresa italiana, enriquecida con más de 70 años de investigación y desarrollo, aplicada al servicio del campo con agricultores. Nuestro grupo es uno de los pocos conglomerados de agroquímicos que realiza integradamente desde la síntesis de los activos hasta la",
        desc02:
          "formulación y envasado del producto final. Todo este proceso lo hacemos bajo las más altas normas de calidad europea y con los estándares más altos de cuidado medioambientales. Por estas razones, maniseros cuenta con Sipcam para apoyar el desafío de incursionar en el cultivo de maní de manera adecuada, protegida y exitosa.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Barbecho a Maní",
        },
        box02: {
          title: "Pre Siembra",
        },
        box03: {
          title: "Pre Emergencia",
        },
        box04: {
          title: "R1 - R6",
        },
        box05: {
          title: "Postcosecha",
        },
      },
      sectionFour: {
        title: "Beneficios",
        box01: {
          title: "Capacitación Técnica",
          content: "Uso de Terbyne en el Cultivo de Maní.",
          cta: "VER VIDEO",
        },
        box02: {
          title: "Capacitación Técnica",
          content: "Uso de Terbyne en Cultivos de Servicio Maíz y Sorgo.",
          cta: "VER VIDEO",
        },
        box03: {
          title: "Seguinos",
          content:
            "Para saber más sobre Sipcam y nuestras soluciones para el cultivo de maní, seguinos en nuestras redes.",
        },
      },
    },
    stoller: {
      sectionOne: {
        titleFirstLineHighlight: "Activando el p",
        titleFirstLine: "oder",
        titleSecondLineHighlight: "De las plan",
        titleSecondLine: "tas",
      },
      sectionTwo: {
        title01: "Especialistas en nutrición vegetal",
        desc01:
          "El agro que conocíamos comenzó a redefinirse. Junto a distintos productores buscamos la innovación constante para lograr una agricultura sustentable de precisión.",
        desc02:
          "Comprometidos con el mundo, nuestro equipo tiene el gran desafío de aportar soluciones integrales y conocimiento en nutrición vegetal para que tu campo siga evolucionando al servicio de la vida.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Tratamiento a semilla",
          img01: "/assets/logo_stimulate_mani.svg",
          img02: "/assets/logo_masterfix-mani.svg",
        },
        box02: {
          title: "Siembra",
          img01: "/assets/logo_masterfix-L.svg",
        },
        box03: {
          title: "V6 - Vn",
          img01: "/assets/logo_stimulate.svg",
        },
        box04: {
          title: "R1 - R2",
          img01: "/assets/logo_x-cyte.svg",
        },
        box05: {
          title: "R5 - R6",
          img01: "/assets/logo_nitrate_balanced.svg",
        },
      },
      sectionFour: {
        title: "Beneficios",
        box01: {
          content:
            "Te invitamos a vivir una auténtica Jornada a campo online en el cultivo de maní. De la mano de nuestros expertos, conocé las mejores estrategias para potenciar tu cultivo.",
          cta: "VER VIDEO",
        },
        box02: {
          content: "Ahora estamos más cerca, hablemos por Whatsapp.",
          cta: "+54 9 3517 68-7995",
        },
      },
    },
    upl: {
      sectionOne: {
        titleFirstLineHighlight: "UPL #Open",
        titleFirstLine: "Ag",
      },
      sectionTwo: {
        title01: "Protección de cultivos y biosoluciones",
        title02: "para una agricultura sostenible",
        desc01:
          "UPL Ltd. es un proveedor global de productos y soluciones agrícolas sostenibles, con ingresos anuales que superan los 5.2 mil millones de dólares. Somos na empresa dirigida por un propósito. A través de OpenAg™, UPL se centra en acelerar el progreso del sistema alimentario. Estamos construyendo una red que está reinventando la sostenibilidad, redefiniendo la forma de pensar y de trabajar de toda uan industria, abierta a nuevas ideas, a la innovación y a nuevas",
        desc02:
          "respuestas mientras que nos esforzamos por cumplir nuestra misión de hacer que todos los productos alimentarios sean más sostenibles. Como una de las mayores empresas de soluciones agrícolas del mundo, nuestro amplio portafolio consiste en soluciones biológicas y de protección de cultivos tradicionales con más de 14.000 registros. Estamos presentes en más de 130 países, representados por más de 10.000 colegas en todo el mundo.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Barbecho",
        },
        box02: {
          title: "Pre emergencia",
        },
        box03: {
          title: "Siembra",
        },
        box04: {
          title: "V1 - Vn",
        },
        box05: {
          title: "R1 - R2",
        },
        box06: {
          title: "R4",
        },
        box07: {
          title: "R5 - R6",
        },
        box08: {
          title: "Granos Almacenados",
        },
      },
      sectionFour: {
        title: "Beneficios",
        box01: {
          content:
            "Conocé nuestro programa exclusivo que integra biosoluciones con productos de protección de cultivos",
          cta: "Ingresar",
        },
        box02: {
          content: "Pronutiva En El Cultivo Del Maní",
          cta: "Ver Video",
        },
        box03: {
          content: "Ahora estamos más cerca, hablemos por Whatsapp.",
          cta: "+54 911 6820 2519",
        },
      },
    },
    fmc: {
      sectionOne: {},
      sectionTwo: {
        title01: "Un compromiso en constante crecimiento",
        desc01:
          "FMC es una compañía impulsada por la innovación y el compromiso con el descubrimiento de nuevos ingredientes activos, formulación de productos y tecnologías de aplicación. Durante más de 130 años, nuestro Propósito ha sido desarrollar soluciones innovadoras para la agricultura y la industria, y acompañar al productor brindando respuesta a sus necesidades cotidianas en materia de Protección de Cultivos.",
        desc02:
          "Para lograrlo, nuestros Principios de Negocio fundamentales son: ",
        item01:
          "Descubrir y desarrollar tecnologías sustentables de vanguardia.",
        item02:
          "Superar las expectativas del productor en cuanto a colaboración, rapidez y agilidad.",
        item03:
          "Ofrecer simpleza, transparencia y compromiso en todos nuestros negocios.",
        item04:
          "Trabajar de manera rigurosa para el beneficio de nuestros clientes, colaboradores e inversores, buscando el triple impacto positivo: económico, social y ambiental.",
      },
      sectionThree: {
        title: "Productos para cada etapa del crecimiento",
        box01: {
          title: "Barbecho",
        },
        box02: {
          title: "Pre siembra",
        },
        box03: {
          title: "V1 - Vn",
        },
        box04: {
          title: "V6 - Vn",
        },
        box05: {
          title: "R1 - R6",
        },
      },
      sectionFour: {
        title: "Beneficios",
        box01: {
          content:
            "ARC Farm Intelligence es la plataforma móvil de FMC que ofrece datos en tiempo real sobre la presión de insectos con una semana de anticipación y hasta un 90% de confiabilidad para las principales plagas, ayudando así a proteger la productividad y el rendimiento de los cultivos.",
          cta: "Ingresar",
        },
        box02: {
          title: "Seguinos en nuestras redes",
          content:
            "Para conocer más sobre nuestro portfolio de productos y soluciones para el cultivo de maní, seguinos en nuestras redes",
        },
        box03: {
          content:
            "Por cualquier consulta o comentario, comunícate con nosotros vía whatsapp",
          cta1: "+54 9 358 6029421",
          cta2: "o visitá ",
          cta3: "nuestra página web",
        },
      },
    },
    aviacion: {
      page01: {
        sectionOne: {
          titleFirstLineHighlight: "Prode",
          titleFirstLine: "man",
          titleSecondLineHighlight: "Servi",
          titleSecondLine: "cios aéreos",
        },
        sectionTwo: {
          title:
            "Cuando una plaga, maleza o enfermedad amenaza al cultivo, el tiempo es crítico.",
          content: {
            firstP:
              "Para la protección de los cultivos extensivos, nuestro departamento de aviación y nuestros aviones de última generación están preparados para recorrer grandes superficies y distancias por día, con el fin de mantener los cultivos protegidos y con la sanidad adecuada para expresar todo su potencial genético en el ambiente elegido.",
            secondP:
              "Evite pisotear los cultivos y el lote, manejando umbrales mediante una aplicación de calidad y pagando solo por el producto aplicado",
          },
        },
        sectionThree: {
          titleFirstLineHighlight01: "¡Gracias por con",
          titleFirstLine01: "tactarte!",
          content: {
            firstLine: "Hemos recibido tu solicitud online.",
            secondLine: "A la brevedad nos comunicaremos con vos.",
          },
          titleFirstLineHighlight02: "¡Nos vemos des",
          titleFirstLine02: "de el aire!",
        },
        sectionFour: {
          title01: "1. Dibujá tus lotes",
          searchPlaceholder: "Ingresá una ubicación",
          fileTypes:
            "Ó podés subir un archivo KMZ, KML, GeoJSON o SHP (SHP debe estar en un ZIP con los archivos *.shp, *.dbf y *.shx).",
          attachedFile: "Archivo adjunto",
          button: "Elegir archivo",
          fileSelected: "No se ha elegido archivo",
        },
        steps: "pasos",
        drawMessage: "Debés dibujar un área en el mapa.",
      },
      page02: {
        sectionOne: {
          title01: "2. Seleccioná propio y/o cotizá la cantidad exacta*",
          table: {
            firstRow: {
              item01: "Tipo",
              item02: "Propio",
              item03: "Cotizá",
            },
            secondRow: "Herbicida",
            thirdRow: "Insecticida",
            fourthRow: "Fungicida",
            fifthRow: "Sólidos",
          },
          arrayNames: {
            item0: "Herbicida Propio",
            item1: "Herbicida Cotizá",
            item2: "Insecticida Propio",
            item3: "Insecticida Cotizá",
            item4: "Fungicida Propio",
            item5: "Fungicida Cotizá",
            item6: "Sólidos Propio",
            item7: "Sólidos Cotizá",
          },
          selector: {
            title: "Selector de Cultivo",
            item01: "Maní",
            item02: "Maíz",
            item03: "Soja",
            item04: "Trigo",
            item05: "Girasol",
            item06: "Otros",
          },
          clarifications: {
            firstLine:
              "* Recibirás una cotización con el detalle del producto disponible.",
            secondLine: "En caso de requerir sólo el vuelo, seleccioná",
            secondLineI: "Propio",
            thirdLine: "Sólidos incluye cultivos de cobertura y fertilizantes.",
            thirdLineI: "Sólidos",
          },
        },
        sectionTwo: {
          title01: "3. Ingresá tus datos",
          form: {
            input01: "CUIT",
            input02: "Razón Social",
            input03: "Nombre y Apellido",
            input04: "Email",
            input05: "Teléfono",
            input06: "Notas",
            input06placeholder:
              "Aquí podrás indicar la plaga, maleza o enfermedad a combatir, aclaraciones de cultivo y obstáculos importantes.",
          },
        },
      },
      alert: {
        pending:
          "Aguardá un instante mientras enviamos los datos de tu cultivo.",
        success: "Los datos se enviaron de manera satisfactoria.",
        error:
          "El envío de datos no pudo completarse. Intentá nuevamente más tarde o ponete en contacto con nosotros.",
      },
    },
  },
  // ------------------- CROPMIX -------------------
  cropmix: {
    section: "Cropmix",
    titleFirstLineHighlight: "Simulador Crop",
    titleFirstLine: "mix",
    page01: {
      section01: {
        title: "1. Indicá tu mix de Soja/Maíz actual",
        soy: "Soja(%)",
        corn: "Maíz(%)",
        lowValue: "Bajo Valor",
        highValue: "Alto valor",
      },
      section02: {
        title: "2. Ingresá los siguientes parámetros",
      },
      form: {
        column01Title: "Soja",
        column02Title: "Maíz",
        row01Title: "Rendimiento (Tn/Ha)",
        row02Title: "Costo (u$s/Ha)",
        row03Title: "Alquiler (u$s/Ha)",
        row03Subtitle: "Opcional",
        row04Title: "Precio (u$s/Tn)",
      },
      button: "ENVIAR",
    },
    page02: {
      title: "3. Reporte Cropmix Maní",
      graph: {
        bar01: "Ingreso Bruto",
        bar02: "Costos + Alquiler",
        bar03: "Margen Bruto",
      },
      bars: {
        soy: "Soja",
        corn: "Maíz",
        peanut: "Maní",
      },
      details: {
        line01:
          "El nuevo cropmix se calcula asumiendo un ingreso de maní al 20%.",
        line02:
          "El rendimiento de maní se calcula con una serie de datos que relacionan Rendimiento de Soja vs Maní en grano y relación G/C de 0.68 donde, Rto = 0.8X + 0.5 Tn/Ha (R2: 0.8).",
        line03:
          "El costo de maní incluye labores, insumos y cosecha promedio de las últimas tres campañas en 50K Has de datos (650 USD/Ha).",
        line04:
          "El alquiler de maní se asume un 80% superior al de Soja en caso de incluirlo en el cálculo.",
      },
      backButton: "VOLVER",
      sendButton: "ENVIAR CORREO",
      dialog: {
        titleFirstLineHighlight: "Envío de re",
        titleFirstLine: "porte",
        contentText:
          "Para recibir el reporte ingresá tu correo acá. Te enviaremos novedades ocasionalmente.",
        backButton: "VOLVER",
        sendButton: "ENVIAR CORREO",
        changeMailButton: "Cambiar Correo",
        sendReportStatus: {
          sending: "Estamos enviando tu reporte, por favor espera un momento.",
          sent: "Ya enviamos tu reporte! Gracias por esperar.",
          fetchError:
            "Hubo un problema durante la comunicación con el servidor. Intenta nuevamente más tarde.",
          error:
            "Error enviando el Reporte. Por favor intenta nuevamente más tarde. De persistir el problema, ponete en contacto con nosotros.",
        },
      },
    },
  },
  // ------------------- BLOG -------------------
  blog: {
    section: "Blog",
    titleFirstLineHighlight: "Noticia",
    titleFirstLine: "s",
    newArticle: "Nueva noticia:",
    readMore: "LEER MÁS",
    recentEntries: "Últimas entradas:",
    previousEntries: "Entradas anteriores:",
  },
  // ------------------- CONTACTO -------------------
  contact: {
    section: "Contacto",
    titleFirstLineHighlight: "El mejor man",
    titleFirstLine: "í del mundo se",
    titleSecondLineHighlight: "cultiva en nuestra tierr",
    titleSecondLine: "a.",
    formulario: {
      box01: "Nombre",
      box02: "Apellido",
      box03: "Email",
      box04: "Teléfono",
      drop01: {
        title: "PROGRAMAS",
        subtitle: "Estamos para ayudarte",
        desc: "Ubica tu campo en el mapa",
      },
      drop02: {
        title: "INVERSIONES",
        subtitle: "Alquilá",
        box01: "Organización",
        box02: "Teléfono",
      },
      drop03: {
        title: "SOBRE MANÍ",
        subtitle: "Estamos para ayudarte",
        box01: "Mensaje",
      },
      drop04: {
        title: "SOBRE MANISEROS",
        subtitle: "Sobre Nosotros",
        box01: "Mensaje",
      },
      send: "Enviar",
    },
  },
};
