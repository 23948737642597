/* eslint-disable default-case */
import React, { useState } from "react";
import Highlights from "./Highlights";
import ContactoProgramas from "./ContactoProgramas";
import Footer from "./Footer";
import "../scss/bootstrap.scss";
import "../scss/Cropmix.scss";
import CropmixFormPageOne from "./CropmixFormPageOne";
import CropmixFormPageTwo from "./CropmixFormPageTwo";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import i18n from "../i18n/index.js";

export default function DiscreteSlider() {
  const defaultFormData = {
    sojaPer: 0.5,
    maizPer: 0.5,
    sojaRto: "",
    sojaCosto: "",
    sojaAlquiler: "",
    sojaPrecio: "",
    maizRto: "",
    maizCosto: "",
    maizAlquiler: "",
    maizPrecio: "",
  };
  const [send, setSend] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [calculatedData, setCalculatedData] = useState();
  const [mailContacto, setMailContacto] = useState("");
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [isSendingReport, setIsSendingReport] = useState(false);
  const [sendReportStatus, setSendReportStatus] = useState({
    status: "initial",
    message: "",
  });

  const handleSubmit = (event) => {
    event?.preventDefault();
    if (!send) {
      calculateReport(formData);
      return setSend(!send);
    }
    const filter = (node) => {
      return node.id !== "filtrar";
    };
    console.log("Trying to send Report.");
    setIsSendingReport(true);
    setSendReportStatus({
      status: "pending",
      message: i18n.t("cropmix.page02.dialog.sendReportStatus.sending"),
    });
    domtoimage
      .toPng(document.getElementById("element-to-print"), { filter: filter })
      .then((dataUrl) => {
        var img = new Image();
        img.src = dataUrl;
        var doc = new jsPDF({
          orientation: "l",
          unit: "mm",
          format: "a4",
          putOnlyUsedFonts: true,
          floatPrecision: 16, // or "smart", default is 16
        });
        doc.addImage(img, "PNG", 75, 20, 140, 150);
        // doc.addImage(img, "PNG", 40, 20, 200, 150);
        // doc.save("Reporte Cropmix");

        // function srcToFile(src, fileName, mimeType) {
        //   return fetch(src)
        //     .then(function (res) {
        //       return res.arrayBuffer();
        //     })
        //     .then(function (buf) {
        //       return new File([buf], fileName, { type: mimeType });
        //     });
        // }
        const file = new File(
          [doc.output("arraybuffer", "Reporte Cropmix.pdf")],
          "Reporte Cropxmix.pdf",
          { type: "application/pdf" }
        );
        // srcToFile(
        //   doc.output("arraybuffer", "Reporte.pdf"),
        //   "Reporte CropMix.pdf",
        //   "application/pdf"
        // ).then(function (file) {
        //   postBackend(file);
        // });

        const postBackend = async (file) => {
          let fd = new FormData();
          fd.append("mailContacto", mailContacto);
          fd.append("reporte", file);
          fd.append("idioma", i18n.language);
          return await fetch("http://maniseros.com/api/reporte", {
            method: "POST",
            body: fd,
            redirect: "follow",
          });
        };
        postBackend(file)
          .then((res) => {
            console.log(res);
            let message = res.statusText;
            if (res.status > 400) {
              throw new Error(message);
            }
            setIsSendingReport(false);
            setSendReportStatus({
              status: "ok",
              message: i18n.t("cropmix.page02.dialog.sendReportStatus.sent"),
            });
            console.log("Report sent successfully");
            setTimeout(() => {
              setFormData(defaultFormData);
              setMailContacto("");
              setSend(false);
              setIsSendingReport(false);
              setSendReportStatus({
                status: "initial",
                message: "",
              });
            }, 2500);
          })
          .catch((error) => {
            console.error("An error ocurred trying to send report: ", error);
            setIsSendingReport(false);

            // Error message handle
            switch (error.message) {
              case "Failed to fetch":
                setSendReportStatus({
                  status: "error",
                  message: i18n.t(
                    "cropmix.page02.dialog.sendReportStatus.fetchError"
                  ),
                });
                break;

              case "Unprocessable Entity":
                setSendReportStatus({
                  status: "error",
                  message:
                    "E-mail inválido. Por favor ingrese una dirección de correo válida.",
                });
                break;

              case error.message:
                setSendReportStatus({
                  status: "error",
                  message: "Error sin registro: " + error.message,
                });
                break;

              case "":
                setSendReportStatus({
                  status: "error",
                  message: i18n.t(
                    "cropmix.page02.dialog.sendReportStatus.error"
                  ),
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.error("Oops, something went wrong!", error);
        setIsSendingReport(false);
        setSendReportStatus({
          status: "error",
          message: "Error del servidor: " + error.message,
        });
      });
  };
  const handleGoBack = () => {
    setSend(false);
  };

  const calculateReport = ({
    sojaPer,
    maizPer,
    sojaRto,
    sojaCosto,
    sojaAlquiler,
    sojaPrecio,
    maizRto,
    maizCosto,
    maizAlquiler,
    maizPrecio,
  }) => {
    sojaRto = sojaRto === "" ? 0 : sojaRto;
    sojaCosto = sojaCosto === "" ? 0 : sojaCosto;
    sojaAlquiler = sojaAlquiler === "" ? 0 : sojaAlquiler;
    sojaPrecio = sojaPrecio === "" ? 0 : sojaPrecio;
    maizRto = maizRto === "" ? 0 : maizRto;
    maizCosto = maizCosto === "" ? 0 : maizCosto;
    maizAlquiler = maizAlquiler === "" ? 0 : maizAlquiler;
    maizPrecio = maizPrecio === "" ? 0 : maizPrecio;

    const sojaManiPer = Number((sojaPer * 0.8).toFixed(2));
    const maizManiPer = Number((maizPer * 0.8).toFixed(2));
    const maniPer = 0.2;

    const ingresoBrutoActual = Number(
      (sojaRto * sojaPrecio * sojaPer + maizRto * maizPrecio * maizPer).toFixed(
        2
      )
    );
    const costosActual = Number(
      (sojaCosto * sojaPer + maizCosto * maizPer).toFixed(2)
    );
    const alquilerActual = Number(
      (sojaAlquiler * sojaPer + maizAlquiler * maizPer).toFixed(2)
    );
    const margenBrutoActual = Number(
      (ingresoBrutoActual - costosActual - alquilerActual).toFixed(2)
    );

    const ingresoBrutoMani = Number(
      (
        sojaRto * sojaPrecio * sojaManiPer +
        maizRto * maizPrecio * maizManiPer +
        (sojaRto * 0.8 + 0.5) * 800 * maniPer
      ).toFixed(2)
    );
    const costosMani = Number(
      (
        sojaCosto * sojaManiPer +
        maizCosto * maizManiPer +
        650 * maniPer
      ).toFixed(2)
    );
    const alquilerMani = Number(
      (
        sojaAlquiler * sojaManiPer +
        maizAlquiler * maizManiPer +
        sojaAlquiler * maniPer * 1.8
      ).toFixed(2)
    );
    const margenBrutoMani = Number(
      (ingresoBrutoMani - costosMani - alquilerMani).toFixed(2)
    );

    const deltaIngresoBruto = Number(
      (ingresoBrutoMani - ingresoBrutoActual).toFixed(2)
    );
    const deltaCostos = Number((costosMani - costosActual).toFixed(2));
    const deltaAlquiler = Number((alquilerMani - alquilerActual).toFixed(2));
    const deltaMargenBruto = Number(
      (margenBrutoMani - margenBrutoActual).toFixed(2)
    );

    setCalculatedData({
      sojaPer: sojaPer,
      maizPer: maizPer,
      sojaManiPer,
      maizManiPer,
      maniPer,
      ingresoBrutoActual,
      costosActual,
      alquilerActual,
      margenBrutoActual,
      ingresoBrutoMani,
      costosMani,
      alquilerMani,
      margenBrutoMani,
      deltaIngresoBruto,
      deltaCostos,
      deltaAlquiler,
      deltaMargenBruto,
    });
  };

  return (
    <section className="Cropmix">
      <div className="space"></div>
      <img
        src="/assets/deco-gold.svg"
        alt="deco"
        className="deco-gold-contacto pt-4"
      />
      <div className="gold-cropmix"></div>
      <div className="container mb-5 pb-3">
        <div className="d-md-flex">
          <Highlights
            classNameH="interna"
            firstLineH={i18n.t("cropmix.titleFirstLineHighlight")}
            firstLine={i18n.t("cropmix.titleFirstLine")}
          />
        </div>
        <div className="formContainer bg-white mb-5">
          <form onSubmit={handleSubmit}>
            <div className="col">
              {(!send && (
                <CropmixFormPageOne
                  setFormData={setFormData}
                  formData={formData}
                />
              )) || (
                <CropmixFormPageTwo
                  submitReport={handleSubmit}
                  handleGoBack={handleGoBack}
                  calculatedData={calculatedData}
                  setMailContacto={setMailContacto}
                  mailContacto={mailContacto}
                  setReportModalOpen={setReportModalOpen}
                  isReportModalOpen={isReportModalOpen}
                  isSendingReport={isSendingReport}
                  sendReportStatus={sendReportStatus}
                  setSendReportStatus={setSendReportStatus}
                  setIsSendingReport={setIsSendingReport}
                />
              )}
            </div>
          </form>
        </div>
      </div>

      <ContactoProgramas />
      <Footer />
    </section>
  );
}
