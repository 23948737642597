import React from "react";
import Highlights from "./Highlights";
import i18n from "../i18n/index.js";
import "../scss/ProgramaLlave.scss";

function ProgramaLlave() {
  return (
    <>
      <section className="Llave">
        <div className="position-relative py-5">
          <div className="bg-grey-llave"></div>
          <div className="container position-relative">
            <img
              src="/assets/gold-fedea.svg"
              alt="deco"
              className="deco-fedeagold"
            />
            <div className="row">
              <div className="col-md-6 section01">
                <Highlights
                  classNameH="interna"
                  firstLineH={i18n.t(
                    "programs.sectionFive.titleFirstLineHighlight"
                  )}
                  firstLine={i18n.t("programs.sectionFive.titleFirstLine")}
                  secondLineH={i18n.t(
                    "programs.sectionFive.titleSecondLineHighlight"
                  )}
                  secondLine={i18n.t("programs.sectionFive.titleSecondLine")}
                />
                <p className="destacadoLlave my-4 my-md-0">
                  {i18n.t("programs.sectionFive.bajada")}
                </p>
                <p className="infoLlave">
                  {i18n.t("programs.sectionFive.desc01")}
                </p>
                <p className="infoLlave">
                  {i18n.t("programs.sectionFive.desc02")}
                </p>
              </div>
              <div className="col-md-6">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/d/u/3/edit?mid=1UYS6M_6n85kULsvtXHUF0rbo1fmXsj0a&usp=sharing"
                  rel="noreferrer"
                >
                  <img src="/assets/map-llave.png" alt="map" className="map" />
                </a>
                <img
                  src="/assets/sello-arg.svg"
                  alt="arg"
                  className="sello-arg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ventajas py-3 py-md-5">
          <div className="container">
            <img
              src="/assets/big-gold.svg"
              alt="deco"
              className="deco-big-gold"
            />
            <div className="gold-ventajas"></div>
            <h3 className="title-compromise">
              {i18n.t("programs.sectionSix.subtitle")}
            </h3>
            <div className="row">
              <div className="col-md-6">
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box01")}</p>
                </div>
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box03")}</p>
                </div>
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box04")}</p>
                </div>
                <div className="ventaja mb-0">
                  <p>
                    {i18n.t("programs.sectionSix.box05")}
                    {/* <img src="/assets/asterisco.svg" className="asterisco" alt="asterisco"/> */}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box02")}</p>
                </div>
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box04")}</p>
                </div>
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box06")}</p>
                </div>
                <div className="ventaja">
                  <p>{i18n.t("programs.sectionSix.box08")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProgramaLlave;
