import React from "react";
import Highlights from "./Highlights";
import ProgramaFedea from "./ProgramaFedea";
import ProgramaLlave from "./ProgramaLlave";
import ExitoProgramas from "./ExitoProgramas";
import ContactoProgramas from "./ContactoProgramas";
import Footer from "./Footer";
import i18n from "../i18n/index.js";
import "../scss/Programas.scss";

function Programas() {
  return (
    <>
      <div className="Programas">
        <div className="space"></div>
        <div className="container">
          <section className="introProgramas">
            <p className="nameSection">
              {i18n.t("programs.sectionOne.subtitle")}
            </p>
            <div className="row">
              <div className="col-md-6 deco-internas">
                <Highlights
                  classNameH="interna"
                  firstLineH={i18n.t(
                    "programs.sectionOne.titleFirstLineHighlight"
                  )}
                  firstLine={i18n.t("programs.sectionOne.titleFirstLine")}
                  secondLineH={i18n.t(
                    "programs.sectionOne.titleSecondLineHighlight"
                  )}
                  secondLine={i18n.t("programs.sectionOne.titleSecondLine")}
                  thirthLineH={i18n.t(
                    "programs.sectionOne.titleThirdLineHightlight"
                  )}
                  thirthLine={i18n.t("programs.sectionOne.titleThirdLine")}
                />
                <img
                  className="deco-programas-01"
                  src="./assets/deco-gold.svg"
                  alt=""
                />
              </div>
              <div className="col-md-6 d-none d-md-flex">
                <p className="descPrograms">
                  {i18n.t("programs.sectionOne.desc")}
                </p>
              </div>
            </div>
          </section>
          <section className="infoProgramas py-5">
            <div className="detalle-blue"></div>
            <img
              src="/assets/deco-grey.svg"
              alt="deco"
              className="grey-deco-programas"
            />
            <div className="row justify-content-between">
              <div className="col-md-5 text-left bg-p mx-3 mx-md-0 mb-5 mb-md-0">
                <div className="row flex-md-column">
                  <img
                    src="/assets/sello-fedea.svg"
                    alt="sello Fedea"
                    className="sello-programas"
                  />
                  <h2 className="titleprograma">
                    1. {i18n.t("programs.sectionOne.cuadro01")}
                  </h2>
                  <p className="d-none d-md-block textprograma">
                    {i18n.t("programs.sectionOne.bajada01")}
                  </p>
                </div>
                <a href="#fedea" className="row botton-mas">
                  <p>{i18n.t("programs.sectionOne.boton01")}</p>
                  <img
                    src="/assets/arrow-bottom.svg"
                    alt="flecha"
                    className="arrow-conocer"
                  />
                </a>
              </div>
              <div className="col-md-5 text-left bg-p mx-3 mx-md-0 mb-5 mb-md-0">
                <div className="row flex-md-column">
                  <img
                    src="/assets/llave-en-mano.svg"
                    alt="sello Llave en Mano"
                    className="sello-programas"
                  />
                  <h2 className="titleprograma">
                    2. {i18n.t("programs.sectionOne.cuadro02")}
                  </h2>
                  <p className="d-none d-md-block textprograma">
                    {i18n.t("programs.sectionOne.bajada02")}
                  </p>
                </div>
                <a href="#llaveenmano" className="row botton-mas">
                  <p>{i18n.t("programs.sectionOne.boton01")}</p>
                  <img
                    src="/assets/arrow-bottom.svg"
                    alt="flecha"
                    className="arrow-conocer"
                  />
                </a>
              </div>
            </div>
          </section>
          <div className="d-md-none">
            <p className="descPrograms">{i18n.t("programs.sectionOne.desc")}</p>
          </div>
        </div>
        <div className="bg-gold">
          <div className="bg-gold-color"></div>
        </div>
      </div>
      <div id="fedea" className="spacefedea"></div>
      <ProgramaFedea />
      <div className="divisor-programas">
        <div className="row">
          <div className="bluedark"></div>
          <div className="gold"></div>
        </div>
      </div>
      <div id="llaveenmano" className="spacefedea">
        <div className="bg-grey-llave"></div>
      </div>
      <ProgramaLlave />
      <ExitoProgramas />
      <ContactoProgramas />
      <Footer />
    </>
  );
}

export default Programas;
