import React, { useState, useEffect } from "react";
import Highlights from "./Highlights";
import Footer from "./Footer";
import Aviacion from "./Aviacion";
import AviacionPageTwo from "./AviacionPageTwo";
import Alerts from "./Alerts";
import "../scss/Aliados.scss";
import "../scss/Aviacion.scss";
import i18n from "../i18n/index.js";

function AviacionProdeman() {
  const [page, setPage] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [mapFile, setMapFile] = useState();
  const [toggleAlert, setToggleAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOK, setIsOK] = useState(false);

  const handleFormPages = () => {
    setPage(!page);
  };

  const handleGoBack = () => {
    setPage(false);
  };

  const [isMobile, setIsMobile] = useState(false);

  const reportWindowSize = (e) => {
    const actualWidth = e.target.innerWidth;
    if (actualWidth < 768 && !isMobile) setIsMobile(true);
    if (actualWidth >= 768) setIsMobile(false);
  };

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
    else setIsMobile(false);

    window.addEventListener("resize", reportWindowSize);
    return () => window.removeEventListener("resize", reportWindowSize);
  });

  const handleSubmit = ({ event, formData, activeCheckboxes }) => {
    event.preventDefault();
    setToggleAlert(true);
    setIsLoading(true);

    const cropSelectorToString = (cropSelector) => {
      const arrayNames = [
        i18n.t("partners.aviacion.page02.sectionOne.selector.item01"),
        i18n.t("partners.aviacion.page02.sectionOne.selector.item02"),
        i18n.t("partners.aviacion.page02.sectionOne.selector.item03"),
        i18n.t("partners.aviacion.page02.sectionOne.selector.item04"),
        i18n.t("partners.aviacion.page02.sectionOne.selector.item05"),
        i18n.t("partners.aviacion.page02.sectionOne.selector.item06"),
      ];

      return arrayNames[parseInt(cropSelector) - 1];
    };

    const postBackend = async () => {
      const arrayNames = [
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item0"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item1"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item2"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item3"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item4"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item5"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item6"),
        i18n.t("partners.aviacion.page02.sectionOne.arrayNames.item7"),
      ];

      const products = activeCheckboxes.reduce((a, b, index) => {
        let key = arrayNames[index];
        let value = b;
        let product = { [key]: value };
        return (a = { ...a, ...product });
      }, {});

      let fd = new FormData();
      fd.append("mapa", mapFile);
      fd.append("idioma", i18n.language);
      fd.append("fullName", formData.fullName);
      fd.append("socialName", formData.socialName);
      fd.append("cuit", formData.cuit);
      fd.append("products", JSON.stringify(products));
      fd.append("phoneNumber", formData.phoneNumber);
      fd.append("email", formData.email);
      fd.append("cropSelector", cropSelectorToString(formData.cropSelector));
      fd.append("notes", formData.notes);

      return await fetch("http://maniseros.com/api/aviacion", {
        method: "POST",
        body: fd,
        redirect: "follow",
      });
    };
    postBackend()
      .then((response) => {
        console.log(response);
        if (response.status >= 400) {
          throw new Error(response.json());
        }
        response.json();
      })
      .then((data) => console.log(data))
      .then(() => {
        setToggleAlert(false);
        setIsLoading(false);
        setIsSubmited(!isSubmited);
        setPage(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsOK(false);
        setTimeout(() => {
          setToggleAlert(false);
          setPage(false);
        }, 3000);
      });
  };

  if (isSubmited && !toggleAlert && !isLoading) {
    window.scrollTo(0, 0);
    return (
      <>
        <section className="aviacionFinalPage mb-5">
          <div className="space"></div>
          <div className="Hero">
            <div className="bg-white">
              <div className="container">
                <div className="col-md-12 text-left my-5 pb-5">
                  <p>Partners</p>
                </div>
              </div>
            </div>
            <img
              src="/assets/deco-gold.svg"
              alt="deco"
              className="deco-descrips"
            />
            <div className="container thanksContainer">
              <div className="col-md-12 text-left my-5">
                <div className="col-md-12">
                  <Highlights
                    classNameH="interna"
                    firstLineH={i18n.t(
                      "partners.aviacion.page01.sectionThree.titleFirstLineHighlight01"
                    )}
                    firstLine={i18n.t(
                      "partners.aviacion.page01.sectionThree.titleFirstLine01"
                    )}
                  />
                </div>
                <div className="col-md-6 ">
                  <p className="finalPageResponse bg-white">
                    {i18n.t(
                      "partners.aviacion.page01.sectionThree.content.firstLine"
                    )}
                    <br />
                    {i18n.t(
                      "partners.aviacion.page01.sectionThree.content.secondLine"
                    )}
                  </p>
                </div>
                <div className="col-md-12">
                  <Highlights
                    classNameH="interna"
                    firstLineH={i18n.t(
                      "partners.aviacion.page01.sectionThree.titleFirstLineHighlight02"
                    )}
                    firstLine={i18n.t(
                      "partners.aviacion.page01.sectionThree.titleFirstLine02"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            src="/assets/sello-arg.svg"
            alt="deco"
            className="selloArg"
            style={{ height: "100px" }}
          />
          <div className="gold-aviacion"></div>
        </section>
        <Footer />
      </>
    );
  }

  return (
    <>
      <section className="aliado-Fedea aviacionContainer mb-5">
        <div className="space"></div>
        <div className="Hero">
          <img
            className="aviacionBanner"
            src={
              isMobile
                ? "/assets/Prodeman-intro-mobile.jpg"
                : "/assets/Prodeman-intro.jpg"
            }
            alt="hero"
          />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/Prodeman-Logo.png"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.aviacion.page01.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t(
                  "partners.aviacion.page01.sectionOne.titleFirstLine"
                )}
                secondLineH={i18n.t(
                  "partners.aviacion.page01.sectionOne.titleSecondLineHighlight"
                )}
                secondLine={i18n.t(
                  "partners.aviacion.page01.sectionOne.titleSecondLine"
                )}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="container py-5 my-5 d-sm-flex align-items-center descripciónContainer">
            <div className="d-flex w-100 align-items-center justify-content-around">
              <div className="align-items-center">
                <p className="title-presentacion">
                  {i18n.t("partners.aviacion.page01.sectionTwo.title")}
                </p>
              </div>
            </div>
            <div className="col-md-6 ">
              <p className="text-aliado mb-3">
                {i18n.t("partners.aviacion.page01.sectionTwo.content.firstP")}
                <br />
                {i18n.t("partners.aviacion.page01.sectionTwo.content.secondP")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        {!page ? (
          <Aviacion
            changePage={handleFormPages}
            mapFile={mapFile}
            setMapFile={setMapFile}
          />
        ) : (
          <AviacionPageTwo
            handleFormSubmit={handleSubmit}
            handleGoBack={handleGoBack}
          />
        )}
      </form>
      <Footer />
      {toggleAlert && (
        <Alerts
          toggleAlert={toggleAlert}
          isOK={isOK}
          message={{
            pending: i18n.t("partners.aviacion.alert.pending"),
            success: i18n.t("partners.aviacion.alert.success"),
            error: i18n.t("partners.aviacion.alert.error"),
          }}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default AviacionProdeman;
