import React from "react";
import Highlights from "./Highlights";
import DropdownProgramas from "./DropdownProgramas";
// import DropdownProducto from "./DropdownProducto";
import i18n from "../i18n/index.js";
import "../scss/ProgramaFedea.scss";

function ProgramaFedea() {
  return (
    <section className="Fedea">
      <div className="position-relative pt-5">
        <div className="bg-blue-fedea"></div>
        <div className="container position-relative">
          <img
            src="/assets/gold-fedea.svg"
            alt="deco"
            className="deco-fedeagold"
          />
          <div className="row">
            <div className="col-md-6 section01">
              <Highlights
                classNameH="homeH"
                firstLine={i18n.t("programs.sectionTwo.titleFirstLine")}
                firstLineH2={i18n.t(
                  "programs.sectionTwo.titleFirstLineHighlight"
                )}
                secondLine={i18n.t(
                  "programs.sectionTwo.titleSecondLineHighlight"
                )}
                secondLineH2={i18n.t("programs.sectionTwo.titleSecondLine")}
                secondLineW=" "
              />
              <p className="infofedea my-5 my-md-0">
                {i18n.t("programs.sectionTwo.desc")}
              </p>
            </div>
            <div className="col-md-6">
              <a
                target="_blank"
                href="https://www.google.com/maps/d/u/0/viewer?mid=1dFfh8oELqfRfMqBOTMdTgTWqQZqrw3_J&ll=-32.47804922591111%2C-61.861132999999995&z=6"
                rel="noreferrer"
              >
                <img src="/assets/map.png" alt="map" className="map" />
              </a>
              {i18n.language === "es" ? (
                <img
                  src="/assets/sello-01.svg"
                  alt="arg"
                  className="sello-arg"
                />
              ) : (
                <img
                  src="/assets/sello-01-en.png"
                  alt="arg"
                  className="sello-arg"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="compromiso position-relative pb-5">
        <div className="container">
          <h3 className="title-compromise">
            {i18n.t("programs.sectionThree.subtitle")}
          </h3>
          <div className="row">
            <div className="col-md-6 my-2">
              <DropdownProgramas
                titleDropPrograms={i18n.t("programs.sectionThree.box01")}
                dataTarget="direccion"
                infoInterior={i18n.t("programs.sectionThree.desc01")}
              />
            </div>
            <div className="col-md-6 my-2">
              <DropdownProgramas
                titleDropPrograms={i18n.t("programs.sectionThree.box02")}
                dataTarget="provision"
                infoInterior={i18n.t("programs.sectionThree.desc02")}
              />
            </div>
            <div className="col-md-6 my-2">
              <DropdownProgramas
                titleDropPrograms={i18n.t("programs.sectionThree.box03")}
                dataTarget="organizacion"
                infoInterior={i18n.t("programs.sectionThree.desc03")}
              />
            </div>
            <div className="col-md-6 my-2">
              <DropdownProgramas
                titleDropPrograms={i18n.t("programs.sectionThree.box04")}
                dataTarget="capacitaacion"
                infoInterior={i18n.t("programs.sectionThree.desc04")}
              />
            </div>
            <div className="col-md-6 my-2">
              <DropdownProgramas
                titleDropPrograms={i18n.t("programs.sectionThree.box05")}
                dataTarget="compra"
                infoInterior={i18n.t("programs.sectionThree.desc05")}
              />
            </div>
          </div>
        </div>
        <img src="/assets/big-gold.svg" alt="deco" className="deco-big-gold" />
      </div>
      <div className="beneficios py-5">
        <div className="bg-blue-light"></div>
        <img
          src="/assets/wave-gold.svg"
          alt="wave gold"
          className="wave-beneficios-01"
        />
        <img
          src="/assets/wave-gold.svg"
          alt="wave gold"
          className="wave-beneficios-02"
        />
        {i18n.language === "es" ? (
          <img
            src="/assets/sello-mapa.svg"
            alt="sello"
            className="sello-mapa"
          />
        ) : (
          <img
            src="/assets/sello-mapa-en.png"
            alt="sello"
            className="sello-mapa"
          />
        )}
        <div className="container">
          <h3 className>{i18n.t("programs.sectionFour.subtitle")}</h3>
          <div className="row justify-content-between">
            <div className="col-md-4">
              <div className="beneficio m-md-1">
                <p className="title-beneficio">
                  {i18n.t("programs.sectionFour.boxTitle01")}
                </p>
                <p className="text-beneficio">
                  {i18n.t("programs.sectionFour.desc01")}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="beneficio m-md-1">
                <p className="title-beneficio">
                  {i18n.t("programs.sectionFour.boxTitle02")}
                </p>
                <p className="text-beneficio">
                  {i18n.t("programs.sectionFour.desc02")}.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="beneficio m-md-1">
                <p className="title-beneficio">
                  {i18n.t("programs.sectionFour.boxTitle03")}
                </p>
                <p className="text-beneficio">
                  {i18n.t("programs.sectionFour.desc03")}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProgramaFedea;
