import React from "react";
import Highlights from "./Highlights";
import ContactoProgramas from "./ContactoProgramas";
import Footer from "./Footer";
import "../scss/bootstrap.scss";
import "../scss/Blog.scss";
import { Link } from "react-router-dom";
import { blogContent } from "../content/BlogsContent";
import { Button } from "@material-ui/core";
import i18n from "../i18n/index.js";

export default function Blog() {
  const listNotes = () => {
    const filteredList = blogContent.slice(3, blogContent.length);
    return filteredList.map((note, index) => {
      return (
        <li
          key={note.id}
          className="py-3"
          style={{
            borderBottom:
              index === filteredList.length - 1 ? "none" : "1px solid #deab5f",
          }}
        >
          <div className="d-flex flex-row align-items-center listItemContainer">
            <img
              src={note.images[0]}
              className="previewImage bg-white p-1 ml-3"
              alt="preview"
            />
            <span className="text-left ml-2 w-50">
              <h4>
                <b>{note.title}</b>
              </h4>
            </span>
            <div
              className="text-right align-self-center ml-md-3 leerMas"
              style={{ width: "30%" }}
            >
              <Link to={`blog/note/${index + 4}`}>
                <Button className="readMore ml-4">
                  {i18n.t("blog.readMore")}
                  <img
                    src={"/assets/arrow-next.svg"}
                    alt="arrow"
                    className="arrow-cta ml-3"
                  />
                </Button>
              </Link>
            </div>
          </div>
        </li>
      );
    });
  };
  //<img src="/assets/sello-arg.svg" alt="deco" className="selloArg" />
  return (
    <section className="Blog">
      <div className="space"></div>
      <div className="introBackground">
        <img
          src="/assets/deco-gold.svg"
          alt="deco"
          className="deco-historia"
          style={{ width: "100%" }}
        />
      </div>
      <div className="blogBackground blogOverview"></div>

      <div className="container blogContainer pb-5">
        <section className="introBlog">
          <div className="">
            <Highlights
              classNameH="interna"
              firstLineH={i18n.t("blog.titleFirstLineHighlight")}
              firstLine={i18n.t("blog.titleFirstLine")}
            />
          </div>
        </section>

        <section className="py-5 my-5">
          <h3 className="text-left mb-5">{i18n.t("blog.newArticle")}</h3>
          <div className="d-md-flex justify-content-around">
            <div className="w-75 bg-white p-2">
              <img src={blogContent[0].images[0]} alt="preview" />
            </div>
            <div className="col-md-5 pl-4 text-left">
              <h2 className="pb-5">{blogContent[0].title}</h2>
              <b className="py-2">{blogContent[0].subtitle}</b>
              <p className="contentPreview py-3">
                {blogContent[0].contentPreview}
              </p>
              <div className="text-right py-2">
                <Link to={`blog/note/1`}>
                  <Button className="readMore">
                    {i18n.t("blog.readMore")}
                    <img
                      src={"/assets/arrow-next.svg"}
                      alt="arrow"
                      className="arrow-cta ml-3"
                    />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section
          className="py-5 my-5"
          style={{
            borderTop: "4px solid #152C39",
            borderBottom: "4px solid #152C39",
          }}
        >
          <h3 className="text-left mb-5">{i18n.t("blog.recentEntries")}</h3>
          <div className="d-flex lastEntries justify-content-between">
            <span className="pairNote w-50">
              <div className="d-flex w-75 flex-column ">
                {
                  <img
                    src={blogContent[1].images[0]}
                    className="previewImage bg-white p-2"
                    alt="preview"
                  />
                }
                <span className="d-flex w-100 flex-column justify-content-between">
                  <h4 className="py-2 title">
                    <b>{blogContent[1].title}</b>
                  </h4>
                  <div className="py-2 text-left subtitle">
                    <i>{blogContent[1].subtitle}</i>
                  </div>
                  <div className="text-right py-2 readMoreButton">
                    <Link to={`blog/note/2`}>
                      <Button className="readMore">
                        {i18n.t("blog.readMore")}
                        <img
                          src={"/assets/arrow-next.svg"}
                          alt="arrow"
                          className="arrow-cta ml-3"
                        />
                      </Button>
                    </Link>
                  </div>
                </span>
              </div>
            </span>
            <span className="pairNote w-50">
              <div className="d-flex w-75 flex-column justify-content-around ">
                {
                  <img
                    src={blogContent[2].images[0]}
                    className="previewImage bg-white p-2"
                    alt="preview"
                  />
                }
                <span className="d-flex w-100 flex-column justify-content-between">
                  <h4 className="py-2 title">
                    <b>{blogContent[2].title}</b>
                  </h4>
                  <div className="py-2 text-left subtitle">
                    <i>{blogContent[2].subtitle}</i>
                  </div>
                  <div className="text-right py-2 readMoreButton">
                    <Link to={`blog/note/3`}>
                      <Button className="readMore">
                        {i18n.t("blog.readMore")}
                        <img
                          src={"/assets/arrow-next.svg"}
                          alt="arrow"
                          className="arrow-cta ml-3"
                        />
                      </Button>
                    </Link>
                  </div>
                </span>
              </div>
            </span>
          </div>
        </section>

        <section className="py-5 my-5">
          <h3 className="text-left mb-5">{i18n.t("blog.previousEntries")}</h3>
          <ul className="notesList justify-content-center">{listNotes()}</ul>
        </section>
      </div>
      <ContactoProgramas />
      <Footer />
    </section>
  );
}
