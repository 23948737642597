import React from "react";
import "../scss/_variables.scss";

function WhatsApp() {
  return (
    <>
      <div className="whatsapp">
        <a href="https://wa.me/5493586007380" target="_blank" rel="noreferrer">
          <img src="/assets/icon-wpp.svg" alt="linea-wpp" />
        </a>
      </div>
    </>
  );
}
export default WhatsApp;
