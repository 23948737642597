
import {atom} from 'recoil'

// export const nameState = atom({
//   key: 'nameState', // unique ID (with respect to other atoms/selectors)
//   default: '', // default value (aka initial value)
// });
// export const emailState = atom({
//   key: 'emailState', // unique ID (with respect to other atoms/selectors)
//   default: '', // default value (aka initial value)
// });

export const formState = atom({
  key: 'formState', // unique ID (with respect to other atoms/selectors)
  default: {
    name: '',
    surname:'',
    email: '',
    phone: ''
  }, // default value (aka initial value)
});