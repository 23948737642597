import Header from './components/Header';
import Routes from './Routes';
import Footer from './components/Footer';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {
  RecoilRoot
} from 'recoil';
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";


const ScrollToTop = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Router>
            <ScrollToTop />
          <div>
            <Header/>
            <Routes/>
          </div>
        </Router>
      </RecoilRoot>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
