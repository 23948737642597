import React from "react";
import Carousel from "react-elastic-carousel";
import i18n from "../i18n/index.js";
import "../scss/CarouselAliados.scss";

function CarouselAliados() {
  return (
    <>
      <div className="">
        <Carousel
          breakPoints={[
            { width: 1, itemsToShow: 1 },
            { width: 400, itemsToShow: 3, itemsToScroll: 2, pagination: true },
            { width: 850, itemsToShow: 3 },
          ]}
        >
          <div className="beneficio fedea">
            <a href="https://www.corteva.com.ar/" target="_blank">
              <img
                className="d-block mb-3"
                src="/assets/logo-corteva.png"
                alt="First slide"
              />
              <p>{i18n.t("partners.fedea.sectionSix.box01")}</p>
            </a>
          </div>
          <div className="beneficio fedea">
            <a
              href="https://www.axionenergy.com/Paginas/agro.aspx"
              target="_blank"
            >
              <img
                className="d-block mb-3"
                src="/assets/logo-axion.png"
                alt="First slide"
              />
              <p>{i18n.t("partners.fedea.sectionSix.box02")}</p>
            </a>
          </div>
          <div className="beneficio fedea">
            <a href="https://www.brevant.com.ar/" target="_blank">
              <img
                className="d-block mb-3"
                src="/assets/logo-brevant.svg"
                alt="First slide"
              />
              <p>{i18n.t("partners.fedea.sectionSix.box03")}</p>
            </a>
          </div>
          <div className="beneficio fedea">
            <a href="https://www.fedea.com.ar/" target="_blank">
              <img
                className="d-block mb-3 fedea-img"
                src="/assets/logo-fedea.svg"
                alt="First slide"
              />
              <p>{i18n.t("partners.fedea.sectionSix.box04")}</p>
            </a>
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default CarouselAliados;
