import React from "react";
// import Video from './Video'
// import {useParams} from "react-router-dom";
// import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import { Link } from "react-router-dom";
// import sobremani from './sobremani.json'
import sobremani from "./sobremaniinfo.js";
import i18n from "../i18n/index.js";
import "../scss/DropdownProgramas.scss";
import "../scss/bootstrap.scss";
import "../scss/SobreMani.scss";

function SobreMani({ currentPeanutPlantStatus }) {
  const cicloState = sobremani.find(
    ({ id }) => id === currentPeanutPlantStatus
  );
  // const cicloState = (sobremani[2]);
  if (!cicloState) {
    return <></>;
  }
  // const cicloState = i18n.find(({id}) => id === currentPeanutPlantStatus)
  // // const cicloState = (sobremani[2]);
  // if(!cicloState) {return <></>}
  return (
    <>
      <div className="info-one">
        <div className="d-flex flex-column flex-md-row justify-content-between content-etapa">
          <div className="col-md-4 info-gral d-none d-md-block">
            <h4>{cicloState.Name}</h4>
            <p className="gdu-base">Gdu {cicloState.Gdu}</p>
            <p className="gdu-base">T° Base {cicloState.Base}</p>
            <p>{cicloState.info}</p>
            <div className="row align-items-start">
              <div className="col-md-5 pr-0">
                <p className="subtitle">{i18n.t("lyfecycle.progress")}</p>
                <img
                  src={cicloState.Progresoimg}
                  alt="mani"
                  className="maniporcentaje"
                />
              </div>
              {cicloState.Apartado && (
                <div className="col-md-5 pl-0 d-flex flex-column justify-content-center align-items-center">
                  <p className="subtitle">{i18n.t("lyfecycle.sanity")}</p>
                  <Link to="/oroverde">
                    <img
                      src={cicloState.LogoApartado}
                      alt=""
                      className="logo-sanidad"
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="info-gral d-md-none">
            <div className="DropdownProgramas">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed d-flex justify-content-space-between"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#${cicloState.idDropdown}`}
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h4 className="cicloStateMobile">{cicloState.Name}</h4>
                        <img src="/assets/arrow-down.svg" alt="flecha" />
                      </button>
                    </h2>
                  </div>
                  <div
                    id={cicloState.idDropdown}
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body text-left">
                      <p className="gdu-base">Gdu {cicloState.Gdu}</p>
                      <p className="gdu-base">T° Base {cicloState.Base}</p>
                      <p>{cicloState.info}</p>
                      <div className="row align-items-start">
                        <div className="col-6 col-md-5 pr-0">
                          <p className="subtitle">
                            {i18n.t("lyfecycle.progress")}
                          </p>
                          <img
                            src={cicloState.Progresoimg}
                            alt="mani"
                            className="maniporcentaje"
                          />
                        </div>
                        {cicloState.Apartado && (
                          <div className="col-6 col-md-5 pl-0 d-flex flex-column justify-content-center align-items-center">
                            <p className="subtitle">
                              {i18n.t("lyfecycle.progress")}
                            </p>
                            <Link to="/oroverde">
                              <img
                                src={cicloState.LogoApartado}
                                alt=""
                                className="logo-sanidad"
                              />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(cicloState.BeneficioTitle || cicloState.SolucionesTitle) && (
            <div className="col-9 col-md-4 beneficio d-none d-md-block">
              {cicloState.BeneficioTitle && (
                <div>
                  <h4>{cicloState.BeneficioTitle}</h4>
                  <p>{cicloState.BeneficioInfo}</p>
                </div>
              )}
              <p className="title-soluciones">
                {i18n.t("lyfecycle.solutions")}
              </p>
              {cicloState.SolucionesTitle && (
                <div className="row">
                  {cicloState.Logo01 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo01}>
                        <img src={cicloState.Logo01} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo02 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo02}>
                        <img src={cicloState.Logo02} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo03 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo03}>
                        <img src={cicloState.Logo03} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo04 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo04}>
                        <img src={cicloState.Logo04} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo05 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo05}>
                        <img src={cicloState.Logo05} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo06 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo06}>
                        <img src={cicloState.Logo06} alt="logo" />
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {cicloState.fedea && (
          <div className="col-md-3 fedeaLogo pl-0 d-none d-md-block">
            <p className="fedeaCicloTitle">{i18n.t("lyfecycle.program")}</p>
            <Link to="/fedea" className="d-flex ">
              <img className="fedeaCiclo" src={cicloState.fedea} alt="Fedea" />
            </Link>
          </div>
        )}

        <div className="container d-md-none">
          <div className="row">
            <div className="col-3 col-md-3 fedeaLogo pl-md-0">
              <p className="fedeaCicloTitle">{i18n.t("lyfecycle.program")}</p>
              <Link to="/fedea" className="d-flex ">
                <img
                  className="fedeaCiclo"
                  src={cicloState.fedea}
                  alt="Fedea"
                />
              </Link>
            </div>
            <div className="col-9 col-md-4 beneficio ">
              <p className="title-soluciones">{cicloState.SolucionesTitle}</p>
              {cicloState.SolucionesTitle && (
                <div className="row">
                  {cicloState.Logo01 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo01}>
                        <img src={cicloState.Logo01} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo02 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo02}>
                        <img src={cicloState.Logo02} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo03 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo03}>
                        <img src={cicloState.Logo03} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo04 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo04}>
                        <img src={cicloState.Logo04} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo05 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo05}>
                        <img src={cicloState.Logo05} alt="logo" />
                      </Link>
                    </div>
                  )}
                  {cicloState.Logo06 && (
                    <div className="col-6 col-md-4">
                      <Link to={cicloState.LogoTo06}>
                        <img src={cicloState.Logo06} alt="logo" />
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SobreMani;
