import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/Aliados.scss";

function Fmc() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img className="banner-hero" src="/assets/hero-fmc.png" alt="hero" />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo_FMC.png"
                alt="logo"
                className="logo-aliado"
                style={{ width: "275px" }}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">
                  {i18n.t("partners.fmc.sectionTwo.title01")}
                </p>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.fmc.sectionTwo.desc01")}
                  </p>
                  <p className="text-aliado">
                    <strong>{i18n.t("partners.fmc.sectionTwo.desc02")}</strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <ul
                    style={{
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "0.9rem",
                    }}
                  >
                    <li>{i18n.t("partners.fmc.sectionTwo.item01")}</li>
                    <li>{i18n.t("partners.fmc.sectionTwo.item02")}</li>
                    <li>{i18n.t("partners.fmc.sectionTwo.item03")}</li>
                    <li>{i18n.t("partners.fmc.sectionTwo.item04")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.fmc.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.fmc.sectionThree.box01.title")}
                  imgEtapa="../assets/planta00.jpg"
                  prodPlusProd
                  heap={[
                    {
                      prod1: {
                        href: "http://www.fmcargentina.com.ar/productos/shark-40c/",
                        partnerImg: "/assets/fmc_shark.png",
                      },
                      prod2: {
                        href: "",
                        partnerImg: "/assets/fmc_biofusion.png",
                      },
                    },
                  ]}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.fmc.sectionThree.box02.title")}
                  imgEtapa="../assets/planta0.jpg"
                  dosxdos
                  imgparter01="../assets/fmc_capaz.png"
                  href01="http://www.fmcargentina.com.ar/productos/capaz/"
                  imgpartner1="fmc-partner"
                  imgparter02="../assets/fmc_capaz-elite.png"
                  href02="http://www.fmcargentina.com.ar/productos/capaz-elite/"
                  imgpartner2="fmc-partner"
                  imgparter03="../assets/fmc_command.png"
                  href03="http://www.fmcargentina.com.ar/productos/command-36-cs/"
                  imgpartner3="fmc-partner"
                  imgparter04="../assets/fmc_teliron.png"
                  imgpartner4="fmc-partner"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.fmc.sectionThree.box03.title")}
                  imgEtapa="../assets/planta4.jpg"
                  prodPlusProd
                  heap={[
                    {
                      prod1: {
                        href: "http://www.fmcargentina.com.ar/productos/talstar-extra/",
                        partnerImg: "/assets/fmc_talstar-extra.png",
                      },
                      prod2: {
                        href: "",
                        partnerImg: "/assets/fmc_biofusion.png",
                      },
                    },
                  ]}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.fmc.sectionThree.box04.title")}
                  imgEtapa="../assets/planta4.jpg"
                  prodPlusProd
                  heap={[
                    {
                      prod1: {
                        href: "http://www.fmcargentina.com.ar/productos/talone/",
                        partnerImg: "/assets/fmc_talone-new.png",
                      },
                      prod2: {
                        href: "",
                        partnerImg: "/assets/fmc_biofusion.png",
                      },
                    },
                  ]}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.fmc.sectionThree.box05.title")}
                  imgEtapa="../assets/planta6.jpg"
                  prodPlusProd
                  heap={[
                    {
                      prod1: {
                        href: "http://www.fmcargentina.com.ar/productos/talstar-extra/",
                        partnerImg: "/assets/fmc_talstar-extra.png",
                      },
                      prod2: {
                        href: "",
                        partnerImg: "/assets/fmc_biofusion.png",
                      },
                    },
                    {
                      prod1: {
                        href: "http://www.fmcargentina.com.ar/productos/talone/",
                        partnerImg: "/assets/fmc_talone-new.png",
                      },
                      prod2: {
                        href: "",
                        partnerImg: "/assets/fmc_biofusion.png",
                      },
                    },
                    {
                      prod1: {
                        href: "http://www.fmcargentina.com.ar/productos/rubric-max/",
                        partnerImg: "/assets/fmc_rubric-max.png",
                      },
                      prod2: {
                        href: "",
                        partnerImg: "/assets/fmc_biofusion.png",
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Beneficios py-5">
          <img
            src="/assets/gold-beneficios.svg"
            alt="deco"
            className="gold-beneficios"
          />
          <div className="container">
            <h4 className="mb-4">{i18n.t("partners.fmc.sectionFour.title")}</h4>
            <div className="row">
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <img src="/assets/logo-arc.jpg" alt="multiplica" />
                    <p>{i18n.t("partners.fmc.sectionFour.box01.content")}</p>
                    <a
                      className="dest vervideo"
                      href={i18n.t("partners.fmc.sectionFour.box01.cta")}
                    >
                      {i18n.t("partners.fmc.sectionFour.box01.cta")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <p className="dest mb-3">
                      {i18n.t("partners.fmc.sectionFour.box02.title")}
                    </p>
                    <p>{i18n.t("partners.fmc.sectionFour.box02.content")}</p>
                    <div className="row pl-2 mt-5">
                      <a
                        href="https://www.instagram.com/fmcargentina/"
                        className="sm"
                      >
                        <img src="/assets/sm_insta.png" alt="Instagram" />
                      </a>
                      <a href="https://www.facebook.com/FMCArg/" className="sm">
                        <img src="/assets/sm_face.png" alt="Facebook" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/fmcargentina/"
                        className="sm"
                      >
                        <img src="/assets/sm_linkedin.png" alt="Linkedin" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCU0kFOy4WtWwluuKoGr-3Aw"
                        className="sm"
                      >
                        <img src="/assets/sm_youtube.png" alt="Youtube" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <img src="/assets/whatsapp.png" alt="multiplica" />
                    <p>{i18n.t("partners.fmc.sectionFour.box03.content")}</p>
                    <a
                      href="https://wa.me/5493586029421"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="dest">
                        {i18n.t("partners.fmc.sectionFour.box03.cta1")}
                      </p>
                    </a>
                    <p className="mt-2">
                      {i18n.t("partners.fmc.sectionFour.box03.cta2")}{" "}
                      <a
                        href="http://www.fmcargentina.com.ar/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          fontWeight: 700,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        }}
                      >
                        {i18n.t("partners.fmc.sectionFour.box03.cta3")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Fmc;
