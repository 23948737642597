import React from "react";
import Highlights from "./Highlights";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/HomeAlianzas.scss";

function HomeAlianzas() {
  return (
    <>
      <section className="homeAlianzas py-5">
        <div className="background"></div>
        <img src="/assets/deco-gold.svg" className="bg-img" alt="fondo" />
        <div className="container">
          <p className="epigrafe-contacto">
            {i18n.t("home.sectionFour.subtitle")}
          </p>
          <div className="d-none d-md-flex ">
            <Highlights
              classNameH="interna"
              firstLineH={i18n.t("home.sectionFour.titleFirstLineHighlight")}
              firstLine={i18n.t("home.sectionFour.titleFirstLine")}
              secondLineH={i18n.t("home.sectionFour.titleSecondLineHighlight")}
              secondLine={i18n.t("home.sectionFour.titleSecondLine")}
            />
          </div>
          <div className="d-flex d-md-none  mb-5">
            <Highlights
              classNameH="interna"
              firstLineH={i18n.t(
                "home.sectionFour.mobile.titleFirstLineHighlight"
              )}
              firstLine={i18n.t("home.sectionFour.mobile.titleFirstLine")}
              secondLineH={i18n.t(
                "home.sectionFour.mobile.titleSecondLineHighlight"
              )}
              secondLine={i18n.t("home.sectionFour.mobile.titleSecondLine")}
              thirthLineH={i18n.t(
                "home.sectionFour.mobile.titleThirdLineHightlight"
              )}
              thirthLine={i18n.t("home.sectionFour.mobile.titleThirdLine")}
            />
          </div>
          <div className="row">
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza fedea-alianza">
                <img src="/assets/logo-fedea.svg" alt="Fedea" />
                <Link to="/fedea">
                  <a className="btn-alianza-fedea row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img src="/assets/logo-basf.png" alt="Basf" />
                <Link to="/basf">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img src="/assets/logo-bayer.png" alt="Bayer" />
                <Link to="/bayer">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img src="/assets/logo-corteva.png" alt="Corteva" />
                <Link to="/corteva">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img src="/assets/logo-nova.png" alt="Nova" />
                <Link to="/nova">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img src="/assets/logo-summit.svg" alt="Summit" />
                <Link to="/summit">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img src="/assets/logo-syngenta.svg" alt="Syngenta" />
                <Link to="/syngenta">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="alianza">
                <img
                  src="/assets/stoller_home_logo.svg"
                  alt="Stoller"
                  className="vertical-img"
                />
                <Link to="/stoller">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <div className="alianza">
                <img
                  src="/assets/sipcam_home_logo.svg"
                  alt="Sipcam"
                  className="vertical-img"
                />
                <Link to="/sipcam">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <div className="alianza">
                <img src="/assets/prodeman_home_logo.svg" alt="Aviación" />
                <Link to="/aviacion">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <div className="alianza">
                <img
                  src="/assets/upl_home_logo.svg"
                  alt="UPL"
                  className="vertical-img"
                />
                <Link to="/upl">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <div className="alianza">
                <img src="/assets/fmc_home_logo.svg" alt="FMC" />
                <Link to="/fmc">
                  <a className="btn-alianza row">
                    <p>{i18n.t("home.sectionFour.href")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-next"
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeAlianzas;
