import React, { useState, useEffect } from "react";
import CropmixButton from "./CropmixButton";
import CheckIcon from "@material-ui/icons/Check";
import { Icon } from "@material-ui/core";
import "../scss/Aliados.scss";
import "../scss/Aviacion.scss";
import "../scss/Cropmix.scss";
import i18n from "../i18n/index.js";

function AviacionPageTwo({ handleFormSubmit, handleGoBack }) {

  useEffect(() => {
    window.scrollTo(0, 850);
  }, []);

  const [activeCheckboxes, setActiveCheboxes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [formData, setFormData] = useState({
    cuit: "",
    email: "",
    fullName: "",
    notes: "",
    phoneNumber: undefined,
    socialName: "",
    cropSelector: "1",
  });

  const handleCheckbox = (e) => {
    console.log(e.target.id);
    const checkbox = e.target.id;
    const updatedCheckbox = activeCheckboxes.map((box, index) => {
      if (index.toString() === checkbox) {
        return !box;
      }
      return box;
    });
    setActiveCheboxes(updatedCheckbox);
  };

  const handleOnChange = (target) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  return (
    <>
      <section className="container mapSection aviacionSecondPage mt-5 pt-5 pb-4">
        <div className="blue-calidad blue-calidad-pageTwo"></div>

        <div className="d-flex aviacionForm">
          <div className="col-md-6">
            <h3 className="pt-3 pb-5 text-left">
              {i18n.t("partners.aviacion.page02.sectionOne.title01")}
            </h3>
            <table className="table table-borderless aviacionTable">
              <thead>
                <tr>
                  <th scope="col">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.firstRow.item01"
                    )}
                  </th>
                  <th scope="col">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.firstRow.item02"
                    )}
                  </th>
                  <th scope="col">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.firstRow.item03"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="cropminxDataName">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.secondRow"
                    )}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[0]}`}
                    id="0"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[0] && <Icon component={CheckIcon}></Icon>}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[1]}`}
                    id="1"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[1] && <Icon component={CheckIcon}></Icon>}
                  </td>
                </tr>
                <tr>
                  <td className="cropminxDataName">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.thirdRow"
                    )}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[2]}`}
                    id="2"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[2] && <Icon component={CheckIcon}></Icon>}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[3]}`}
                    id="3"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[3] && <Icon component={CheckIcon}></Icon>}
                  </td>
                </tr>
                <tr>
                  <td className="cropminxDataName">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.fourthRow"
                    )}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[4]}`}
                    id="4"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[4] && <Icon component={CheckIcon}></Icon>}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[5]}`}
                    id="5"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[5] && <Icon component={CheckIcon}></Icon>}
                  </td>
                </tr>
                <tr>
                  <td className="cropminxDataName">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.table.fifthRow"
                    )}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[6]}`}
                    id="6"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[6] && <Icon component={CheckIcon}></Icon>}
                  </td>
                  <td
                    className={`cropmixData ${activeCheckboxes[7]}`}
                    id="7"
                    onClick={(e) => handleCheckbox(e)}
                  >
                    {activeCheckboxes[7] && <Icon component={CheckIcon}></Icon>}
                  </td>
                </tr>
                <tr>
                  <td className="cropminxDataName">
                    {i18n.t(
                      "partners.aviacion.page02.sectionOne.selector.title"
                    )}
                  </td>
                  <td
                    colSpan="2"
                    className={`cropmixData ${activeCheckboxes[6]}`}
                  >
                    <select
                      className="custom-select"
                      id="inputGroupSelect02"
                      style={{
                        background: `background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right 0.75rem center/8px 10px no-repeat`,
                      }}
                      name="cropSelector"
                      onChange={(e) => handleOnChange(e.target)}
                      defaultValue="1"
                    >
                      <option value="1">
                        {i18n.t(
                          "partners.aviacion.page02.sectionOne.selector.item01"
                        )}
                      </option>
                      <option value="2">
                        {i18n.t(
                          "partners.aviacion.page02.sectionOne.selector.item02"
                        )}
                      </option>
                      <option value="3">
                        {i18n.t(
                          "partners.aviacion.page02.sectionOne.selector.item03"
                        )}
                      </option>
                      <option value="4">
                        {i18n.t(
                          "partners.aviacion.page02.sectionOne.selector.item04"
                        )}
                      </option>
                      <option value="5">
                        {i18n.t(
                          "partners.aviacion.page02.sectionOne.selector.item05"
                        )}
                      </option>
                      <option value="6">
                        {i18n.t(
                          "partners.aviacion.page02.sectionOne.selector.item06"
                        )}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="p-md-4">
              <p className="text-left">
                {i18n.t(
                  "partners.aviacion.page02.sectionOne.clarifications.firstLine"
                )}
                <br />
                {i18n.t(
                  "partners.aviacion.page02.sectionOne.clarifications.secondLine"
                )}{" "}
                <i>
                  {" "}
                  {i18n.t(
                    "partners.aviacion.page02.sectionOne.clarifications.secondLineI"
                  )}
                </i>
                .
                <br />
                <i>
                  {" "}
                  {i18n.t(
                    "partners.aviacion.page02.sectionOne.clarifications.thirdLineI"
                  )}
                </i>{" "}
                {i18n.t(
                  "partners.aviacion.page02.sectionOne.clarifications.thirdLine"
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6 ">
            <h3 className="pt-3 pb-5 text-left">
              {i18n.t("partners.aviacion.page02.sectionTwo.title01")}
            </h3>
            <div className="aviacionDatosForm d-flex flex-column text-left">
              <label>
                {i18n.t("partners.aviacion.page02.sectionTwo.form.input01")}
              </label>
              <input
                className="aviacionDatoInput"
                type="number"
                placeholder="XX-XXXXXXXX-X"
                name="cuit"
                onChange={(e) => handleOnChange(e.target)}
                required
              />
              <label>
                {i18n.t("partners.aviacion.page02.sectionTwo.form.input02")}
              </label>
              <input
                className="aviacionDatoInput"
                name="socialName"
                onChange={(e) => handleOnChange(e.target)}
                required
              />
              <label>
                {i18n.t("partners.aviacion.page02.sectionTwo.form.input03")}
              </label>
              <input
                className="aviacionDatoInput"
                name="fullName"
                onChange={(e) => handleOnChange(e.target)}
                required
              />
              <label>
                {i18n.t("partners.aviacion.page02.sectionTwo.form.input04")}
              </label>
              <input
                className="aviacionDatoInput"
                name="email"
                onChange={(e) => handleOnChange(e.target)}
                required
              />
              <label>
                {i18n.t("partners.aviacion.page02.sectionTwo.form.input05")}
              </label>
              <input
                className="aviacionDatoInput"
                type="number"
                placeholder="+54 9"
                name="phoneNumber"
                onChange={(e) => handleOnChange(e.target)}
                required
              />
              <label>
                {i18n.t("partners.aviacion.page02.sectionTwo.form.input06")}
              </label>
              <textarea
                className="aviacionDatoInput"
                rows={4}
                type="textarea"
                placeholder={i18n.t(
                  "partners.aviacion.page02.sectionTwo.form.input06placeholder"
                )}
                name="notes"
                onChange={(e) => handleOnChange(e.target)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex buttonContainer mx-3">
          <CropmixButton
            label={i18n.t("cropmix.page02.backButton")}
            containerClassName="d-flex flex-fill mt-4 justify-content-start w-75"
            size="w-50 p-2"
            buttonAction={handleGoBack}
            buttonDirection="row-reverse"
          />
          <CropmixButton
            label={i18n.t("cropmix.page01.button")}
            containerClassName="d-flex flex-fill mt-4 justify-content-end w-75"
            size="w-50 p-2"
            buttonAction={(event) =>
              handleFormSubmit({ event, formData, activeCheckboxes })
            }
            disabled={
              !activeCheckboxes.includes(true) ||
              formData.email === "" ||
              formData.cuit === "" ||
              formData.fullName === "" ||
              formData.phoneNumber === "" ||
              formData.socialName === ""
            }
          />
        </div>
        <div className="col-md-12 pt-5 text-center pasos">
          <p>2 / 2 {i18n.t("partners.aviacion.page01.steps")}</p>
        </div>
      </section>
    </>
  );
}

export default AviacionPageTwo;
