import React from "react";
import i18n from "../i18n/index.js";
import "../scss/bootstrap.scss";
import "../scss/Footer.scss";

function Footer() {
  return (
    <div className="footer py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-start justify-content-md-center align-items-center prodeman-sello">
            <a href="http://prodeman.com/" target="_blank">
              {i18n.language === "es" ? (
                <img src="/assets/selloprodeman.png" className="sello" />
              ) : (
                <img src="/assets/selloprodeman-en.png" className="sello" />
              )}
            </a>
          </div>
          <div className="col-md-4">
            <h5 className="titleFooter">{i18n.t("footer.subtitle01")}</h5>
            <a href="mailto:contacto@maniseros.com">
              <p>{i18n.t("footer.desc01")}</p>
            </a>
          </div>
          <div className="col-md-4">
            <h5 className="titleFooter">{i18n.t("footer.subtitle02")}</h5>
            <p>{i18n.t("footer.desc02")}</p>
            <div className="row px-2">
              <a href="https://www.instagram.com/maniserosok/" target="_blank">
                <img
                  src="/assets/sm-insta.svg"
                  className="icon-sm"
                  alt="Insta"
                />
              </a>
              <a href="https://twitter.com/maniseros" target="_blank">
                <img
                  src="/assets/sm-twitter.svg"
                  className="icon-sm"
                  alt="Twitter"
                />
              </a>
              <a
                href="https://www.facebook.com/Maniseros-107641141478116"
                target="_blank"
              >
                <img
                  src="/assets/sm-face.svg"
                  className="icon-sm"
                  alt="Facebook"
                />
              </a>
              <a
                href="https://open.spotify.com/user/7dkgh65xfz81b8ohyp1kkx9ac?si=EWCZW2a6TcqdGTpmv1y0OA&nd=1"
                target="_blank"
              >
                <img
                  src="/assets/sm-spotify.svg"
                  className="icon-sm"
                  alt="Spotify"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
