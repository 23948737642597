import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
// import CarouselAliados from "./CarouselAliados";
import Footer from "./Footer";
import { Link } from "react-router-dom";
// import {
//   BrowserRouter as Router,
//   HashRouter,
//   Switch,
//   Route,
//   Link,
// } from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/Aliados.scss";

function Basf() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img
            className="banner-hero"
            src="/assets/hero-corteva.png"
            alt="hero"
          />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo-corteva.png"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.corteva.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t("partners.corteva.sectionOne.titleFirstLine")}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">
                  {i18n.t("partners.corteva.sectionTwo.title01")}
                  <br />
                  {i18n.t("partners.corteva.sectionTwo.title02")}
                </p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.corteva.sectionTwo.desc01")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.corteva.sectionTwo.desc02")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.corteva.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.corteva.sectionThree.box01.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa12=" "
                  imgpartner="corteva-01"
                  imgparter01="/assets/corteva-01.png"
                  href01="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/enlist-colex-d.html"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.corteva.sectionThree.box02.title"
                  )}
                  imgEtapa="../assets/planta0.jpg"
                  claseImgEtapa4=" "
                  imgparter01="/assets/corteva-02.png"
                  href01="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/authority.html"
                  imgpartner1="corteva-02"
                  imgparter02="/assets/corteva-03.png"
                  href02="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/panzer-gold.html"
                  imgpartner2="corteva-02"
                  imgparter03="/assets/corteva-04.png"
                  href03="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/produce.html"
                  imgpartner3="corteva-02"
                  imgparter04="/assets/corteva-05.png"
                  href04="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/spider.html"
                  imgpartner4="corteva-02"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa="V1 Vn"
                  imgEtapa="../assets/planta4.jpg"
                  claseImgEtapa12=" "
                  imgpartner="corteva-01"
                  imgparter01="/assets/corteva-06.png"
                  href01="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/galant-hl.html"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa="R1 - R6"
                  imgEtapa="../assets/planta9.jpg"
                  claseImgEtapa2=" "
                  imgpartner01="corteva-002"
                  imgpartner02="corteva-02"
                  imgparter01="/assets/corteva-07.png"
                  href01="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/galant-max.html"
                  imgparter02="/assets/corteva-08.png"
                  href02="https://www.corteva.com.ar/productos-y-soluciones/proteccion-de-cultivos/orlian.html"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Beneficios py-5">
          <img
            src="/assets/gold-beneficios.svg"
            alt="deco"
            className="gold-beneficios"
          />
          <div className="container">
            <h4 className="mb-4">
              {i18n.t("partners.corteva.sectionSix.title")}
            </h4>
            <div className="row">
              <div className="col-md-4">
                <a href="https://milote.com.ar/auth/log-in" target="_blank">
                  <div className="cuadro-container">
                    <div className="d-flex flex-column">
                      <img
                        src="/assets/milote.png"
                        alt="milote"
                        className="field"
                      />
                      <p>{i18n.t("partners.corteva.sectionSix.box01")}</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="https://milote.com.ar/corteva-flight/" target="_blank">
                  <div className="cuadro-container">
                    <div className="d-flex flex-column">
                      <img
                        src="/assets/corteva-flight.png"
                        alt="corteva-flight"
                        className="corteva-flight"
                      />
                      <p>{i18n.t("partners.corteva.sectionSix.box02")}</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <p className="dest mb-3">
                      {i18n.t("partners.corteva.sectionSix.title")}
                    </p>
                    <p>{i18n.t("partners.corteva.sectionSix.box03")}:</p>
                    <p className="dest mb-0">FEDEA & CERROS</p>
                    <img src="/assets/mix-cerros.png" className="mb-0" />
                    <p className="dest mb-0 mt-1">CERROS</p>
                    <img src="/assets/cerros.png" className="cerros mb-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Basf;
