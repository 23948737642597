import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
// import CarouselAliados from "./CarouselAliados";
import Footer from "./Footer";
import { Link } from "react-router-dom";
// import {
//   BrowserRouter as Router,
//   HashRouter,
//   Switch,
//   Route,
//   Link,
// } from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/Aliados.scss";

function Basf() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img
            className="banner-hero"
            src="/assets/herp-oroverde.png"
            alt="hero"
          />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo-oro-verde.png"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.oroverde.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t(
                  "partners.oroverde.sectionOne.titleFirstLine"
                )}
                secondLineH={i18n.t(
                  "partners.oroverde.sectionOne.titleSecondLineHighlight"
                )}
                secondLine={i18n.t(
                  "partners.oroverde.sectionOne.titleSecondLine"
                )}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">
                  {i18n.t("partners.oroverde.sectionTwo.title01")}
                </p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.oroverde.sectionTwo.desc01")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.oroverde.sectionTwo.desc02")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.oroverde.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box01.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box01.item01"
                  )}
                  infoEtapa2={i18n.t(
                    "partners.oroverde.sectionThree.box01.item02"
                  )}
                  infoEtapa3={i18n.t(
                    "partners.oroverde.sectionThree.box01.item03"
                  )}
                  infoEtapa4={i18n.t(
                    "partners.oroverde.sectionThree.box01.item04"
                  )}
                  infoEtapa5={i18n.t(
                    "partners.oroverde.sectionThree.box01.item05"
                  )}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box02.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box02.item01"
                  )}
                  infoEtapa2={i18n.t(
                    "partners.oroverde.sectionThree.box02.item02"
                  )}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box03.title"
                  )}
                  imgEtapa="../assets/planta4.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box03.item01"
                  )}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box04.title"
                  )}
                  imgEtapa="../assets/planta4.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box04.item01"
                  )}
                  infoEtapa2={i18n.t(
                    "partners.oroverde.sectionThree.box04.item02"
                  )}
                  infoEtapa3={i18n.t(
                    "partners.oroverde.sectionThree.box04.item03"
                  )}
                  infoEtapa4={i18n.t(
                    "partners.oroverde.sectionThree.box04.item04"
                  )}
                  infoEtapa5={i18n.t(
                    "partners.oroverde.sectionThree.box04.item05"
                  )}
                  infoEtapa6={i18n.t(
                    "partners.oroverde.sectionThree.box04.item06"
                  )}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box05.title"
                  )}
                  imgEtapa="../assets/planta6.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box05.item01"
                  )}
                  infoEtapa2={i18n.t(
                    "partners.oroverde.sectionThree.box05.item02"
                  )}
                  infoEtapa3={i18n.t(
                    "partners.oroverde.sectionThree.box05.item03"
                  )}
                  infoEtapa4={i18n.t(
                    "partners.oroverde.sectionThree.box05.item04"
                  )}
                  infoEtapa5={i18n.t(
                    "partners.oroverde.sectionThree.box05.item05"
                  )}
                  infoEtapa6={i18n.t(
                    "partners.oroverde.sectionThree.box05.item06"
                  )}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box06.title"
                  )}
                  imgEtapa="../assets/planta12.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box06.item01"
                  )}
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box07.title"
                  )}
                  imgEtapa="../assets/planta13.jpg"
                  infoEtapa={i18n.t(
                    "partners.oroverde.sectionThree.box07.item01"
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Basf;
