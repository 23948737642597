import React from "react";
import Carousel from "react-elastic-carousel";
import "../scss/CarouselAliados.scss";
import i18n from "../i18n/index.js";

function CarouselAliados() {
  return (
    <>
      <div className="">
        <Carousel
          breakPoints={[
            { width: 1, itemsToShow: 1 },
            {
              width: 400,
              itemsToShow: 3,
              itemsToScroll: 2,
              pagination: true,
            },
            { width: 850, itemsToShow: 3 },
          ]}
        >
          <div className="beneficio">
            <a href="https://www.multiplicabasf.com.ar/" target="_blank">
              <img src="/assets/multiplica.png" alt="multiplica" />
              <p>
                {i18n.t("partners.basf.sectionSix.box01")}
                <br />
                {i18n.t("partners.basf.sectionSix.box01reg")}
                <a
                  href="https://www.multiplicabasf.com.ar/registrate/"
                  target="_blank"
                >
                  {i18n.t("partners.basf.sectionSix.box01link")}
                </a>
                {i18n.t("partners.basf.sectionSix.box01final")}
              </p>
            </a>
          </div>
          <div className="beneficio">
            <a
              href="https://agriculture.basf.com/ar/es/sustentabilidad1/Academia-Agro.html"
              target="_blank"
            >
              <img src="/assets/academia-agro.svg" alt="multiplica" />
              <p className="mt-2">
                {i18n.t("partners.basf.sectionSix.box02")}
                <br />
                <a
                  href="https://agriculture.basf.com/ar/es/sustentabilidad/Academia-Agro.html"
                  target="_blank"
                >
                  {i18n.t("partners.basf.sectionSix.box02link")}
                </a>
              </p>
            </a>
          </div>
          <div className="beneficio">
            <a href="https://wa.me/5491134215552" target="_blank">
              <img
                src="/assets/whatsapp.png"
                alt="multiplica"
                // style={{ height: "56px" }}
              />
              <p>{i18n.t("partners.basf.sectionSix.box03")}</p>
              <p className="dest">+54 911 3421 5552</p>
            </a>
          </div>
          <div className="beneficio">
            <a href="http://www.xarvio.com/ar" target="_blank" className="">
              <img
                className="d-block mb-3 xarvio-img"
                src="/assets/xarvio.jpg"
                alt="xarvio"
              />
              <p className="mt-2">{i18n.t("partners.basf.sectionSix.box04")}</p>
            </a>
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default CarouselAliados;
