import React, { useEffect } from "react";
import CropmixButton from "./CropmixButton";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import * as L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "../scss/Aliados.scss";
import "../scss/Aviacion.scss";
import i18n from "../i18n/index.js";

import AutoComplete from "react-google-autocomplete";

function Aviacion({ changePage, setMapFile, mapFile }) {
  let mymap;
  useEffect(() => {
    mymap = L.map("map", {
      center: [-32.8, -63.863],
      zoom: 15,
      renderer: L.canvas(),
    });
    //L.control.scale().addTo(mymap);
    // L.tileLayer(
    //   // "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    //   "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //   {
    //     attribution:
    //       'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //     maxZoom: 18,
    //     id: "mapbox/streets-v11",
    //     tileSize: 512,
    //     zoomOffset: -1,
    //     accessToken:
    //       "pk.eyJ1IjoibmFtb3Jhc2QiLCJhIjoiY2tzNnNkZjlkMDBueTJ1bjB5N2l1OWc5aSJ9.ZGT9CXez1xS8xOB3RfK_Ng",
    //   }
    // ).addTo(mymap);

    //Fuente: https://stackoverflow.com/questions/9394190/leaflet-map-api-with-google-satellite-layer
    L.tileLayer("http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}", {
      maxZoom: 20,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    }).addTo(mymap);
    mymap.pm.addControls({
      position: "topleft",
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawPolygon: true,
      drawCircle: true,
      editMode: true,
      dragMode: false,
      cutPolygon: true,
      removalMode: true,
      rotateMode: false,
    });
    mymap.pm.setLang(i18n.language);
    mymap.locate();
    mymap.on("locationfound", (event) => {
      mymap.panTo(event.latlng);
    });

    return () => mymap.remove();
  });

  //Obtained @ https://stackoverflow.com/questions/62887120/leafletjs-geoman-into-json-data
  // function generateGeoJson() {
  //   var fg = L.featureGroup();
  //   var layers = findLayers(mymap);
  //   layers.forEach(function (layer) {
  //     fg.addLayer(layer);
  //   });
  //   console.log(fg.toGeoJSON());
  // }

  // function findLayers(map) {
  //   var layers = [];
  //   map.eachLayer((layer) => {
  //     if (
  //       layer instanceof L.Polyline ||
  //       layer instanceof L.Marker ||
  //       layer instanceof L.Circle ||
  //       layer instanceof L.CircleMarker
  //     ) {
  //       layers.push(layer);
  //     }
  //   });

  //   // filter out layers that don't have the leaflet-geoman instance
  //   layers = layers.filter((layer) => !!layer.pm);
  //   // filter out everything that's leaflet-geoman specific temporary stuff
  //   layers = layers.filter((layer) => !layer._pmTempLayer);

  //   return layers;
  // }

  const handleChangeFile = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    setMapFile(e.target.files[0]);
    changePage();
  };

  const createMapFile = (e) => {
    e.preventDefault();
    if (!e.detail) return;

    let fg = L.featureGroup();
    const layer = mymap.pm.getGeomanDrawLayers(true);
    fg.addLayer(layer);

    if (!fg.toGeoJSON().features.length) {
      alert(i18n.t("partners.aviacion.page01.drawMessage"));
      return;
    }

    const jsonFile = JSON.stringify(fg.toGeoJSON());

    const file = new File([jsonFile], "Mapa.geojson", {
      type: "application/json",
    });
    setMapFile(file);
    changePage();
  };

  const handleSelectPlace = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    mymap.panTo({ lat, lng });
  };

  return (
    <>
      <section className="container mapSection mt-5 pt-5">
        <div className="blue-calidad"></div>
        <h3 className="pt-3 pb-5" id="title01">
          {i18n.t("partners.aviacion.page01.sectionFour.title01")}
        </h3>
        <div className="mapContainer">
          <AutoComplete
            apiKey={"AIzaSyBWm7081D7bnHPcIp-UZzXAsJizYPUkdH4"}
            onPlaceSelected={handleSelectPlace}
            options={{
              types: ["(regions)"],
              componentRestrictions: { country: "arg" },
            }}
            placeholder={i18n.t(
              "partners.aviacion.page01.sectionFour.searchPlaceholder"
            )}
            style={{ width: "100%" }}
          />
          <div className="map " id="map"></div>
        </div>
        <div className="col-md-12 text-left mt-5">
          <h4 className="pt-3">
            {i18n.t("partners.aviacion.page01.sectionFour.fileTypes")}
          </h4>
        </div>
        <div className="col-md-12 pt-4 text-left">
          <p className="p-0 m-0">
            {i18n.t("partners.aviacion.page01.sectionFour.attachedFile")}
          </p>
        </div>
        <div className="col-md-12 text-left">
          <span className="d-flex buttonContainer">
            <div className="input-file-container w-50 d-flex flex-column">
              <label
                htmlFor="fileInput"
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  background: "#0052B5",
                  color: "aliceblue",
                }}
                className="w-75 input-file"
              >
                {i18n.t("partners.aviacion.page01.sectionFour.button")}
              </label>
              {i18n.t("partners.aviacion.page01.sectionFour.fileSelected")}
              <input
                type="file"
                accept=".kmz, .kml, .geojson, .txt, .zip, .rar, .7zip"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleChangeFile}
              />
            </div>
            <CropmixButton
              label={i18n.t("cropmix.page01.button")}
              containerClassName="d-flex flex-fill justify-content-end w-75"
              size="w-50 p-2"
              buttonAction={(e) => createMapFile(e)}
            />
          </span>
        </div>
        <div className="col-md-12 pt-4 text-center">
          <p>1 / 2 {i18n.t("partners.aviacion.page01.steps")}</p>
        </div>

        <div className="gold-calidad-01"></div>
      </section>
    </>
  );
}

export default Aviacion;
