import React from 'react';
import handleViewport from 'react-in-viewport';
import '../scss/bootstrap.scss';
import '../scss/Highlights.scss';



// $(".box").inViewport(function(px){
//   if(px) $(this).addClass("triggeredCSS3") ;
// });


// const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
// const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

// const animation =document.getElementById(animation)

// const chargeOnView = 

// if animation {
//   element.classList.add("hl1");
// }








  // // get the element to animate
  // let element = document.getElementById('animation');
  // let elementHeight = element.clientHeight;
  
  // // listen for scroll event and call animate function
  // document.addEventListener('scroll', animate);
  
  // // check if element is in view
  // function inView() {
  //   // get window height
  //   var windowHeight = window.innerHeight;
  //   // get number of pixels that the document is scrolled
  //   var scrollY = window.scrollY || window.pageYOffset;
    
  //   // get current scroll position (distance from the top of the page to the bottom of the current viewport)
  //   var scrollPosition = scrollY + windowHeight;
  //   // get element position (distance from the top of the page to the bottom of the element)
  //   var elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight;
    
  //   // is scroll position greater than element position? (is element in view?)
  //   if (scrollPosition > elementPosition) {
  //     return true;
  //   }
    
  //   return false;
  // }
  
  // // animate element when it is in view
  // function animate() {
  //   // is element in view?
  //   if (inView()) {
  //       // element is in view, add class to element
  //       element.classList.add('animate');
  //   }
  // }


function Highlights(props) {
    const ref = props.forwardedRef
    return (
      <div className="Highlights" ref={ref}>
        <div className={props.classNameH}>
          <h2>
            {props.firstLineH && <span id="animation" className={props.inViewport ? 'hl1' : ''}>{props.firstLineH}</span>}
            <span>{props.firstLine}</span>
            {props.firstLineH2 && <span id="animation" className={props.inViewport ? 'hl1' : ''}>{props.firstLineH2}</span>}
            </h2>
          <h2>
            {props.secondLineH && <span id="animation" className={props.inViewport ? 'hl1' : ''}>{props.secondLineH}</span>}
            <span>{props.secondLine}</span>
            {props.secondLineH2 && <span id="animation" className={props.inViewport ? 'hl1' : ''}>{props.secondLineH2}</span>}
            {props.secondLineW && <span> {props.secondLineW} </span>}
          </h2>
          { props.thirthLineH && <h2><span id="animation" className={props.inViewport ? 'hl1' : ''}>{props.thirthLineH}</span><span>{props.thirthLine}</span></h2>}
          { props.forthLineH && <h2><span id="animation" className={props.inViewport ? 'hl1' : ''}>{props.forthLineH}</span><span>{props.forthLine}</span></h2>}
        </div>
      </div>
    );
  }
  
  export default handleViewport(Highlights);
  