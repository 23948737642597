import React from "react";
import Highlights from "./Highlights";
import DropdownProducto from "./DropdownProducto";
import DropdownProductoDos from "./DropdownProductoDos";
import Grafico from "./Grafico";
import WhatsApp from "./WhatsApp";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/bootstrap.scss";
import "../scss/Producto.scss";

function Producto() {
  return (
    <>
      <WhatsApp />
      <div className="Producto">
        <div className="space"></div>
        <section className="producto-intro">
          <div className="gold-producto-01"></div>
          <div className="blue-producto-01"></div>
          <img
            src="/assets/deco-grey.svg"
            alt="deco"
            className="grey-producto"
          />
          <div className="container">
            <p className="nameSection">
              {i18n.t("product.sectionOne.subtitle")}
            </p>
            <div className="row align-items-center">
              <div className="col-md-6 info-producto mb-5">
                <Highlights
                  classNameH="interna"
                  firstLineH={i18n.t(
                    "product.sectionOne.titleFirstLineHighlight"
                  )}
                  firstLine={i18n.t("product.sectionOne.titleFirstLine")}
                  secondLineH={i18n.t(
                    "product.sectionOne.titleSecondLineHighlight"
                  )}
                  secondLine={i18n.t("product.sectionOne.titleSecondLine")}
                  thirthLineH={i18n.t(
                    "product.sectionOne.titleThirdLineHightlight"
                  )}
                  thirthLine={i18n.t("product.sectionOne.titleThirdLine")}
                />
                <p>{i18n.t("product.sectionOne.desc01")}</p>
              </div>
              <div className="col-md-6 ">
                <div className="numeros-producto">
                  <p className="title-numeros mt-3">
                    {i18n.t("product.sectionOne.title01")}
                  </p>
                  <ul>
                    <li>{i18n.t("product.sectionOne.number01")}</li>
                    <li>{i18n.t("product.sectionOne.number02")}</li>
                    <li>{i18n.t("product.sectionOne.number03")}</li>
                  </ul>
                  <p>{i18n.t("product.sectionOne.desc02")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="destacados py-5">
          <img
            src="/assets/gold-wave.svg"
            alt="deco"
            className="wave-destacado"
          />
          <div className="container">
            <p className="title-destacado d-none d-md-flex">
              {i18n.t("product.sectionTwo.title")}
            </p>
            <p className="title-destacado d-md-none">
              {i18n.t("product.sectionTwo.title")}
            </p>
            <div className="row">
              <div className="col-md-6">
                <div className="elaborado">
                  <p>
                    {i18n.t("product.sectionTwo.box01")}
                    <span>{i18n.t("product.sectionTwo.little01")}</span>
                  </p>
                </div>
                <div className="elaborado">
                  <p>
                    {i18n.t("product.sectionTwo.box03")}
                    <span>{i18n.t("product.sectionTwo.little02")}</span>
                  </p>
                </div>
                <div className="elaborado">
                  <p>{i18n.t("product.sectionTwo.box05")}</p>
                </div>
                <div className="elaborado">
                  <p>{i18n.t("product.sectionTwo.box07")}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="elaborado">
                  <p>{i18n.t("product.sectionTwo.box02")}</p>
                </div>
                <div className="elaborado">
                  <p>{i18n.t("product.sectionTwo.box04")}</p>
                </div>
                <div className="elaborado">
                  <p>{i18n.t("product.sectionTwo.box06")}</p>
                </div>
                {i18n.language === "es" ? (
                  <img
                    src="/assets/sello-mapa.svg"
                    alt="sello"
                    className="sello-mapa"
                  />
                ) : (
                  <img
                    src="/assets/sello-mapa-en.png"
                    alt="sello"
                    className="sello-mapa"
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="calidad py-5">
          <div className="blue-calidad"></div>
          <div className="gold-calidad-01"></div>
          <div className="gold-calidad-02"></div>
          <img
            src="/assets/gold-wave.svg"
            alt="deco"
            className="wave-calidad"
          />
          <div className="container">
            <h3 className="title-calidad">
              {i18n.t("product.sectionThree.subtitle")}
            </h3>
            <DropdownProducto
              titleDropProduct={i18n.t("product.sectionThree.title01")}
              dataTarget="product1"
              infoInterior={i18n.t("product.sectionThree.desc0101")}
              bajada={i18n.t("product.sectionThree.subtitle01")}
              textOne={i18n.t("product.sectionThree.desc0101")}
              textTwo={i18n.t("product.sectionThree.desc0102")}
            />
            <DropdownProductoDos
              titleDropProduct={i18n.t("product.sectionThree.title02")}
              dataTarget="product2"
              infoInterior=""
              bajada={i18n.t("product.sectionThree.desc0201")}
              textOne={i18n.t("product.sectionThree.desc0202")}
              titleOne={i18n.t("product.sectionThree.subtitle02")}
              subtitleOne={i18n.t("product.sectionThree.desc03")}
            />
          </div>
        </section>
        <section className="historia">
          <div className="white-historia"></div>
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-historia"
          />
          <div className="d-none d-md-block white-desktop"></div>
          <div className="bg-grey-historia"></div>
          <div className="container pt-5">
            <div className="d-none d-md-block pt-5">
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "product.sectionFour.titleFirstLineHighlight"
                )}
                firstLine={i18n.t("product.sectionFour.titleFirstLine")}
                secondLineH={i18n.t(
                  "product.sectionFour.titleSecondLineHighlight"
                )}
                secondLine={i18n.t("product.sectionFour.titleSecondLine")}
              />
              <img
                src="/assets/sello-arg.svg"
                alt="arg"
                className="sello-arg-historia"
              />
            </div>
            <div className="d-md-none mb-5 pb-5">
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "product.sectionFour.titleFirstLineHighlightMobile"
                )}
                firstLine={i18n.t("product.sectionFour.titleFirstLineMobile")}
                secondLineH={i18n.t(
                  "product.sectionFour.titleSecondLineHighlightMobile"
                )}
                secondLine={i18n.t("product.sectionFour.titleSecondLineMobile")}
                thirthLineH={i18n.t(
                  "product.sectionFour.titleThirthLineHighlightMobile"
                )}
                thirthLine={i18n.t("product.sectionFour.titleThirthLineMobile")}
                forthLineH={i18n.t(
                  "product.sectionFour.titleForthLineHighlightMobile"
                )}
                forthLine={i18n.t("product.sectionFour.titleForthLineMobile")}
              />
              <img
                src="/assets/sello-arg.svg"
                alt="arg"
                className="sello-arg-historia"
              />
            </div>
            <p className="title-rinde mt-5">
              {i18n.t("product.sectionFour.subtitle")}
            </p>
            <div className="row">
              <div className="col-md-6">
                <p>{i18n.t("product.sectionFour.desc01")}</p>
              </div>
              <div className="col-md-6">
                <p>{i18n.t("product.sectionFour.desc02")}</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <Grafico />
          </div>
        </section>
        <section className="ctas py-5 mt-5">
          <div className="gold-ctas"></div>
          <div className="blue-ctas"></div>
          <img
            src="/assets/lineas_azules.svg"
            alt="lineas"
            className="lineas-azules-ctas"
          />
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-2">
                <div className="cta-producto">
                  <p className="title-cta">
                    {i18n.t("product.sectionFive.title01")}
                  </p>
                  <p>{i18n.t("product.sectionFive.desc01")}</p>
                  <Link to="/programas">
                    <p className="button mb-0 row justify-content-between">
                      {i18n.t("product.sectionFive.boton01")}
                      <img src="/assets/arrow-next.svg" alt="arrow" />
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="cta-producto">
                  <p className="title-cta">
                    {i18n.t("product.sectionFive.title02")}
                  </p>
                  <p>{i18n.t("product.sectionFive.desc02")}</p>
                  <Link to="/contacto">
                    <p className="button mb-0 row justify-content-between">
                      {i18n.t("product.sectionFive.boton02")}
                      <img src="/assets/arrow-next.svg" alt="arrow" />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Producto;
