import React from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { formState } from "../atom";
import i18n from "../i18n/index.js";
import "../scss/ContactoProgramas.scss";

function ContactoProgramas() {
  const [form, setForm] = useRecoilState(formState);
  const onChange = (event) =>
    setForm({ ...form, [event.target.name]: event.target.value });

  const { name, email, surname } = form;

  return (
    <>
      <section className="ContactoProgramas">
        <div className="gold-rectangle-01"></div>
        <div className="gold-rectangle-02"></div>
        <img
          src="/assets/lineas_azules.svg"
          alt="lineas"
          className="lineas-azules"
        />
        <div className="container d-flex flex-column align-items-center">
          <Link to="/contacto" className="d-none d-md-block">
            <a href="#" className="botonPlan d-none d-md-block">
              <div className="row d-flex align-items-center justify-content-center">
                <p>{i18n.t("programs.sectionSeven.boton")}</p>
                <img
                  src="/assets/arrow-next.svg"
                  alt="arrow"
                  className="arrow-nextprogram"
                />
              </div>
            </a>
          </Link>
          <Link to="/contacto" className="botonPlan d-md-none">
            <div className="row d-flex flex-column align-items-center justify-content-center">
              <p>
                {i18n.t("programs.sectionSeven.boton")}
                <br />
              </p>

              <img
                src="/assets/arrow-next.svg"
                alt="arrow"
                className="arrow-nextprogram"
              />
            </div>
          </Link>
          <div className="form-enviar">
            <div className="gold-rectangle-03"></div>
            <p className="epigrafe">{i18n.t("programs.contacto.subtitle")}</p>
            <h4>{i18n.t("programs.contacto.title")}</h4>
            <form>
              <div className="row m-0 justify-content-between">
                <div className="inputlabel d-flex flex-column">
                  <label>{i18n.t("programs.contacto.campo01")}</label>
                  <input
                    name="name"
                    value={name}
                    onChange={onChange}
                    className="textInput"
                  ></input>
                </div>
                <div className="inputlabel d-flex flex-column">
                  <label>{i18n.t("programs.contacto.campo02")}</label>
                  <input
                    name="surname"
                    value={surname}
                    onChange={onChange}
                    className="textInput"
                  ></input>
                </div>
                <div className="inputlabel d-flex flex-column">
                  <label>{i18n.t("programs.contacto.campo03")}</label>
                  <input
                    name="email"
                    value={email}
                    onChange={onChange}
                    className="textInput"
                  ></input>
                </div>
              </div>
              <div
                className="col-10 d-flex justify-content-end"
                id="btn-contacto-vm"
              >
                <Link to="/contacto" className="submitBtn mt-4">
                  <div className="row justify-content-between px-3">
                    <p className="pr-5">{i18n.t("programs.contacto.send")}</p>
                    <img src="/assets/arrow-next.svg" alt="arrow" />
                  </div>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactoProgramas;
