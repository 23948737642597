import React from 'react';
import DropdownContacto from './DropdownContacto';
import Highlights from './Highlights';
import i18n from '../i18n/index.js'
import Footer from './Footer';
import '../scss/bootstrap.scss';
import '../scss/Contacto.scss';

import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import { useRecoilState } from 'recoil';
import { formState } from '../atom'

function Contacto() {
    // const [name, setName] = useRecoilState(nameState)
    // const [email, setEmail] = useRecoilState(emailState)
    
    // const handleInputName = event => setName(event.target.value)
    // const handleInputEmail = event => setEmail(event.target.value)

    ///////////////////////////

    const [form, setForm] = useRecoilState(formState)
    const onChange = event => setForm({...form, [event.target.name]: event.target.value})

    const {name, surname, email, phone} = form

    return (
    <>
      <div className="Contacto">
      <div className="space"></div>
      <div className="container">
            <p className="nameSection">{i18n.t('contact.section')}</p>
      </div>
          <img src="/assets/deco-gold.svg" alt="deco" className="deco-gold-contacto" />
          <div className="gold-contact"></div>
          <div className="container">
            <div className="d-none d-md-flex">
                <Highlights classNameH="interna" 
                firstLineH={i18n.t('contact.titleFirstLineHighlight')} firstLine={i18n.t('contact.titleFirstLine')} 
                secondLineH={i18n.t('contact.titleSecondLineHighlight')} secondLine={i18n.t('contact.titleSecondLine')}/>
            </div>
            <div className="d-md-none">
                <Highlights classNameH="interna" 
                firstLineH="El mejor man" firstLine="í del" 
                secondLineH="mund" secondLine="o se cultiva" 
                thirthLineH="en nuestra tierr" thirthLine="a."/>
            </div>
            <div className="Form">
                <div className="col-12">
                    <div className="row formClassic">
                        <div className="col-md-3 pl-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('contact.formulario.box01')}</label>
                            <input className="textInput" name='name' value={name} onChange={onChange} required></input>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0 pl-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('contact.formulario.box02')}</label>
                            <input className="textInput" name='surname' value={surname} onChange={onChange} required></input>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0 pl-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('contact.formulario.box03')}</label>
                            <input className="textInput" name='email' value={email} onChange={onChange} required></input>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0 pl-md-1 pl-md-auto pr-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('contact.formulario.box04')}</label>
                            <input name='phone' value={phone} onChange={onChange} className="textInput" required></input>
                        </div>
                    </div>
                    <div className="row d-md-flex justify-content-between drops">
                        <DropdownContacto inversiones=""/>
                    </div>
                </div>
            </div>
          </div>
      </div>
      <Footer/>
    </>
    );
  }
  
  export default Contacto;
  