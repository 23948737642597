import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import i18n from "../i18n/index.js";
import "../scss/Aliados.scss";

function Upl() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img className="banner-hero" src="/assets/hero-upl.png" alt="hero" />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo_UPL.svg"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.upl.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t("partners.upl.sectionOne.titleFirstLine")}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">
                  {i18n.t("partners.upl.sectionTwo.title01")}
                </p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.upl.sectionTwo.desc01")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.upl.sectionTwo.desc02")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.upl.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box01.title")}
                  imgEtapa="../assets/planta00.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/tornado",
                      partnerImg: "/assets/upl_tornado.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box02.title")}
                  imgEtapa="../assets/planta0.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/satellite",
                      partnerImg: "/assets/upl_satellite.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/up-stage",
                      partnerImg: "/assets/upl_up-stage.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/strim-upl",
                      partnerImg: "/assets/upl_strim-upl.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box03.title")}
                  imgEtapa="../assets/planta0.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/rancona-dimension",
                      partnerImg: "/assets/upl_rancona-dimension.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/vitavax-flo-ts",
                      partnerImg: "/assets/upl_vitavax-flo-ts.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/humiplex-50g",
                      partnerImg: "/assets/upl_humiplex.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/rancona-trio",
                      partnerImg: "/assets/upl_rancona-trio.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/biozyme-tf",
                      partnerImg: "/assets/upl_biozyme.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box04.title")}
                  imgEtapa="../assets/planta4.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/select-1",
                      partnerImg: "/assets/upl_select.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/blazer",
                      partnerImg: "/assets/upl_blazer.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/select-36-ec",
                      partnerImg: "/assets/upl_select-36.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/strim-upl",
                      partnerImg: "/assets/upl_strim-upl.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/celebrate",
                      partnerImg: "/assets/upl_celebrate.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box05.title")}
                  imgEtapa="../assets/planta6.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/biozyme-tf",
                      partnerImg: "/assets/upl_biozyme.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/manzate-prostick",
                      partnerImg: "/assets/upl_manzate.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/biotron-plus",
                      partnerImg: "/assets/upl_biotron-plus.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/emerge",
                      partnerImg: "/assets/upl_emerge.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box06.title")}
                  imgEtapa="../assets/planta6.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/biotron-plus",
                      partnerImg: "/assets/upl_biotron-plus.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/manzate-prostick",
                      partnerImg: "/assets/upl_manzate.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/emerge",
                      partnerImg: "/assets/upl_emerge.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box07.title")}
                  imgEtapa="../assets/planta6.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/biotron-plus",
                      partnerImg: "/assets/upl_biotron-plus.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/manzate-prostick",
                      partnerImg: "/assets/upl_manzate.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/k-fol",
                      partnerImg: "/assets/upl_kfol.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/emerge",
                      partnerImg: "/assets/upl_emerge.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t("partners.upl.sectionThree.box08.title")}
                  imgEtapa="../assets/planta00.jpg"
                  heap={[
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/defentox-d",
                      partnerImg: "/assets/upl_defentox.png",
                    },
                    {
                      href: "https://www.upl-ltd.com/ar/Detalles-del-producto/quickphos",
                      partnerImg: "/assets/upl_quickphos.png",
                    },
                  ]}
                  imgClass="heap-product"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Beneficios py-5">
          <img
            src="/assets/gold-beneficios.svg"
            alt="deco"
            className="gold-beneficios"
          />
          <div className="container">
            <h4 className="mb-4">{i18n.t("partners.upl.sectionFour.title")}</h4>
            <div className="row">
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <img src="/assets/logo-pronutiva.png" alt="multiplica" />
                    <p>{i18n.t("partners.upl.sectionFour.box01.content")}</p>
                    <a
                      className="dest vervideo"
                      href={i18n.t("partners.upl.sectionFour.box01.cta")}
                    >
                      {i18n.t("partners.upl.sectionFour.box01.cta")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <h4>{i18n.t("partners.upl.sectionFour.box02.content")}</h4>
                    <a
                      className="dest vervideo"
                      href="https://youtu.be/fzkkSu1IRUE"
                    >
                      {i18n.t("partners.upl.sectionFour.box02.cta")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <a
                  href="https://wa.me/5491168202519"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="cuadro-container">
                    <div className="d-flex flex-column">
                      <img src="/assets/whatsapp.png" alt="multiplica" />
                      <p>{i18n.t("partners.upl.sectionFour.box03.content")}</p>
                      <p className="dest">
                        {i18n.t("partners.upl.sectionFour.box03.cta")}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Upl;
