import React, { useState } from "react";

export const Bars = ({ widths, labels, colors, labelColor }) => {
  const [screenWidth] = useState(window.innerWidth);

  const createBar = () => {
    return labels.map((label, index) => {
      const width = Math.round(widths[index] * 100); //Widths in %
      const fontSize =
        width <= 15
          ? "1.2rem"
          : width <= 25
          ? "1.7rem"
          : width <= 30
          ? "2rem"
          : "2.7rem"; //Percentage numbers size
      const writingMode = width >= 15 ? "horizontal-tb" : "vertical-lr";
      const smallWidthFontSize =
        width <= 15
          ? "0.75rem"
          : width <= 25
          ? "1.2rem"
          : width <= 30
          ? "1.7rem"
          : "2.7rem"; //Percentage numbers size
      const smallWidthWritingMode =
        width >= 20 ? "horizontal-tb" : "vertical-lr";
      return (
        <div
          className={`${label} pt-5 pb-2 pl-2 pr-2`}
          key={label+index}
          style={{
            height: "140px",
            width: `${width}%`,
            display: width === 0 ? "none" : "inline-block",
            backgroundColor: colors[index],
            color: labelColor[index],
            verticalAlign: 'bottom'
          }}
        >
          <p
            style={{
              fontSize: width <= 25 ? "0.75rem" : "0.95rem",
              writingMode:
                screenWidth > 375 ? writingMode : smallWidthWritingMode,
            }}
          >
            {label}
          </p>
          <strong
            style={{
              fontSize: screenWidth > 375 ? fontSize : smallWidthFontSize,
              writingMode: screenWidth > 375 ? writingMode : smallWidthWritingMode,
              marginTop: width <= 25 ? '10px' : 0,
              marginLeft: width <= 25 ? '-6px' : 0,
            }}
          >
            {Math.round(widths[index] * 100)}%
          </strong>
        </div>
      );
    });
  };

  return <span className="soja-maiz  text-left">{createBar()}</span>;
};
