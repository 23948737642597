import React, { useState } from "react";
import Highlights from "./Highlights";
import ContactoProgramas from "./ContactoProgramas";
import Footer from "./Footer";
import "../scss/bootstrap.scss";
import "../scss/Blog.scss";
import {
  EmailShareButton,
  // FacebookShareButton,
  LinkedinShareButton,
  // TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  // FacebookIcon,
  LinkedinIcon,
  // TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { blogContent } from "../content/BlogsContent";

export default function Blog({ postNumber }) {
  const [screenWidth] = useState(window.innerWidth);

  //Change this to see other notes
  const selectedNote = postNumber;
  const note = blogContent[selectedNote];

  const totalTitleWords = Math.round(note.title.split(" ").length);
  const upContent = note.title
    .split(" ")
    .slice(0, totalTitleWords / 2 + 1)
    .join(" ");
  const downContent = note.title
    .split(" ")
    .slice(totalTitleWords / 2 + 1, totalTitleWords + 1)
    .join(" ");

  /*
  const totalContentWords = Math.round(note.content.split(" ").length);
  const leftContent = note.content
    .split(" ")
    .slice(0, totalContentWords / 2 + 1)
    .join(" ");
  const rightContent = note.content
    .split(" ")
    .slice(totalContentWords / 2 + 1, totalContentWords + 1)
    .join(" ");
  */

  return (
    <section className="Blog">
      <div className="space"></div>
      <div className="introBackground">
        <img
          src="/assets/deco-gold.svg"
          alt="deco"
          className="deco-historia"
          style={{ width: "100%" }}
        />
      </div>
      <div className="blogBackground"></div>
      <img src="/assets/sello-arg.svg" alt="deco" className="selloArg" />
      <div className="container blogContainer">
        <section className="introBlog">
          <div className="">
            <Highlights
              classNameH="interna"
              firstLineH={upContent}
              secondLineH={downContent}
            />
          </div>
        </section>

        <section className="py-5">
          <div className="subtitle w-50 text-left ">
            <strong>
              <i>{note.subtitle && note.subtitle}</i>
            </strong>
            <br />
            <small className="mt-2">Por {note.author}</small>
          </div>
          <div className="content d-flex py-5">
            {screenWidth > 768 ? (
              <>
                {note.content.leftContent}
                {note.content.rightContent}
              </>
            ) : (
              <>
                {note.content.leftContent}
                {note.content.rightContent}
              </>
            )}
          </div>

          {note.embed !== "" && (
            <div className="graph ">
              <div className="mb-1" style={{ height: "90%" }}>
                {note.embed}
              </div>
              <div className="compartir d-flex align-items-center">
                <div className="btn-group dropright">
                  <h4
                    type="button"
                    className=" m-0 p-0 dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/share-icon.svg"
                      className="icon-sm"
                      alt="Share Icon"
                      style={{
                        height: "30px",
                        width: "30px",
                        marginRight: "4px",
                      }}
                    />
                    Compartir
                  </h4>
                  <div className="dropdown-menu shareDropdown">
                    {/* <a
                      href="https://twitter.com/maniseros"
                      target="_blank"
                      rel="noreferrer"
                      style={{ height: "28px", width: "28px" }}
                    >
                      <img
                        src="/assets/sm_twitter.png"
                        className="icon-sm"
                        alt="Twitter"
                        style={{ height: "28px", width: "28px" }}
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/Maniseros-107641141478116"
                      target="_blank"
                      rel="noreferrer"
                      style={{ height: "30px", width: "30px" }}
                    >
                      <img
                        src="/assets/sm_face.png"
                        className="icon-sm"
                        alt="Facebook"
                      />
                    </a> */}
                    <EmailShareButton
                      style={{ margin: "0 4px" }}
                      url={window.location.href}
                      subject={`Mirá esta nota ${note.title} en maniseros.com`}
                      body={note.subtitle}
                    >
                      <EmailIcon round size={32} />
                    </EmailShareButton>
                    {/* <FacebookShareButton
                      style={{ margin: "0 4px" }}
                      url={window.location.href}
                      quote="Mirá esta nota de Maniseros: "
                      hashtag="#maniseros"
                    >
                      <FacebookIcon round size={32} />
                    </FacebookShareButton> */}
                    <LinkedinShareButton
                      style={{ margin: "0 4px" }}
                      url={window.location.href}
                      title={`Mirá esta nota ${note.title} en maniseros.com`}
                      summary={note.subtitle}
                      source="Maniseros"
                    >
                      <LinkedinIcon round size={32} />
                    </LinkedinShareButton>
                    {/* <TelegramShareButton
                      style={{ margin: "0 4px" }}
                      url={window.location.href}
                      title={note.title}
                    >
                      <TelegramIcon round size={32} />
                    </TelegramShareButton> */}
                    <TwitterShareButton
                      style={{ margin: "0 4px" }}
                      url={window.location.href}
                      title={`Mirá esta nota ${note.title} en maniseros.com`}
                      hashtags={["maniseros", "campo", "cultivamospasión"]}
                    >
                      <TwitterIcon round size={32} />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      style={{ margin: "0 4px" }}
                      url={window.location.href}
                      title={`Mirá esta nota ${note.title} en maniseros.com`}
                    >
                      <WhatsappIcon round size={32} />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <ContactoProgramas />
      <Footer />
    </section>
  );
}
