export const eng = {
  menu: {
    item1: "LifeCycle",
    item2: "Programs",
    item3: "Product",
    item4: "Partners",
    item5: "Cropmix",
    item6: "Blog",
    item7: "Contact",
  },
  formSimple: {
    field1: "Name",
    field2: "Surname",
    field3: "Email",
    field4: "Phone",
    send: "Send",
    title: "Reason for consultation",
    box01: "PROGRAMS",
    box02: "INVESTMENTS",
    box03: "ABOUT THE PEANUT",
    box04: "ABOUT MANISEROS",
  },
  footer: {
    subtitle01: "Contact",
    desc01: "contacto@maniseros.com",
    subtitle02: "Networks",
    desc02: "Follow us!",
  },
  // ------------------- HOME -------------------
  home: {
    Banner: {
      titleFirstLineHighlight: "PEANU",
      titleFirstLine: "T:",
      titleSecondLineHighlight: "The farm e",
      titleSecondLine: "volution",
    },
    sectionOne: {
      subtitle: "LIFE CYCLE",
      titleFirstLineHighlight: "PEANUT. SOI",
      titleFirstLine: "L AND WATER,",
      titleSecondLineHighlight: "the perfect balan",
      titleSecondLine: "ce to",
      titleThirdLineHightlight: "archieve a sus",
      titleThirdLine: "tainable mix.",
    },
    sectionTwo: {
      subtitle: "MANISEROS PROGRAM",
      titleFirstLineHighlight: "AN IMPROVED P",
      titleFirstLine: "ROPOSAL.",
      titleSecondLineHighlight: "Two plans t",
      titleSecondLine: "o achieve.",
    },
    sectionThree: {
      subtitle: "PRODUCT",
      titleFirstLineHighlight: "CROP WHICH GRO",
      titleFirstLine: "WS",
      titleSecondLineHighlight: "in efficiency a",
      titleSecondLine: "nd productivity",
    },
    sectionFour: {
      subtitle: "PARTNERS TO GROW",
      href: "SEE MORE",
      titleFirstLineHighlight: "CHANCE TO WAL",
      titleFirstLine: "K THROUGHT",
      titleSecondLineHighlight: "THE WAY WIT",
      titleSecondLine: "H THE BEST",
      mobile: {
        titleFirstLineHighlight: "CHANC",
        titleFirstLine: "E TO WALK",
        titleSecondLineHighlight: "THROUGH TH",
        titleSecondLine: "E WAY ",
        titleThirdLineHightlight: "WITH T",
        titleThirdLine: "HE BEST",
      },
    },
    sectionFive: {
      subtitle: "CONTACT",
      titleFirstLineHighlight: "THE BEST PEANUT I",
      titleFirstLine: "N THE WORLD",
      titleSecondLineHighlight: "GROWS IN O",
      titleSecondLine: "UR LAND",
      mobile: {
        titleFirstLineHighlight: "THE BEST PEA",
        titleFirstLine: "NUT IN",
        titleSecondLineHighlight: "THE WORLD",
        titleSecondLine: "GROWS",
        titleThirdLineHightlight: "IN O",
        titleThirdLine: "UR LAND",
      },
    },
  },
  // ------------------- SOBRE MANI -------------------
  lyfecycle: {
    title: "Life cycle",
    progress: "Crop Progress",
    sanity: "Vegetal health",
    solutions: "Market Solutions",
    program: "Programs",
    day: "Day",
    day00: {
      title: "PRE SOWING",
    },
    day01: {
      title: "PRE EMERGENCY",
    },
    day12: {
      title: "VE EMERGENCY",
      cont: "Cotyledons near the soil surface and seedling showing some part.",
    },
    day15: {
      title: "V0 COTYLEDONAR",
      cont: "Open and horizontal cotyledon under the ground surface.",
    },
    day18: {
      title: "V1 First sheet ",
      title02: "V1 FIRST SHEET",
      subtitle: "TETRAFOLIATED",
      cont: "First node developed on the main axis with its unfolded tetrafoliate leaf and horizontal leaflets.",
      titlebenefits: "N FIXATION / Benefit",
      benefics:
        "BenefitAs a legume, peanuts are able to absorb nitrogen from the air through the nodules on their roots, making them independent of nitrogen fertilizer use and favoring crop mix with gramineous plants.",
    },
    day35: {
      title: "VN “N” TETRAFOLIATED LEAF",
      title02: "Vn “n”",
      subtitle: "",
      cont: "“N” nodes developed on the main axis with or without their expanded tetrafoliate leaves and horizontal leaflets.",
      titlebenefits: "MICROCLIMATE/Benefit",
      benefics:
        "When it rains, the peanut plant shape creates a microclimate under the foliage and above the land making it possible to increase the use of water efficiency.",
    },
    day40: {
      title: "R1 Start of flowering",
      title02: "R1 FLOWERINGS",
      subtitle: "",
      cont: "An open flower in some knot.",
      titlebenefits: "FLOWERING /Benefit",
      benefics:
        "When it rains, peanuts have the ability to flower indeterminately making the crop cycle and fructification adjust to weather conditions.",
    },
    day50: {
      title: "R2 Beginning of nail forming",
      title02: "R2 BEGINNING OF NAIL FORMING",
      subtitle: "",
      cont: "A nail (gynophore) elongating.",
      titlebenefits: "GROWTH / Benefit",
      benefics:
        "When there are prolonged rainfall or drought periods, the plant stops its growth and water demand is minimized.",
    },
    day60: {
      title: "R3 Beginning of fruit formation",
      title02: "R3 FRUIT TRAINING",
      subtitle: "",
      cont: "A nail driven into the ground with the end (ovary) being twice the diameter of the nail.",
      titlebenefits: "ROOTS / Benefit",
      benefics:
        "Roots of peanut plants can be up to 2 mts long. This makes exploration efficient in capturing water and nutrients, as well as improving soil oxygenation.",
    },
    day70: {
      title: "R4 Fully developed fruit",
      title02: "R4 FULL FRUIT",
      subtitle: "",
      cont: "A fully grown fruit, with the characteristic dimensions of the crop.",
      titlebenefits: "FRUIT > BIO/Benefit",
      benefics:
        "The relationship between biomass and fruit is low, in other words there are more fruits than foliage. This makes crop water consumption lower compared to others.",
    },
    day80: {
      title: "R5 Seed formation start",
      title02: "R5 SEED FORMATION",
      subtitle: "",
      cont: "A fully grown fruit, with visible growth of the seed cotyledons, when a cross section of the fruit is made (when the liquid endosperm stage has passed).",
    },
    day85: {
      title: "R6 Fully developed seed",
      title02: "R6 SEED",
      subtitle: "",
      cont: "A seeded fruit that fills its cavities.",
      titlebenefits: "NPK /Benefit",
      benefics:
        "Given the characteristics of its root system and the biological nitrogen absorption, the crop does not depend on the use of fertilizers as a source of macro- and meso-nutrients.",
    },
    day120: {
      title: "R7 Beginning of maturity",
      title02: "R7 MATURITY",
      subtitle: "",
      cont: "A fruit showing cinnamon or brown coloration on the pericap internal face in 50% of the crop plants.",
    },
    day160: {
      title: "R8 Harvest or plucked maturity",
      title02: "R8 HARVEST",
      subtitle: "",
      cont: "Equal to R7 depending on the percentage of crop plants planted: 70 to 75% in Virginia and Spanish types, 80% in Valencia type.",
      titlebenefits: "DECOMPACTS /Benefit",
      benefics:
        "Both the exploratory root system and conservative management practices to facilitate exploration make the crop an excellent tool to decompact subsurface soil layers and provide oxygenation and mineralization to the soil.",
    },
  },
  // ------------------- PROGRAMAS -------------------
  programs: {
    sectionOne: {
      subtitle: "Maniseros Programs",
      titleFirstLineHighlight: "Advantag",
      titleFirstLine: "es and ",
      titleSecondLineHighlight: "benefits of a",
      titleSecondLine: "n ",
      titleThirdLineHightlight: "inproved prop",
      titleThirdLine: "osal.",
      desc: 'Growers who participate in the program will be able to find out all about the benefits that means to become part of the "maniseros"  family, being able to increase the productivity of their agriculture and the benefits for the soil of their farms as well, improving the sustainability of their productive system by incorporating this fascinating  crop. Two attractive proposals tailor-made for the farm producers who need to enhance the crop production and satisfy the growing demand maintaining the premium quality standard of the argentinian peanut,  internationally renown and required. ',
      cuadro01: "FEDEA & PRODEMAN",
      bajada01: 'Become part of "maniseros" international family.',
      boton01: "KNOW MORE",
      cuadro02: "Turnkey",
      bajada02: "Global solution according to the area of inerest.",
    },
    sectionTwo: {
      titleFirstLine: "Fedea A",
      titleFirstLineHighlight: "ND ",
      titleSecondLineHighlight: "PDM",
      titleSecondLine: "",
      desc: 'Fedea and PDM participate together with "maniseros"  in the production and implementation of this innovative program which accompanies the grower from the global technical direction to the final crop providing support at each stage and the necessary guarantee so that he can introduce the peanut by carrying out a successful strategy in its mix of crops improving its productity.',
    },
    sectionThree: {
      subtitle: "Commitments to the Grower",
      box01: "1. GLOBAL TECHNICAL DIRECTION OF THE CROP",
      desc01:
        "In charge of a staff of expert egronomist peanut producers who will supply the global dta from the election of a piece of land, its preparation, crop protection and the decision about the moment and way with the best practices of the crop management. 	",
      box02: "2. THE BEST SEEDS SUPPLY",
      desc02:
        "Which have been trated by azoxistrobin 0,6%, carbonxin 20%,metiltiofanato 10%, metalaxil 1.33% and molibdeno (mo) 4% recieving a unique treatment which garantees the  crop success.",
      box03: "3. LOGISTICS ORGANIZATION OF THE CROP",
      desc03:
        "The best collaborators in each process of the production through our expert contarctors net to get  in due time and proper course to accomplish each of the tasks the peanut crop demands.",
      box04: "4. GLOBAL TRAINING",
      desc04:
        "Trough which the producers will receive all the knowledge and we will pass to them our passion while providing  all the training about the right way to achieve the peanut crop. They will go through the experience on  how to achieve a really successful crop.",
      box05: "5. TOTAL PURCHASE GUARANTEED",
      desc05:
        "Guaranteeing this way a successesful peanut production at the market value of reference and organizing the total reception with the minimun merchandise setbacks.",
    },
    sectionFour: {
      subtitle: "Benefits for the producer",
      boxTitle01: "Crop guarantee",
      desc01:
        "Total crop bonus in case of an inferior efficiency than the one referred at base in the box clean and dry.",
      boxTitle02: "Seeds guarantee",
      desc02:
        "Parcial seed bonus for the next capaign in case of an inferior efficiency than the one referred at base in the box clean and dry.",
      boxTitle03: "Contractors fidelity",
      desc03:
        "Fee plus to the contractors dedicated to crop tasks in growers under the peanut program.",
    },
    sectionFive: {
      titleFirstLineHighlight: "Ke",
      titleFirstLine: "y in",
      titleSecondLineHighlight: "hand p",
      titleSecondLine: "rogram",
      bajada:
        "A tempting proposal (“all included”) for a limited group of growers who want to try the peanut crop for the first time.",
      desc01:
        "The Turnkey Program, a true integral solution, will be 100% managed by PDM and includes the technical direction service offer and total execution of the peanut crop, contemplating from the lot selection to harvest.",
      desc02:
        "Maniseros, with the grower, will execute and carry out the integral crop production process with all their knowledge and good practices, taking over of the technical direction, the protection of the crop, the labors and the harvest, at which point PDM will charge for its participation in exchange on what is produced, discounting the total cost of the executed crop.",
    },
    sectionSix: {
      subtitle: "Key in hand advantages",
      box01: "The farm owner keeps his role of producer",
      box02: "Global execution of the crop with the technical direction of PDM",
      box03: "No charges of the crop management",
      box04: "Crop competitive planning",
      box05: "No financial componement in the exchange",
      box06: "Crop total leverage",
      box07: "Digital agriculture flow",
      box08: "Crop total documentation and biweekly reports",
    },
    sectionSeven: {
      subtitle: "Guarantee of crop success under the following program.",
      box01: "SUPPORT",
      desc01: "A multidisciplinary trained team with 40 years experience.",
      box02: "TASKS",
      desc02: "The largest and most efficient contractors net and machinery.",
      box03: "SEEDS",
      desc03:
        "Our best partner for each piece of land and for the crop success.",
      box04: "LOGISTICS AND PROCESS",
      desc04:
        "Reception, analysis, prosecution and store monitored by digital traceability systems.",
      boton: "Are you interested in one of our plans?",
    },
    contacto: {
      subtitle: "Contact",
      title: "The best peanut in the world grows in our land.",
      campo01: "Name",
      campo02: "Last Name",
      campo03: "Email",
      send: "Send",
    },
  },
  // ------------------- PRODUCTO -------------------
  product: {
    sectionOne: {
      subtitle: "Product",
      titleFirstLineHighlight: "The bes",
      titleFirstLine: "t peanut",
      titleSecondLineHighlight: "in th",
      titleSecondLine: "e world:",
      titleThirdLineHightlight: "ou",
      titleThirdLine: "r pride",
      desc01:
        "Argentina has been worldwide consolidated as the most renown peanut exporter and its prestige  is precisely achieved for its high quality  which differenciates it as the best peanut in the world and it has opened doors into the main markets.",
      title01: "EXPORTS",
      number01: "+ 400.000 Tn",
      number02: "+ 100 Destinations ",
      number03: "+ 1 u$ Billion",
      desc02:
        "95% of the total peanut produced in the country it is destined to be exported abroad being the european union the main market of interest.",
    },
    sectionTwo: {
      title:
        "Among the manufactured products which are exported the ones that stand out are:",
      box01: '"Monfiteria" Peanut (premium peanut)',
      little01: "(Shelled, Blanched, Splits, Chopped, Sliced)",
      box02: "Peanut oil  raw and refined",
      box03: "Prepared and/or flavoured peanut",
      little02: "(Snacks)",
      box04: "Peanut flour",
      box05: "Peanut paste",
      box06: "Peanuts pellets and expellers",
      box07: "Peanut butter",
    },
    sectionThree: {
      subtitle: "How do you measure the efficiency?",
      boton: "KNOW MORE",
      title01: "A SELECTED CULTIGEN",
      subtitle01: "To develop healthy food and sustainably produced.",
      desc0101:
        "Peanut is a cultigen, an historically domesticated crop by man, originally elaborated with a nutritional purpose and its subsequent commercial development.",
      desc0102:
        "As a legume, enriches the soil with nitrogen, providing advantages in its role in the crop mix by direct seeding, helping to maintain an adequate nutritional balance, contributing to the sustainability of the production system.",
      title02: '"CONFITERIA" PEANUT',
      desc0201:
        "it is indicated in percentage ratio  how  much represents the weight of the grains in the total weight of the boxes that arrive at PDM.",
      desc0202:
        'Unlike to what many  people think, the ""confiteria"" peanut is not a commodity as it is usually mistakenly believed but an elaborated food product, a product of agricultural origin which counts in its final process with an extraordinary component of added value.',
      subtitle02: '"Confiteria" Peanut (premium):',
      desc03: "An elaborated  food product of extraordinary added value. ",
      column01Title: "GRAINS",
      column01box01: "LOOSE GRAINS",
      column01box02: "INSIDE THE SHELL",
      divisor: "Confiteria Efficiency",
      column01Title01: "INSIDE",
      column01Title02: "THE",
      column01Title03: "SHELL",
      // column01box01: "CONFITERIA",
      // column01box02: "FINE-GRAINED",
      zaranda: "ZARANADA ",
      btna: "KNOW MORE",
      img: "/assets/analisisen.png",
      imgmobile: "/assets/analisisen-mobile.png",
    },
    sectionFour: {
      titleFirstLineHighlight: "OVER 10",
      titleFirstLine: "0 YEARS OF HISTORY",
      titleSecondLineHighlight: "to produce  a superior quality pro",
      titleSecondLine: "duct.",
      subtitle: "Historical efficiency",
      desc01:
        "Today we are writing one of the most important chapters in history about the peanut production in our country, facing a huge international demand and the challenge of keeping up a  distinctive superior quality which represents a real opportunity for new producers to be part of the success as well and add their pasion by joining the big argentinian peanut producers family, well known all around the world for producing the best peanut in the wolrd.",
      desc02:
        "This experience initially originated in our country with a group of passionate growers who, by the end of 1896, bet on the potential of our rich soil coupled with the unique properties of the peanut crop. Growing, overcoming challenges, improving harvesting processes, investing in technology to achieve innovative solutions, until reaching the perfect High Oleic peanuts, wich are the most required in international markets.",
      titlegraph01: "Prices in u$d/ton shell paid off in PDM",
      titlegraph02: "Argentinian efficiency in ta/hap shell",
      titleFirstLineHighlightMobile: "OVER 10",
      titleFirstLineMobile: "0 YEARS OF",
      titleSecondLineHighlightMobile: "HISTOR",
      titleSecondLineMobile: "Y to produce",
      titleThirthLineHighlightMobile: "a superior qua",
      titleThirthLineMobile: "lity",
      titleForthLineHighlightMobile: "pro",
      titleForthLineMobile: "duct.",
    },
    sectionFive: {
      title01: "Start growing peanut!",
      desc01:
        "Start growing peanut! We have all the options for success in your peanut growing start up.",
      boton01: "PROGRAMS",
      title02: "Rent",
      desc02:
        "Do you want to have peanut on your farm but you don´t want to keep the crop? Call us and we rent you the farm.",
      boton02: "CONTACT US	",
    },
    sectionSix: {
      box01: "Shell Yield (Tn/Ha):",
      box02: "Prices U$D/Ton: ",
      box03: "(Tn/Ha) Shell ",
      box01Mobile: "Year",
    },
  },
  // ------------------- PARTENER -  -------------------
  partners: {
    cta: "IF YOU ARE INTERESTED, CONTACT US",
    fedea: {
      sectionOne: {
        titleFirstLineHighlight: " Quali",
        titleFirstLine: "ty",
        titleSecondLineHighlight: "customer se",
        titleSecondLine: "rvice",
      },
      sectionTwo: {
        title01: "Fedea accompanies you",
        title02: "all trough the way",
        desc01:
          "We are a company with over 25 years  experience in the farm  market. With 30 branches in la pampa, wetern buenos aires and southern cordoba, we accompany the producers all along the production cycle.Together with the fedea prodeman peanut program we have other six units of business: crop protection, seeds, fertilizers, grain supply, fuel and animal nutrition.",
        desc02:
          " We count with a team of 170 people who provide technical  consultancy, financial services and farm global logistics among other services.Besides we count with fertilizing plants, grain supply and fuel which allowas us to be close to our clients in their everyday needs.",
      },
      sectionThree: {
        title: "Services for each growth stage",
        box01: {
          title: "Pre seeding",
          item01: "- Parcel of land election",
          item02: "- Fallow land planning",
          item03: "- Sampling soil",
          item04: "- Genetic strategy and seeding date",
        },
        box02: {
          title: "Pre emergency",
          item01: "- Emerging strategies",
          item02: "- Seeding definition",
          item03: "- Seeding logistics",
          item04: "- Seed logistics",
        },
        box03: {
          title: "Ve V0",
          item01: "- Implanting report",
          item02: "- Stand definition",
          item03: "- Inicial evaluation",
        },
        box04: {
          title: "V1 - Vn - R1 - R6 - R7",
          item01: "- Intensive monitoring",
          item02: "- Technical counseling",
          item03: "- Tasks logistics",
          item04: "- Recommendations phinotyping",
        },
        box05: {
          title: "Crop",
          item01: "- Crop logistics",
          item02: "- Support postharvest Crop ",
          item03: "- Contribution Rotation and/or sequencing.",
        },
      },
      sectionFour: {
        title: "Fedea offers you",
        box01: "It improves the rentability of the production system",
        box02: "Specific task management",
        box03: "Counseling and  specialized technical assistance  ",
        box04: "Back up of solid and realiable companies",
        box05: "Adding a new crop to the cropmix",
        box06: "Map support",
        dest: "See map",
      },
      sectionSix: {
        title: "Benefits",
        box01: "Find all the corteva products at the best price and financing.",
        box02:
          "We offer quality and technology in axion agro fuel and castrol lubricants.",
        box03:
          "Brevant corn seeds - excellent efficiency in early corn crops and late corncrops.",
        box04:
          "We supply and commercialize all the grains and cereals. We look for the best business alternative either delivering at our plants or at ports.",
      },
    },
    basf: {
      sectionOne: {
        titleFirstLineHighlight: " ",
        titleFirstLine: " ",
        titleSecondLineHighlight: " ",
        titleSecondLine: " ",
      },
      sectionTwo: {
        title01:
          "Together looking for an ideal balance for the farmers´success, agriculture and the future generations",
        title02: "",
        desc01:
          "Agriculture has to do with balance. The natural balance  which promotes the  campaigns,the climate and the seeding cycles. There has never been a most challenging moment in agriculture before.But there has never been either a moment of such great opportunity before. Basf, through its worldwide experienceto achieve  knows how important it is to achieve an ideal balance for the´farmers success . That is the reason  why maniseros count with basf as an outstanding",
        desc02:
          " partner in its network of associated producers, in this dynamic world, BASF accepts the challenge by creating innovative solutions for agriculture and environmental hygiene, allowing us to grow and carry forward all the good practices in order to improve the planet, trying to find the ideal balanance for the farmers success, agriculture and the future generations. ",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "0 a 10 days before the seeding",
        },
        box02: {
          title: "Seeding",
        },
        box03: {
          title: "V2",
        },
        box04: {
          title: "V6 - R1",
        },
        box05: {
          title: "R3",
        },
      },
      sectionSix: {
        title: "Benefits",
        box01:
          'By buying products from the basf portfolio you can accumulate reward points and exchange these points for vans, technology and much more from our "multiplica" catalogue, the most important benefit and discount program in the argentinian agro.',
        box01reg: " Register",
        box01link: " here",
        box01final: " and become part of it.",
        box02:
          "Sow knowledge. Harvest results. A space for training, interactive talks, downloadable material for the different  farm problems  and its management.",
        box02link: " Know more about it...",
        box03:
          "If you have  questions about any of our products you can contact basf customer service via whatsapp and get further information.",
        box04:
          "xarvio™, the most complete digital solutions platform for the field. Contact us and digitize your field.",
      },
    },
    bayer: {
      sectionOne: {
        titleFirstLineHighlight: "#YourPeanutsP",
        titleFirstLine: "artner",
      },
      sectionTwo: {
        title01: "Global holding",
        title02: "and innovative",
        desc01:
          "We are a leader company in providing technological solutions and farm products which improve  the agriculture productivity  and the food quality. Our objective is to allow small ",
        desc02:
          "and big  farmers to produce more on their land and at the same time to keep the natural resources of our planet like  water  and energy.",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Pre emergency",
        },
        box02: {
          title: "V5 - R3",
        },
      },
      sectionSix: {
        title: "Benefits",
        box01: "Climate FieldView",
        box0101:
          " interprets the farm data in a simple way  so that  you can take the most advantage of every and each hectare of your farm.",
        box02:
          "A real connecting network which strengthens the development of each member.A beneficial proposal.",
        box03title: "Talks with experts",
        box03:
          'Sustainability solutions with the programs. ""yo aplico responsablemente""  and forward farming bayer.  Talks by mentors about weed control, illnesses and good agricultural practices.',
      },
    },
    corteva: {
      sectionOne: {
        titleFirstLineHighlight: "Keep growin",
        titleFirstLine: "g",
      },
      sectionTwo: {
        title01: "Dedicated to ",
        title02: "the agriculture",
        desc01:
          "Corteva agriscience™  is a company dedicated 100% to agriculture and 200 years of experience in this field. We offer seed solutions , crop and agriculture  protection through out the crop life cycle. We focus on technological innovation and elaborating products for the protection of crops and seeds to ",
        desc02:
          "enhance the farmers´productivity and efficiency and at the same time minimizing the risk of their business our  digital tools provide information to our partners on a regular basis in order to grow today and keep it doing it in the future. ",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Pre Seeding",
        },
        box02: {
          title: "Pre Emergency",
        },
        box03: {
          title: "V1 Vn",
        },
        box04: {
          title: "R1 - R6",
        },
      },
      sectionSix: {
        title: "Benefits",
        box01:
          "Milote is a web application developed by coteva agriscience which allows the user to manage all his parcels of land, follow their evolution according to a green rate data base, see historical productivities based on 18 years of information. ",
        box02:
          "Corteva flight allows to make more efficient and faster the measuring during the different phenological stages of the crops. Our unique charasteristic , edge computing, allo inws to process all the information in the moment through artificial intelligence  algorithms in order to provide an accurate, fast and reliable report.",
        box03:
          "Access to exclusive bonuses for the purchase of mix preducts from the peanut pack (50has pack) at these emblematic distributors.",
      },
    },
    nova: {
      sectionOne: {
        titleFirstLineHighlight: "Crop qua",
        titleFirstLine: "lity ",
      },
      sectionTwo: {
        title01: "Accompaning your crops",
        title02: "",
        desc01:
          "Nova is an argentinian company born 35 years ago in  cañada de gómez, province of santa fe, argentina.  It formulates , develops and commercializes the main lines of preducts for the crops  protection and growth and it counts with a biotechnolgy dedicated to the development and production of industrial enzymes for several industries.",
        desc02:
          "Since the year 2010 it operates under iso regulations. In 2018 it obtained the iso certification 14004:2015 in environmental management systems and it certified again iso 9001:2015 in quality management systems.",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Seed tratment",
        },
        box02: {
          title: "Pre Seeding",
        },
        box03: {
          title: "Early PRE - POE",
        },
        box04: {
          title: "V6 - R3",
        },
        box05: {
          title: "V2 - V4",
        },
        box06: {
          title: "R1 - R2",
        },
        box07: {
          title: "R1 - R4",
        },
      },
    },
    oroverde: {
      sectionOne: {
        titleFirstLineHighlight: "We see th",
        titleFirstLine: "e farm ",
        titleSecondLineHighlight: "in ano",
        titleSecondLine: "ther color",
      },
      sectionTwo: {
        title01: "Us",
        desc01:
          "Oro verde services is a family company dedicated to provide services to the agri-food network, principally plant health, oriented to the development of  processing technologies and crop global treatment,",
        desc02:
          "inluding the seed health and quality and the monitoring of the crops, providing information  in order to take decisions, minimizing risk and uncertainty.",
      },
      sectionThree: {
        title: "Services for each growth stage",
        box01: {
          title: "Pre seeding",
          item01: "- Parcel of land selection.",
          item02: "- Soil pathological analysis.",
          item03: "- Seed analysis.",
          item04: "- Variety selection.",
          item05: "- Fallow counseling.",
        },
        box02: {
          title: "Pre emergency",
          item01: "- Fallow counseling.",
          item02: "- Implant and emergency quality.",
        },
        box03: {
          title: "Ve - V0",
          item01: "- Post emergency weed control. (Recommendations)",
        },
        box04: {
          title: "V1 - Vn",
          item01: "- Smallpox monitoring.",
          item02: "- Illnesses diagnosis.",
          item03: "- Plague diagnosis.",
          item04: "- Technical tours to the parcels of land. ",
          item05: "- Field technician.",
          item06: "- Smallpox alert system.",
        },
        box05: {
          title: "R1 - R6",
          item01: "- Smallpox monitoring.",
          item02: "- Illnesses diagnosis.",
          item03: "- Plague diagnosis.",
          item04: "- Technical tours to the parcels of land.",
          item05: "- Field technician.",
          item06: "- Smallpox alert system.",
        },
        box06: {
          title: "R7",
          item01: "- Smallpox alert system.",
        },
        box07: {
          title: "Crop",
          item01: "- Seed quality.",
        },
      },
    },
    summit: {
      sectionOne: {
        titleFirstLineHighlight: "Leader jap",
        titleFirstLine: "anese",
        titleSecondLineHighlight: "technolo",
        titleSecondLine: "gy",
      },
      sectionTwo: {
        title01: "A new way of coming up with the",
        title02: "protection of the crop",
        desc01:
          "The evolution starts with change and change needs decision. The most emerging challenge  implies developing the most effective products to protect  the crops but at the same time properly protecting  the ones who use them, who consume them and the environment.That is why summit agro is an oustanding partner of maniseros for its network of associated producers, because of its strong commitmentwith the environment and the life quality of people.",
        desc02:
          " The internal and external  activities    and its carried out by summit agro  are within a sustainable context using  concrete measures after their objective, providing reliable solutions in face of the needs and opportunities of the farm community and its sustainable growth. ",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Pre seeding",
        },
        box02: {
          title: "Pre emergency",
        },
        box03: {
          title: "V1 - Vn",
        },
        box04: {
          title: "R1 - R6",
        },
      },
      sectionSix: {
        title: "Benefits",
        box01: "Now we are closer, let´s chat by whatsapp",
      },
    },
    syngenta: {
      sectionOne: {
        titleFirstLineHighlight: "",
        titleFirstLine: "",
        titleSecondLineHighlight: "",
        titleSecondLine: "",
      },
      sectionTwo: {
        title01: "About",
        title02: "",
        desc01:
          "Syngenta is one of the main agricultural companies in the world. Our ambition is to help to feed the world in a safe way while we take care of our planet.Our objective is to improve the  sustainability, the quality and safety of the agriculture with worlwide science and innovative solutions for crops. Our technologies allow millions of farmers in the whole world  to make a better use of the limited agricultural resources.  Syngenta is part of the syngenta group with 48000 people in over 100 countries",
        desc02:
          "and they are working on changing the way crops are produced. Trough associations, the collaboration and the good growth plan  program, we are committed to accelerate the innovation for the farmers and nature, working hard to achieve an agriculture neutral in carbon, helping people to keep safe and healthy and joining our efforts to make an impact.",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Pre seeding",
        },
        box02: {
          title: "Pre emergency",
        },
        box03: {
          title: "Ve - V0",
        },
        box04: {
          title: "V1 - Vn",
        },
        box05: {
          title: "R1 - R6",
        },
      },
    },
    sipcam: {
      sectionOne: {
        titleFirstLineHighlight: "Better pro",
        titleFirstLine: "tection.",
        titleSecondLineHighlight: "Better Y",
        titleSecondLine: "ield.",
      },
      sectionTwo: {
        title01: "Supporting farming since 1946.",
        desc01:
          "We have the mindset and creativity of an Italian company, with more than 70 years of research and development, applied to the field and farmers' service. Our group is one of the few agrochemical companies that carries out an integrated process from the synthesis of active ingredients to the formulation and packaging of the final product.",
        desc02:
          "We do this whole process under the highest European quality standards and environmental care standards. For these reasons, Maniseros can rely on Sipcam to support the challenge of growing peanuts in an adequate, protected and successful way.",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Land fallow to peanut",
        },
        box02: {
          title: "Pre seeding",
        },
        box03: {
          title: "Pre emergency",
        },
        box04: {
          title: "R1 - R6",
        },
        box05: {
          title: "Post crop",
        },
      },
      sectionFour: {
        title: "Benefits",
        box01: {
          title: "Technical Education",
          content: "Use of Terbyne in Peanut Crops.",
          cta: "WATCH VIDEO",
        },
        box02: {
          title: "Technical Education",
          content: "Use of Terbyne in Corn and Sorghum Crops.",
          cta: "WATCH VIDEO",
        },
        box03: {
          title: "Follow us",
          content:
            "To learn more about Sipcam and our peanut growing solutions, follow us on our social networks.",
        },
      },
    },
    stoller: {
      sectionOne: {
        titleFirstLineHighlight: "Greater Yi",
        titleFirstLine: "eld in",
        titleSecondLineHighlight: "Every Fi",
        titleSecondLine: "eld",
      },
      sectionTwo: {
        title01: "Specialists in plant nutrition",
        desc01:
          "The agriculture we knew began to redefine itself. Working together with different growers, we seek constant innovation to achieve sustainable precision agriculture.",
        desc02:
          "Committed to the world, our team has the great challenge to provide integrated solutions and plant nutrition knowledge so your field continues evolving in the service of life.",
      },
      sectionThree: {
        title: "Products for each growth stage",
        box01: {
          title: "Seed tratment",
          img01: "/assets/logo_stimulate_mani.svg",
          img02: "/assets/logo_masterfix-mani.svg",
        },
        box02: {
          title: "Seed",
          img01: "/assets/logo_masterfix-L.svg",
        },
        box03: {
          title: "V6 - Vn",
          img01: "/assets/logo_stimulate.svg",
        },
        box04: {
          title: "R1 - R2",
          img01: "/assets/logo_x-cyte.svg",
        },
        box05: {
          title: "R5 - R6",
          img01: "/assets/logo_nitrate_balanced.svg",
        },
      },
      sectionFour: {
        title: "Benefits",
        box01: {
          content:
            "You are invited to experience an authentic online field day on peanut production. Our experts will show you the best strategies to boost your crop.",
          cta: "WATCH VIDEO",
        },
        box02: {
          content: "Now we are closer, lets chat by Whatsapp.",
          cta: "+54 9 3517 68-7995",
        },
      },
    },
    upl: {
      sectionOne: {
        titleFirstLineHighlight: "UPL #Open",
        titleFirstLine: "Ag",
      },
      sectionTwo: {
        title01: "Crop protection and bio-solutions",
        title02: "for sustainable agriculture",
        desc01:
          "UPL Ltd. is a global provider of sustainable products and solutions for agriculture, with more than $5.2 billion in annual revenues. We are a purpose-driven company. Through OpenAg™, UPL focuses on speeding the food system's progress. We are building a network that is reinventing sustainability, by redefining the way an entire industry thinks and works, open to new ideas, innovation and",
        desc02:
          "answers as we strive to fulfill our mission to make every food product more sustainable. As one of the world's largest agricultural solutions companies, our extensive portfolio includes biological and traditional crop protection solutions with more than 14,000 entries. We are present in more than 130 countries, represented by more than 10,000 partners around the world.",
      },
      sectionThree: {
        title: "Services for each growth stage",
        box01: {
          title: "Land fallow",
        },
        box02: {
          title: "Pre emergency",
        },
        box03: {
          title: "Seeding",
        },
        box04: {
          title: "V1 - Vn",
        },
        box05: {
          title: "R1 - R2",
        },
        box06: {
          title: "R4",
        },
        box07: {
          title: "R5 - R6",
        },
        box08: {
          title: "Stored Grains",
        },
      },
      sectionFour: {
        title: "Benefits",
        box01: {
          content:
            "Learn about our exclusive program of biosolutions and crop protection products",
          cta: "Click Here",
        },
        box02: {
          content: "Pronutiva in peanut crops",
          cta: "Watch Video",
        },
        box03: {
          content: "Now we are closer, let's chat by Whatsapp",
          cta: "+54 911 6820 2519",
        },
      },
    },
    fmc: {
      sectionOne: {},
      sectionTwo: {
        title01: "A constantly growing commitment",
        desc01:
          "FMC is an innovation driven company committed to discover new active ingredients, product formulation and application technologies. For over 130 years, our purpose has been to develop innovative solutions for agriculture and industry, and to accompany the grower by providing answers to his daily crop protection needs.",
        desc02:
          "In order to achieve this, our fundamental Business Principles are: ",
        item01: "Discover and develop cutting-edge sustainable technologies.",
        item02:
          "Exceed grower expectations in terms of collaboration, speed and agility.",
        item03:
          "Offer simplicity, transparency and commitment in all our businesses.",
        item04:
          "Work rigorously for our customers, collaborators and investors' benefit, seeking a triple positive impact: economic, social and environmental.",
      },
      sectionThree: {
        title: "Services for each growth stage",
        box01: {
          title: "Land fallow",
        },
        box02: {
          title: "Pre-seeding",
        },
        box03: {
          title: "V1 - Vn",
        },
        box04: {
          title: "V6 - Vn",
        },
        box05: {
          title: "R1 - R6",
        },
      },
      sectionFour: {
        title: "Benefits",
        box01: {
          content:
            "ARC Farm Intelligence is FMC's mobile platform that delivers real-time insect pressure data up to a week in advance and up to 90% reliability for major pests, helping to protect crop productivity and yields.",
          cta: "Click Here",
        },
        box02: {
          title: " Follow us on Social Media",
          content:
            "To learn more about our peanut crops portfolio, follow us in our social media networks",
        },
        box03: {
          content:
            "For any comments or concerns, please contact us through WhatsApp",
          cta1: "+54 9 358 6029421",
          cta2: "or visit ",
          cta3: "our website",
        },
      },
    },
    aviacion: {
      page01: {
        sectionOne: {
          titleFirstLineHighlight: "Prode",
          titleFirstLine: "man",
          titleSecondLineHighlight: "Aerial Ser",
          titleSecondLine: "vices",
        },
        sectionTwo: {
          title:
            "When an insect, weed or disease damage your crop, time is critical.",
          content: {
            firstP:
              "For crop protection, our aviation department and modern aircrafts are prepared to cover large areas and distances, in order to keep the crops protected and healthy enough to express all their genetic yield potential.",
            secondP:
              "Balance the impact of soil compaction and crop damage caused by wheel traffic, managing thresholds for crop protection with a quality application and only paying for the product applied.",
          },
        },
        sectionThree: {
          titleFirstLineHighlight01: "¡Thank you for con",
          titleFirstLine01: "tacting us!",
          content: {
            firstLine: "We have received your online request.",
            secondLine: "We will respond as soon as possible.",
          },
          titleFirstLineHighlight02: "¡See you fr",
          titleFirstLine02: "om the air!",
        },
        sectionFour: {
          title01: "1. Draw your fields",
          searchPlaceholder: "Enter a location",
          fileTypes:
            "Or you can upload a KMZ, KML, GeoJSON or SHP file (SHP must be packed in a ZIP folder containing *.shp, *.dbf and .shx).",
          attachedFile: "File Attachment",
          button: "Select file",
          fileSelected: "No file selected",
        },
        steps: "steps",
        drawMessage: "You must draw an area on the map.",
      },
      page02: {
        sectionOne: {
          title01: "2. Select your own and/or specify an exact quote*",
          table: {
            firstRow: {
              item01: "Type",
              item02: "Your Own",
              item03: "Exact quote",
            },
            secondRow: "Herbicide",
            thirdRow: "Insecticide",
            fourthRow: "Fungicide",
            fifthRow: "Solids",
          },
          arrayNames: {
            item0: "Your Own Herbicide",
            item1: "Herbicide Exact quote",
            item2: "Your Own Insecticide",
            item3: "Insecticide Exact quote",
            item4: "Your Own Fungicide",
            item5: "Fungicide Exact quote",
            item6: "Your Own Solids",
            item7: "Solids Exact quote",
          },
          selector: {
            title: "Crop Selector",
            item01: "Peanut",
            item02: "Corn",
            item03: "Soybeans",
            item04: "Wheat",
            item05: "Sunflower",
            item06: "Others",
          },
          clarifications: {
            firstLine:
              "* You will receive a quote with the available product details.",
            secondLine: "In case you require only the flight, select",
            secondLineI: "Your Own",
            thirdLine: "includes cover crops seeds and fertilizers.",
            thirdLineI: "Solids",
          },
        },
        sectionTwo: {
          title01: "3. Contact information",
          form: {
            input01: "CUIT",
            input02: "Company name",
            input03: "Name and Surname",
            input04: "Email",
            input05: "Phone",
            input06: "Notes",
            input06placeholder:
              "You can specify here the insect, weed or disease to control, as well as crop clarifications and important obstructions.",
          },
        },
      },
      alert: {
        pending: "Please wait while we send your crop data.",
        success: "Data sent successfully.",
        error:
          "Data delivery failed. Please try again later or get in touch with us.",
      },
    },
  },
  // ------------------- CROPMIX -------------------
  cropmix: {
    section: "Cropmix",
    titleFirstLineHighlight: "Cropmix Simu",
    titleFirstLine: "lator",
    page01: {
      section01: {
        title: "1. Select your current Soy/Corn mix",
        soy: "Soy(%)",
        corn: "Corn(%)",
        lowValue: "Low Value",
        highValue: "High Value",
      },
      section02: {
        title: "2. Type in the following parameters",
      },
      form: {
        column01Title: "Soy",
        column02Title: "Corn",
        row01Title: "Yield (Tn/Ha)",
        row02Title: "Cost (u$s/Ha)",
        row03Title: "Rent (u$s/Ha)",
        row03Subtitle: "Optional",
        row04Title: "Price (u$s/Tn)",
      },
      button: "SEND",
    },
    page02: {
      title: "3. Peanut Cropmix Report",
      graph: {
        bar01: "Gross Income",
        bar02: "Cost + Rent",
        bar03: "Gross Margin",
      },
      bars: {
        soy: "Soy",
        corn: "Corn",
        peanut: "Peanut",
      },
      details: {
        line01:
          "New cropmix is calculated assuming peanut into cropmix by 20%.",
        line02:
          "Peanut yield is calculated with a dataset comparison Soybean grain Yield vs. Peanut grain Yield and G/S ratio of 0.68 where, Yield = 0.8X + 0.5 Tn/Ha (R2: 0.8).",
        line03:
          "Peanut cost includes labor, crop protection products and harvest, average values for the last three seasons on 50K ha dataset (650 USD/Ha).",
        line04:
          "Peanut rent is assumed to be 80% higher than soybean if included in cost.",
      },
      backButton: "RETURN",
      sendButton: "SEND EMAIL",
      dialog: {
        titleFirstLineHighlight: "Report sub",
        titleFirstLine: "mission",
        contentText:
          "To receive the report please enter your email here. We will send you newsletters occasionally.",
        backButton: "RETURN",
        sendButton: "SEND EMAIL",
        changeMailButton: "Change Mail",
        sendReportStatus: {
          sending: "Sending report, please wait.",
          sent: "Report sent. Thank you for waiting.",
          fetchError:
            "There was a problem during the communication with the server. Please try again later.",
          error:
            "There was an error while sending the report. Please try again later. If the problem persist, get in touch with us.",
        },
      },
    },
  },
  // ------------------- BLOG -------------------
  blog: {
    section: "News",
    titleFirstLineHighlight: "New",
    titleFirstLine: "s",
    newArticle: "New article:",
    readMore: "READ MORE",
    recentEntries: "Recent entries:",
    previousEntries: "Previous entries:",
  },
  // ------------------- CONTACTO -------------------
  contact: {
    section: "contact",
    titleFirstLineHighlight: "The best peanu",
    titleFirstLine: "t in the world",
    titleSecondLineHighlight: "grows in o",
    titleSecondLine: "ur country.",
    formulario: {
      box01: "Name",
      box02: "Last Name",
      box03: "Email",
      box04: "Telephone number",
      drop01: {
        title: "PLANS",
        subtitle: "We are here to help you",
        desc: "Locate your farm in the map. Department",
      },
      drop02: {
        title: "INVESTMENTS",
        subtitle: "Rent",
        box01: "Company",
        box02: "Telephone number",
      },
      drop03: {
        title: "ABOUT THE PEANUT",
        subtitle: "We are here to help you",
        box01: "Message",
      },
      drop04: {
        title: "ABOUT MANISEROS",
        subtitle: "About us",
        box01: "Message",
      },
      send: "Send",
    },
  },
};
