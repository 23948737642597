import React from "react";
import i18n from "../i18n/index.js";
import "../scss/ExitoProgramas.scss";

function ExitoProgramas() {
  return (
    <>
      <section className="Exito py-5">
        <div className="grey-bg-exito"></div>
        <div className="container">
          <h3>{i18n.t("programs.sectionSeven.subtitle")}</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="clave flex-column">
                <div className="d-flex align-items-center justify-content-start pb-2">
                  <img src="/assets/soporte.svg" />
                  <p className="exitoTitle">
                    {i18n.t("programs.sectionSeven.box01")}
                  </p>
                </div>
                <p>{i18n.t("programs.sectionSeven.desc01")}</p>
              </div>
              <div className="clave flex-column">
                <div className="d-flex align-items-center justify-content-start pb-2">
                  <img src="/assets/labores.svg" />
                  <p className="exitoTitle">
                    {i18n.t("programs.sectionSeven.box02")}
                  </p>
                </div>
                <p>{i18n.t("programs.sectionSeven.desc02")}</p>
              </div>
              <div className="clave flex-column">
                <div className="d-flex align-items-center justify-content-start pb-2">
                  <img src="/assets/semilla.svg" />
                  <p className="exitoTitle">
                    {i18n.t("programs.sectionSeven.box03")}
                  </p>
                </div>
                <p>{i18n.t("programs.sectionSeven.desc03")}</p>
              </div>
              <div className="clave flex-column">
                <div className="d-flex align-items-center justify-content-start pb-2">
                  <img src="/assets/proceso.svg" />
                  <p className="exitoTitle">
                    {i18n.t("programs.sectionSeven.box04")}
                  </p>
                </div>
                <p>{i18n.t("programs.sectionSeven.desc04")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="/assets/mani-exito.png"
                alt="mani"
                className="img-mani"
              />
              {i18n.language === "es" ? (
                <img
                  src="/assets/sello-mapa.svg"
                  alt="sello"
                  className="sello-mapa"
                />
              ) : (
                <img
                  src="/assets/sello-mapa-en.png"
                  alt="sello"
                  className="sello-mapa"
                />
              )}
              <img
                src="/assets/wave-vertical.svg"
                alt="sello"
                className="wave-vertical"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExitoProgramas;
