import React from "react";
import { Bars } from "./CropmixBars";
import "../scss/bootstrap.scss";
import "../scss/Cropmix.scss";

const CropmixReport = ({
  graphicBarsData1,
  graphicBarsData2,
  calculatedData,
}) => {
  const createBar = (bars) => {
    return bars.map((barHeight) => {
      return (
        <span className="col-sm-4 barValue" key={barHeight.value}>
          {barHeight.value <= 430 && (
            <div className="colValue">{barHeight.value}</div>
          )}
          {(barHeight.secondValue || barHeight.secondValue > 0) && (
            <div
              className="pt-2"
              style={{
                height: barHeight.secondValue / 15,
                backgroundColor: "#deab5f",
              }}
            ></div>
          )}
          {(barHeight.rentValue || barHeight.rentValue > 0) && (
            <div
              className=""
              style={{
                height: barHeight.rentValue / 15,
                backgroundColor: "#a24848",
              }}
            ></div>
          )}
          <div
            className="pt-1"
            style={{
              height: barHeight.secondValue
                ? (barHeight.value - barHeight.secondValue) / 15
                : barHeight.value / 15,
              backgroundColor: barHeight.color,
            }}
          >
            {barHeight.value > 430 && barHeight.value}
          </div>
          <p className="mt-2 mb-0 barValue" style={{ minHeight: "50px" }}>
            {barHeight.label}
          </p>
        </span>
      );
    });
  };

  return (
    <div>
      <section className="Cropmix report">
        <div className="space"></div>
        <div className="container mb-2 pb-2">
          <div className="d-md-flex justify-content-between">
            <div className="ml-5">
              <img
                src="/assets/logo.svg"
                alt="deco"
                style={{ width: "300px" }}
              />
            </div>
            <p className="mr-5" style={{ fontSize: "24px" }}>
              cfaricelli@gmail.com
            </p>
          </div>
        </div>
        <div className="container mb-5 pb-3">
          <div className="formContainer bg-white mb-5">
            <div className="col">
              <div className="row pageTwo">
                <div className="col-md-6">
                  <div className="crop-2 d-flex flex-column align-items-center p-0 mt-4">
                    <Bars
                      widths={[calculatedData.sojaPer, calculatedData.maizPer]}
                      labels={["Soja", "Maíz"]}
                      colors={["#0052b5", "#152c39"]}
                      labelColor={["aliceblue", "aliceblue"]}
                    />
                    <img
                      src="/assets/arrow-bottom-blue.svg"
                      alt="arrow"
                      className="my-4"
                      style={{ width: "50px" }}
                    />
                    <div
                      className="row my-2 align-items-end reportBars"
                      style={{ height: "300px" }}
                    >
                      {createBar(graphicBarsData1)}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="crop-2 d-flex flex-column align-items-center p-0 mt-4">
                    <Bars
                      widths={[
                        calculatedData.sojaManiPer,
                        calculatedData.maizManiPer,
                        calculatedData.maniPer,
                      ]}
                      labels={["Soja", "Maíz", "Maní"]}
                      colors={["#0052b5", "#152c39", "#deab5f"]}
                      labelColor={["aliceblue", "aliceblue", "#152c39"]}
                    />
                    <img
                      src="/assets/arrow-bottom-blue.svg"
                      alt="arrow"
                      className="my-4"
                      style={{ width: "50px" }}
                    />
                    <div
                      className="row my-2 align-items-end reportBars"
                      style={{ height: "300px" }}
                    >
                      {createBar(graphicBarsData2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CropmixReport;
