import React from "react";
import i18n from "../i18n/index.js";

const CropmixForm = ({ setFormData, formData }) => {
  const handleOnChange = (target) => {
    setFormData({ ...formData, [target.name]: Number(target.value) });
  };

  return (
    <table className="table table-borderless cropmixTable">
      <thead>
        <tr>
          <th scope="col">{i18n.t("cropmix.page01.form.column01Title")}</th>
          <th scope="col">{i18n.t("cropmix.page01.form.column02Title")}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="cropmixData">
            <input
              name="sojaRto"
              value={formData.sojaRto}
              type="number"
              max={6}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropmixData">
            <input
              name="maizRto"
              value={formData.maizRto}
              type="number"
              max={15}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropminxDataName text-left">
            {i18n.t("cropmix.page01.form.row01Title")}
          </td>
        </tr>
        <tr>
          <td className="cropmixData">
            <input
              name="sojaCosto"
              value={formData.sojaCosto}
              type="number"
              max={600}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropmixData">
            <input
              name="maizCosto"
              value={formData.maizCosto}
              type="number"
              max={800}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropminxDataName text-left">
            {i18n.t("cropmix.page01.form.row02Title")}
          </td>
        </tr>
        <tr>
          <td className="cropmixData">
            <input
              name="sojaAlquiler"
              value={formData.sojaAlquiler}
              type="number"
              max={700}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropmixData">
            <input
              name="maizAlquiler"
              value={formData.maizAlquiler}
              type="number"
              max={700}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropminxDataName text-left w-50">
            {i18n.t("cropmix.page01.form.row03Title")}{" "}
            <small>{i18n.t("cropmix.page01.form.row03Subtitle")}</small>
          </td>
        </tr>
        <tr>
          <td className="cropmixData">
            <input
              name="sojaPrecio"
              value={formData.sojaPrecio}
              type="number"
              max={500}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropmixData">
            <input
              name="maizPrecio"
              value={formData.maizPrecio}
              type="number"
              max={300}
              placeholder="0"
              onChange={(e) => handleOnChange(e.target)}
            />
          </td>
          <td className="cropminxDataName text-left">
            {i18n.t("cropmix.page01.form.row04Title")}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CropmixForm;
