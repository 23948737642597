import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../scss/DropdownMenu.scss";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import "../scss/bootstrap.scss";
import i18n from "../i18n/index.js";

function DropdownMenu(props) {
  return (
    <div className="DropdownMenu">
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header px-0" id="headingTwo">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left collapsed d-flex justify-content-space-between"
                type="button"
                data-toggle="collapse"
                data-target={`#${props.dataTarget}`}
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                {props.titleDropPrograms}
                <img
                  src="/assets/arrow-bottom-blue.svg"
                  alt="flecha"
                  className="ml-3"
                />
              </button>
            </h2>
          </div>
          <div
            id={props.dataTarget}
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div className="card-body text-left">
              <ul>
                <li>
                  <Link to={props.linkZero} onClick={props.handleClick}>
                    {i18n.language === "es" ? "Aviación" : "Aviation"}
                  </Link>
                </li>
                <li>
                  <Link to={props.linkOne} onClick={props.handleClick}>
                    Fedea
                  </Link>
                </li>
                <li>
                  <Link to={props.linkTwo} onClick={props.handleClick}>
                    Basf
                  </Link>
                </li>
                <li>
                  <Link to={props.linkThree} onClick={props.handleClick}>
                    Bayer
                  </Link>
                </li>
                <li>
                  <Link to={props.linkFour} onClick={props.handleClick}>
                    Corteva
                  </Link>
                </li>
                <li>
                  <Link to={props.linkFive} onClick={props.handleClick}>
                    Nova
                  </Link>
                </li>
                <li>
                  <Link to={props.linkSix} onClick={props.handleClick}>
                    OroVerde
                  </Link>
                </li>
                <li>
                  <Link to={props.linkSeven} onClick={props.handleClick}>
                    Summit Agro
                  </Link>
                </li>
                <li>
                  <Link to={props.linkEight} onClick={props.handleClick}>
                    Syngenta
                  </Link>
                </li>
                <li>
                  <Link to={props.linkNine} onClick={props.handleClick}>
                    Sipcam
                  </Link>
                </li>
                <li>
                  <Link to={props.linkTen} onClick={props.handleClick}>
                    Stoller
                  </Link>
                </li>
                <li>
                  <Link to={props.linkEleven} onClick={props.handleClick}>
                    UPL
                  </Link>
                </li>
                <li>
                  <Link to={props.linkTwelve} onClick={props.handleClick}>
                    FMC
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropdownMenu;
