import React from 'react';
import '../scss/DropdownProgramas.scss';
import '../scss/bootstrap.scss';

function DropdownProgramas(props) {
    return (
      <div className="DropdownProgramas">
            <div className="accordion" id="accordionExample">
            <div className="card">
                <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left collapsed d-flex justify-content-space-between" type="button" data-toggle="collapse" data-target={`#${props.dataTarget}`} aria-expanded="false" aria-controls="collapseTwo">
                    {props.titleDropPrograms}
                    <img src="/assets/arrow-down.svg" alt="flecha"/>
                    </button>
                </h2>
                </div>
                <div id={props.dataTarget} className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div className="card-body text-left">
                    {props.infoInterior}
                </div>
                </div>
            </div>
                
            </div>
      </div>
    );
  }
  
  export default DropdownProgramas;
  