import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import DropdownMenu from "./DropdownMenu";
// import { useTranslation } from "react-i18next"
import i18n from "../i18n/index.js";
import "../scss/Header.scss";
const NoMatch = () => <div>404 Not Found</div>;
const ScrollToTop = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  // const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("lang") === null)
      localStorage.setItem("lang", "es");
  }, []);

  return (
    <div className="header">
      <div className="container">
        <div className="d-md-none">
          <div className="row justify-content-between align-items-center m-auto h-100">
            <div className="col-8 col-lg-2 logo">
              <Link to="/">
                {i18n.language === "es" ? (
                  <img src="/assets/logo.svg" alt="Logo Maniseros" />
                ) : (
                  <img
                    className="logomaniseros-en"
                    src="/assets/logo-en.svg"
                    alt="Maniseros Logo"
                  />
                )}
              </Link>
            </div>
            <div
              className={`menu-btn ${menuOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <div className="menu-btn__burger"></div>
            </div>
          </div>
        </div>
        <div className="row d-none d-md-flex align-items-center h-100">
          <div className="col-md-5 d-flex justify-content-start d-flex align-items-center h-100">
            <Link to="/">
              {i18n.language === "es" ? (
                <img src="/assets/logo.svg" alt="Maniseros Logo" />
              ) : (
                <img
                  className="logomaniseros-en"
                  src="/assets/logo-en.svg"
                  alt="Maniseros Logo"
                />
              )}
            </Link>
          </div>
          <div className="col-md-7">
            <div className="row justify-content-between">
              <Link to="/sobremani">{i18n.t("menu.item1")}</Link>
              <Link to="/programas">{i18n.t("menu.item2")}</Link>
              <Link to="/producto">{i18n.t("menu.item3")}</Link>
              <a
                className="nav-link dropdown-toggle mb-0"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                to="#"
              >
                {i18n.t("menu.item4")}
              </a>
              <div
                className="dropdown-menu p-0"
                aria-labelledby="navbarDropdown"
              >
                <ul className="collapseNav">
                  <li>
                    <Link to="/aviacion">
                      {i18n.language === "es" ? "Aviación" : "Aviation"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/fedea">Fedea</Link>
                  </li>
                  <li>
                    <Link to="/basf">Basf</Link>
                  </li>
                  <li>
                    <Link to="/bayer">Bayer</Link>
                  </li>
                  <li>
                    <Link to="/corteva">Corteva</Link>
                  </li>
                  <li>
                    <Link to="/nova">Nova</Link>
                  </li>
                  <li>
                    <Link to="/oroverde">OroVerde</Link>
                  </li>
                  <li>
                    <Link to="/summit">Summit Agro</Link>
                  </li>
                  <li>
                    <Link to="/syngenta">Syngenta</Link>
                  </li>
                  <li>
                    <Link to="/sipcam">Sipcam</Link>
                  </li>
                  <li>
                    <Link to="/stoller">Stoller</Link>
                  </li>
                  <li>
                    <Link to="/upl">UPL</Link>
                  </li>
                  <li>
                    <Link to="/fmc">FMC</Link>
                  </li>
                </ul>
              </div>
              <Link to="/cropmix">{i18n.t("menu.item5")}</Link>
              <Link to="/blog">{i18n.t("menu.item6")}</Link>
              <Link to="/contacto">{i18n.t("menu.item7")}</Link>
              <div className="dropdown langDropdown">
                <a
                  className="nav-link dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {localStorage.getItem("lang")?.toUpperCase() || "ES"}
                </a>
                <div
                  className="dropdown-menu p-0"
                  aria-labelledby="dropdownMenuButton"
                >
                  <ul className="collapseNav">
                    <li>
                      <button
                        onClick={() =>
                          localStorage.setItem("lang", "es") ||
                          window.location.reload()
                        }
                      >
                        ES
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          localStorage.setItem("lang", "en") ||
                          window.location.reload()
                        }
                      >
                        EN
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-none">
        <div className={`d-none ${menuOpen ? "menuBurger" : ""}`}>
          <div className="row">
            <div className="col-12">
              <ul className="d-flex flex-column px-3">
                <li className="row mx-1">
                  <Link to="/sobremani" onClick={toggleMenu}>
                    {i18n.t("menu.item1")}
                  </Link>
                  <img
                    src="/assets/arrow-next-blue.svg"
                    alt="flecha"
                    className="ml-3 flechitaIn"
                  />
                </li>
                <li className="row mx-1">
                  <Link to="/programas" onClick={toggleMenu}>
                    {i18n.t("menu.item2")}
                  </Link>
                  <img
                    src="/assets/arrow-next-blue.svg"
                    alt="flecha"
                    className="ml-3 flechitaIn"
                  />
                </li>
                <li className="row mx-1">
                  <Link to="/producto" onClick={toggleMenu}>
                    {i18n.t("menu.item3")}
                  </Link>
                  <img
                    src="/assets/arrow-next-blue.svg"
                    alt="flecha"
                    className="ml-3 flechitaIn"
                  />
                </li>
                <li className="mx-2 px-2">
                  <DropdownMenu
                    titleDropPrograms={i18n.t("menu.item4")}
                    dataTarget="aliados"
                    linkZero="/aviacion"
                    linkOne="/fedea"
                    linkTwo="/basf"
                    linkThree="/bayer"
                    linkFour="/corteva"
                    linkFive="/nova"
                    linkSix="/oroverde"
                    linkSeven="/summit"
                    linkEight="/syngenta"
                    linkNine="/sipcam"
                    linkTen="/stoller"
                    linkEleven="/upl"
                    linkTwelve="/fmc"
                    handleClick={toggleMenu}
                  />
                </li>
                <li className="row mx-1">
                  <Link to="/cropmix" onClick={toggleMenu}>
                    {i18n.t("menu.item5")}
                  </Link>
                  <img
                    src="/assets/arrow-next-blue.svg"
                    alt="flecha"
                    className="ml-3 flechitaIn"
                  />
                </li>
                <li className="row mx-1">
                  <Link to="/blog" onClick={toggleMenu}>
                    {i18n.t("menu.item6")}
                  </Link>
                  <img
                    src="/assets/arrow-next-blue.svg"
                    alt="flecha"
                    className="ml-3 flechitaIn"
                  />
                </li>
                <li className="row mx-1">
                  <Link to="/contacto" onClick={toggleMenu}>
                    {i18n.t("menu.item7")}
                  </Link>
                  <img
                    src="/assets/arrow-next-blue.svg"
                    alt="flecha"
                    className="ml-3 flechitaIn"
                  />
                </li>

                <li className="row mx-1">
                  <div className="DropdownMenu">
                    <div className="accordion" id="accordionExample">
                      <div className="card">
                        <div className="card-header px-0" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left collapsed d-flex justify-content-space-between"
                              type="button"
                              data-toggle="collapse"
                              data-target="#langMenuDropdown"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              {localStorage.getItem("lang")?.toUpperCase() ||
                                "ES"}
                              <img
                                src="/assets/arrow-bottom-blue.svg"
                                alt="flecha"
                                className="ml-3"
                              />
                            </button>
                          </h2>
                        </div>
                        <div
                          id="langMenuDropdown"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body text-left">
                            <ul>
                              <li>
                                <button
                                  onClick={() =>
                                    localStorage.setItem("lang", "es") ||
                                    window.location.reload()
                                  }
                                >
                                  ES
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={() =>
                                    localStorage.setItem("lang", "en") ||
                                    window.location.reload()
                                  }
                                >
                                  EN
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
