import React from 'react'
import i18n from "../i18n/index.js";

const CropmixButton = ({label, containerClassName, color, size, buttonAction, buttonDirection, disabled}) => {
    //  label is the name to display in button
    //  containerClassName applies to the button container it is used to set justify-content
    //  size sets the button with
    //  color indicates if shows in blue or darkblue
    //  buttonAction set onClick behaviour
    //  buttonDirection sets the flex direction
    return (
        <div className={containerClassName}>
            <div className={`${size || 'col-md-3'} btn-send ${color}`}>
                <button
                    className={`send w-100 d-flex justify-content-between align-items-center flex-${buttonDirection || 'row'}` }
                    onClick={buttonAction? buttonAction : null}
                    disabled={disabled}
                    style={{cursor: disabled? 'not-allowed' : 'pointer'}}
                >
                    {label}
                    <img
                    src={
                        label === i18n.t("cropmix.page02.sendButton") || 
                        label === i18n.t("cropmix.page01.button") || 
                        label === i18n.t("cropmix.page02.dialog.sendButton") ||
                        label === i18n.t("cropmix.page02.backButton") ? "/assets/arrow-next.svg" : "/assets/arrow-bottom.svg" }
                    alt="arrow"
                    className="arrow-cta"
                    style={{ transform: label === i18n.t("cropmix.page02.backButton") ? 'rotate(180deg)' : null }}
                    />
                </button>
            </div>
        </div>
    )
}

export default CropmixButton
