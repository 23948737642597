import React from 'react';
import DropdownContacto from './DropdownContacto';
import Highlights from './Highlights';
import i18n from '../i18n/index.js'
import '../scss/bootstrap.scss';
import '../scss/Contacto.scss';

import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import { useRecoilState } from 'recoil';
import { formState } from '../atom'

function Contacto() {
    // const [name, setName] = useRecoilState(nameState)
    // const [email, setEmail] = useRecoilState(emailState)
    
    // const handleInputName = event => setName(event.target.value)
    // const handleInputEmail = event => setEmail(event.target.value)


    ///////////////////////////

    const [form, setForm] = useRecoilState(formState)
    const onChange = event => setForm({...form, [event.target.name]: event.target.value})

    const {name, surname, email, phone} = form

    return (
      <div className="Contacto py-5">
          <img src="/assets/deco-gold.svg" alt="deco" className="deco-gold-contacto" />
          <div className="gold-contact"></div>
          <div className="container">
            <p className="epigrafe-contacto">{i18n.t('home.sectionFive.subtitle')}</p>
            <div className="d-none d-md-flex">
                <Highlights classNameH="interna"
                firstLineH={i18n.t('home.sectionFive.titleFirstLineHighlight')} firstLine={i18n.t('home.sectionFive.titleFirstLine')}
                secondLineH={i18n.t('home.sectionFive.titleSecondLineHighlight')} secondLine={i18n.t('home.sectionFive.titleSecondLine')}/>
            </div>
            <div className="d-md-none">
                <Highlights classNameH="interna"
                firstLineH={i18n.t('home.sectionFive.mobile.titleFirstLineHighlight')} firstLine={i18n.t('home.sectionFive.mobile.titleFirstLine')}
                secondLineH={i18n.t('home.sectionFive.mobile.titleSecondLineHighlight')} secondLine={i18n.t('home.sectionFive.mobile.titleSecondLine')}
                thirthLineH={i18n.t('home.sectionFive.mobile.titleThirdLineHightlight')} thirthLine={i18n.t('home.sectionFive.mobile.titleThirdLine')}/>
            </div>
            <div className="Form">
                <form className="col-12">
                    <div className="row formClassic">
                        <div className="col-md-3 pl-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('formSimple.field1')}</label>
                            <input className="textInput" name='name' value={name} onChange={onChange}></input>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0 pl-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('formSimple.field2')}</label>
                            <input className="textInput" name='surname' value={surname} onChange={onChange}></input>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0 pl-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('formSimple.field3')}</label>
                            <input className="textInput" name='email' value={email} onChange={onChange}></input>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0 pl-md-1 pl-md-auto pr-md-1 d-flex flex-column text-left">
                            <label>{i18n.t('formSimple.field4')}</label>
                            <input name='phone' value={phone} onChange={onChange} className="textInput"></input>
                        </div>
                        <div className="col-10 d-flex d-md-none justify-content-end" id="btn-contacto-vm">
                            <Link to="/contacto" className="submitBtn mt-4">
                                <div className="row justify-content-between px-3">
                                    <p>{i18n.t('formSimple.send')}</p>
                                    <img src="/assets/arrow-next.svg" alt="arrow"/>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row d-none d-md-flex">
                        <h4 className="motivo">{i18n.t('formSimple.title')}</h4>
                    </div>
                    <div className="row d-none d-md-flex">
                        <div className="col-12 col-md-3">
                            <Link to="/contacto">
                                <div className="button-contact row justify-content-between">
                                    <p>{i18n.t('formSimple.box01')}</p>
                                    <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-md-3">
                            <Link to="/contacto" className="w-100">
                                <div className="button-contact row justify-content-between">
                                    <p>{i18n.t('formSimple.box02')}</p>
                                    <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-md-3">
                            <Link to="/contacto">
                                <div className="button-contact row justify-content-between">
                                    <p>{i18n.t('formSimple.box03')}</p>
                                    <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-md-3">
                            <Link to="/contacto">
                                <div className="button-contact row justify-content-between">
                                    <p>{i18n.t('formSimple.box04')}</p>
                                    <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
          </div>
      </div>
    );
  }
  
  export default Contacto;
  