import React from "react";
import Highlights from "./Highlights";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import i18n from "../i18n/index.js";
import "../scss/Grafico.scss";
const data = [
  { name: "2010", CajaRinde: 2.79, pv: 495, amt: 2400 },
  { name: "2011", CajaRinde: 2.65, pv: 704, amt: 2200 },
  { name: "2012", CajaRinde: 2.23, pv: 767, amt: 2200 },
  { name: "2013", CajaRinde: 2.54, pv: 2300, amt: 2200 },
  { name: "2014", CajaRinde: 2.85, pv: 2300, amt: 2200 },
  { name: "2015", CajaRinde: 2.38, pv: 2300, amt: 2200 },
  { name: "2016", CajaRinde: 2.93, pv: 2300, amt: 2200 },
  { name: "2017", CajaRinde: 3.24, pv: 2300, amt: 2200 },
  { name: "2018", CajaRinde: 2.08, pv: 2300, amt: 2200 },
  { name: "2019", CajaRinde: 3.46, pv: 2300, amt: 2200 },
  { name: "2020", CajaRinde: 4.51, pv: 2300, amt: 2200 },
];

// var data = {
//     // A labels array that can contain any sort of values
//     labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
//     // Our series array that contains series objects or in this case series data arrays
//     series: [
//       [5, 2, 4, 2, 0]
//     ]
//   };

//   // Create a new line chart object where as first parameter we pass in a selector
//   // that is resolving to our chart container element. The Second parameter
//   // is the actual data object.
//   new Chartist.Line('.ct-chart', data);

function getIntroOfPage(label) {
  if (label === "2010") {
    return "495";
  }
  if (label === "2011") {
    return "704";
  }
  if (label === "2012") {
    return "767";
  }
  if (label === "2013") {
    return "617";
  }
  if (label === "2014") {
    return "540";
  }
  if (label === "2015") {
    return "482";
  }
  if (label === "2016") {
    return "650";
  }
  if (label === "2017") {
    return "611";
  }
  if (label === "2018") {
    return "547";
  }
  if (label === "2019") {
    return "547";
  }
  if (label === "2020") {
    return "627";
  }
}
function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <p className="intro">
          {i18n.t("product.sectionSix.box01")} <span>{payload[0].value}</span>
        </p>
        <p className="desc">
          {i18n.t("product.sectionSix.box02")}
          <span>{getIntroOfPage(label)}</span>
        </p>
      </div>
    );
  }

  return null;
}
function Grafico() {
  return (
    <>
      <div className="grafico py-5">
        <div className="d-none d-md-block grafico-desktop py-3">
          {/* <div class="ct-chart ct-perfect-fourth"></div> */}
          <p className="aclaracion">
            {i18n.t("product.sectionFour.titlegraph01")}
          </p>
          <p className="aclaracion">
            {i18n.t("product.sectionFour.titlegraph02")}
          </p>
          <div className="row py-2">
            <div className="col-md-2 num-desk">
              <div className="row mt-3">
                <div className="col-6 info-cuadro d-flex flex-column-reverse">
                  <p>495</p>
                  <p>704</p>
                  <p>767</p>
                  <p>617</p>
                  <p>540</p>
                  <p>482</p>
                  <p>620</p>
                  <p>611</p>
                  <p>547</p>
                  <p>547</p>
                  <p>627</p>
                </div>
                <div className="col-6 info-cuadro d-flex flex-column-reverse">
                  <p>2,79</p>
                  <p>2,65</p>
                  <p>2,23</p>
                  <p>2,54</p>
                  <p>2,85</p>
                  <p>2,38</p>
                  <p>2,93</p>
                  <p>3,24</p>
                  <p>2,08</p>
                  <p>3,46</p>
                  <p>4,51</p>
                </div>
                <div className="row mt-3">
                  <div className="col-6 pr-0 text-center">
                    <p className="hl text-center">
                      <Highlights
                        classNameH="interna"
                        firstLineH={i18n.t("product.sectionSix.box02")}
                      />
                    </p>
                  </div>
                  <div className="col-6 pr-0">
                    <p className="hl">
                      <Highlights
                        classNameH="interna"
                        firstLineH={i18n.t("product.sectionSix.box01")}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10 py-3">
              <ResponsiveContainer width="100%">
                <LineChart height={350} data={data}>
                  <Line type="monotone" dataKey="CajaRinde" stroke="#DEAB5F" />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="d-md-none">
          <div className="cuadro">
            <p className="desc-cuadro">
              {i18n.t("product.sectionFour.titlegraph01")}
              {i18n.t("product.sectionFour.titlegraph02")}
            </p>
            <div className="row">
              <div className="col-4">
                <p>{i18n.t("product.sectionSix.box01Mobile")}</p>
              </div>
              <div className="col-4">
                <p>{i18n.t("product.sectionSix.box02")}</p>
              </div>
              <div className="col-4">
                <p>{i18n.t("product.sectionSix.box01")}</p>
              </div>
              <div className="col-4 info-cuadro">
                <p>2010</p>
                <p>2011</p>
                <p>2012</p>
                <p>2013</p>
                <p>2014</p>
                <p>2015</p>
                <p>2016</p>
                <p>2017</p>
                <p>2018</p>
                <p>2019</p>
                <p>2020</p>
              </div>
              <div className="col-4 info-cuadro">
                <p>495</p>
                <p>704</p>
                <p>767</p>
                <p>617</p>
                <p>540</p>
                <p>482</p>
                <p>620</p>
                <p>611</p>
                <p>547</p>
                <p>547</p>
                <p>627</p>
              </div>
              <div className="col-4 info-cuadro">
                <p>2,79</p>
                <p>2,65</p>
                <p>2,23</p>
                <p>2,54</p>
                <p>2,85</p>
                <p>2,38</p>
                <p>2,93</p>
                <p>3,24</p>
                <p>2,08</p>
                <p>3,46</p>
                <p>4,51</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Grafico;
