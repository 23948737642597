import React from "react";
import Highlights from "./Highlights";
import ModuloProductoEtapa from "./ModuloProductoEtapa";
// import CarouselAliados from "./CarouselAliados";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "../scss/Aliados.scss";
import i18n from "../i18n/index.js";

function Sipcam() {
  return (
    <>
      <section className="aliado-Fedea">
        <div className="space"></div>
        <div className="Hero">
          <img
            className="banner-hero"
            src="/assets/herp-sipmap.png"
            alt="hero"
          />
          <div className="info-banner">
            <div className="sectionclass">
              <p>Partners</p>
            </div>
            <div className="nombre-aliado">
              <img
                src="/assets/logo-sipcam.png"
                alt="logo"
                className="logo-aliado"
              />
              <Highlights
                classNameH="interna"
                firstLineH={i18n.t(
                  "partners.sipcam.sectionOne.titleFirstLineHighlight"
                )}
                firstLine={i18n.t("partners.sipcam.sectionOne.titleFirstLine")}
                secondLineH={i18n.t(
                  "partners.sipcam.sectionOne.titleSecondLineHighlight"
                )}
                secondLine={i18n.t(
                  "partners.sipcam.sectionOne.titleSecondLine"
                )}
              />
            </div>
          </div>
        </div>
        <div className="descripccion py-5 mt-5 mt-md-0">
          <img
            src="/assets/deco-gold.svg"
            alt="deco"
            className="deco-descrips"
          />
          <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">
                  {i18n.t("partners.sipcam.sectionTwo.title01")}
                </p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.sipcam.sectionTwo.desc01")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">
                    {i18n.t("partners.sipcam.sectionTwo.desc02")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios py-5">
          <div className="blue-servicio"></div>
          <div className="little-gold"></div>
          <div className="big-gold"></div>
          <div className="grey-servicios"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
          <div className="container">
            <div className="grey-servicio"></div>
            <h3>{i18n.t("partners.sipcam.sectionThree.title")}</h3>
            <div className="row py-3 mx-3 mx-md-0">
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.sipcam.sectionThree.box01.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa12=" "
                  imgparter01="/assets/effectux.png"
                  href01="https://sipcam.com.ar/producto/effectux-me-max-herbicida-barbecho-post-emergente-rama-negra-cerraja"
                  imgpartner="sipcam-partner"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.sipcam.sectionThree.box02.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa12=" "
                  imgparter01="/assets/terbyne.png"
                  href01="https://sipcam.com.ar/producto/terbyne-herbicida-barbecho-maiz-residual-trigo-cebada-cultivos-de-servicio-cultivos-de-economias-regionales"
                  imgpartner="sipcam-partner"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.sipcam.sectionThree.box03.title"
                  )}
                  imgEtapa="../assets/planta2.jpg"
                  claseImgEtapa12=" "
                  imgparter01="/assets/terbyne.png"
                  href01="https://sipcam.com.ar/producto/terbyne-herbicida-barbecho-maiz-residual-trigo-cebada-cultivos-de-servicio-cultivos-de-economias-regionales"
                  imgpartner="sipcam-partner"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.sipcam.sectionThree.box04.title"
                  )}
                  imgEtapa="../assets/planta6.jpg"
                  claseImgEtapa12=" "
                  imgparter01="/assets/echostick.png"
                  href01="https://sipcam.com.ar/producto/echo-stick-clorothalonil-fungicida-mani-papa"
                  imgpartner="sipcam-partner"
                />
              </div>
              <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa
                  titleEtapa={i18n.t(
                    "partners.sipcam.sectionThree.box05.title"
                  )}
                  imgEtapa="../assets/planta00.jpg"
                  claseImgEtapa12=" "
                  imgparter01="/assets/terbyne.png"
                  href01="https://sipcam.com.ar/producto/terbyne-herbicida-barbecho-maiz-residual-trigo-cebada-cultivos-de-servicio-cultivos-de-economias-regionales"
                  imgpartner="sipcam-partner"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Beneficios py-5 d-md-flex">
          <img
            src="/assets/gold-beneficios.svg"
            alt="deco"
            className="gold-beneficios"
          />
          <div className="container">
            <h4 className="mb-4">
              {i18n.t("partners.sipcam.sectionFour.title")}
            </h4>
            <div className="row">
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <img
                      src="/assets/terbyne.png"
                      alt="multiplica"
                      className="imgter"
                    />
                    <p className="dest mb-3">
                      {i18n.t("partners.sipcam.sectionFour.box01.title")}
                    </p>
                    <p>{i18n.t("partners.sipcam.sectionFour.box01.content")}</p>
                    <a
                      className="dest vervideo"
                      href="https://www.youtube.com/watch?v=CChkMu64BCw"
                      style={{ marginTop: "38px" }}
                    >
                      {i18n.t("partners.sipcam.sectionFour.box01.cta")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <img
                      src="/assets/terbyne.png"
                      alt="multiplica"
                      className="imgter"
                    />
                    <p className="dest mb-3">
                      {i18n.t("partners.sipcam.sectionFour.box02.title")}
                    </p>
                    <p>{i18n.t("partners.sipcam.sectionFour.box02.content")}</p>
                    <a
                      className="dest vervideo"
                      href="https://www.youtube.com/watch?v=lNSvshjl8Jk"
                    >
                      {i18n.t("partners.sipcam.sectionFour.box02.cta")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cuadro-container">
                  <div className="d-flex flex-column">
                    <p className="dest mb-3">
                      {i18n.t("partners.sipcam.sectionFour.box03.title")}
                    </p>
                    <p>{i18n.t("partners.sipcam.sectionFour.box03.content")}</p>
                    <div className="row pl-2 mt-2">
                      <a
                        href="https://www.instagram.com/sipcamargentina"
                        className="sm"
                      >
                        <img src="/assets/sm_insta.png" alt="Instagram" />
                      </a>
                      <a
                        href="https://www.facebook.com/sipcamargentina"
                        className="sm"
                      >
                        <img src="/assets/sm_face.png" alt="Facebook" />
                      </a>
                      <a
                        href="https://ar.linkedin.com/company/sipcam-argentina"
                        className="sm"
                      >
                        <img src="/assets/sm_linkedin.png" alt="Linkedin" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UC3ZCrvUh8BVU3NQVhh5RWGg/videos"
                        className="sm"
                      >
                        <img src="/assets/sm_youtube.png" alt="Youtube" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cta-aliados py-5">
          <div className="container">
            <div className="col-12">
              <div href="#" className="interesado-aliados">
                <Link to="/contacto">
                  <div className="row justify-content-center align-items-center">
                    <p>{i18n.t("partners.cta")}</p>
                    <img
                      src="/assets/arrow-next.svg"
                      alt="arrow"
                      className="arrow-cta"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Sipcam;
