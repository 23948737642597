import React from "react";
import "../scss/ModuloProductoEtapa.scss";

const ProdPlusProd = ({ item, imgClass }) => {
  const { prod1, prod2 } = item;
  return (
    <div className="d-flex px-0 justify-content-center align-content-center mb-2 fmc-mobile">
      <a href={prod1.href} target="_blank" className="d-flex" rel="noreferrer">
        <img
          src={prod1.partnerImg}
          className={imgClass}
          style={{ maxWidth: "135px", maxHeight: "50px" }}
          alt="Producto"
        />
      </a>
      <strong className="d-flex p-2 m-0">+</strong>
      <a href={prod2.href} target="_blank" className="d-flex" rel="noreferrer">
        <img
          src={prod2.partnerImg}
          className={imgClass}
          style={{ maxWidth: "135px", maxHeight: "50px" }}
          alt="Producto"
        />
      </a>
    </div>
  );
};

function ModuloProductoEtapa(props) {
  return (
    <div className="ModuloProductoEtapa">
      <div className="col-12">
        {props.titleEtapa && <p className="titleEtapa"> {props.titleEtapa} </p>}
        <div className="row">
          <div className="col-4 w-100 h-100 position-relative">
            <div className="position-absolute tierra-aliados">
              <img src="/assets/tierra-aliados.svg" alt="" />
            </div>
            {props.imgEtapa && (
              <img
                src={props.imgEtapa}
                className="imgEtapa"
                alt={props.titleEtapa}
              />
            )}
          </div>
          {props.heap && !props.prodPlusProd && (
            <div className="col-8 px-0 d-flex justify-content-center">
              <div
                className="d-flex flex-column justify-content-end"
                style={{ minWidth: "170px" }}
              >
                {props.heap.map((item) => {
                  return (
                    <>
                      <a
                        href={item.href}
                        target="_blank"
                        className="d-flex"
                        style={{ width: "fit-content" }}
                        rel="noreferrer"
                      >
                        <img
                          src={item.partnerImg}
                          className={props.imgClass}
                          style={{ maxWidth: "fit-content" }}
                          alt="Producto"
                        />
                      </a>
                    </>
                  );
                })}
              </div>
            </div>
          )}
          {props.heap && props.prodPlusProd && (
            <div className="col-8 px-0 d-flex justify-content-center">
              <div
                className="d-flex flex-column justify-content-center"
                style={{ minWidth: "170px" }}
              >
                {props.heap.map((item) => {
                  return (
                    <>
                      <ProdPlusProd item={item} imgClass="" />
                    </>
                  );
                })}
              </div>
            </div>
          )}
          {!props.heap && (
            <div className="col-8 d-flex px-0">
              {props.dosxdos && (
                <div className="d-flex justify-content-around flex-wrap align-content-around">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner1}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner2}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter03}
                      className={props.imgpartner3}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href04}
                    target="_blank"
                    className="d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter04}
                      className={props.imgpartner4}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.infoEtapa && (
                <div>
                  <p className="infoEtapa pl-md-1">{props.infoEtapa}</p>
                  <p className="infoEtapa pl-md-1">{props.infoEtapa2}</p>
                  <p className="infoEtapa pl-md-1">{props.infoEtapa3}</p>
                  <p className="infoEtapa pl-md-1">{props.infoEtapa4}</p>
                  <p className="infoEtapa pl-md-1">{props.infoEtapa5}</p>
                  <p className="infoEtapa pl-md-1">{props.infoEtapa6}</p>
                </div>
              )}

              {props.claseImgEtapa7 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter03}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href04}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter04}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href05}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter05}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href06}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter06}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href07}
                    target="_blank"
                    className="col-6 col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter07}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}

              {props.claseImgEtapa6 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-6 col-md-4 d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-6 col-md-4 d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="col-6 col-md-4 d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter03}
                      className={props.imgpartnerp}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href04}
                    target="_blank"
                    className="col-6 col-md-4 d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter04}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href05}
                    target="_blank"
                    className="col-6 col-md-4 d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter05}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href06}
                    target="_blank"
                    className="col-6 col-md-4 d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter06}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.claseImgEtapa3v6 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter03}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.claseImgEtapa6siembra && (
                <div className="row h-sp">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner201}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner202}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter03}
                      className={props.imgpartner203}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href04}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter04}
                      className={props.imgpartner204}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href05}
                    target="_blank"
                    className="col-md-4 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter05}
                      className={props.imgpartner205}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href06}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter06}
                      className={props.imgpartner206}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.claseImgEtapa32 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    {props.imgparter03 && (
                      <img
                        src={props.imgparter03}
                        className={props.imgpartner}
                        alt="Producto"
                      />
                    )}
                  </a>
                </div>
              )}
              {props.claseImgEtapa4 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner1}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner2}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href03}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter03}
                      className={props.imgpartner3}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href04}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter04}
                      className={props.imgpartner4}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.claseImgEtapa2 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner01}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-md-6 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner02}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.claseImgEtapa212 && (
                <div className="row">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="col-12 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                  <a
                    href={props.href02}
                    target="_blank"
                    className="col-12 d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter02}
                      className={props.imgpartner2}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
              {props.claseImgEtapa12 && (
                <div className="">
                  <a
                    href={props.href01}
                    target="_blank"
                    className="d-flex"
                    rel="noreferrer"
                  >
                    <img
                      src={props.imgparter01}
                      className={props.imgpartner}
                      alt="Producto"
                    />
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModuloProductoEtapa;
