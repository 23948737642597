import React from "react";
import CropmixForm from "./CropmixForm";
import CropmixButton from "./CropmixButton";
import { Bars } from "./CropmixBars";
import Slider from "@material-ui/core/Slider";
import i18n from "../i18n/index.js";

const CropmixFormPageOne = ({ formData, setFormData }) => {
  const marks = [
    {
      value: 0,
      label: "",
    },
    {
      value: 10,
      label: "",
    },
    {
      value: 20,
      label: "",
    },
    {
      value: 30,
      label: "",
    },
    {
      value: 40,
      label: "",
    },
    {
      value: 50,
      label: "",
    },
    {
      value: 60,
      label: "",
    },
    {
      value: 70,
      label: "",
    },
    {
      value: 80,
      label: "",
    },
    {
      value: 90,
      label: "",
    },
    {
      value: 100,
      label: "",
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-mt-5 pb-3 text-left">
            {i18n.t("cropmix.page01.section01.title")}
          </h5>
          <div className="crop-2 d-flex flex-column align-items-center p-0 ">
            <Bars
              labels={[
                i18n.t("cropmix.page02.bars.soy"),
                i18n.t("cropmix.page02.bars.corn"),
              ]}
              widths={[formData.sojaPer, formData.maizPer]}
              colors={["#0052b5", "#152c39"]}
              labelColor={["aliceblue", "aliceblue"]}
            />

            <div className="w-100 cropmixSlider p-4">
              <div className="mb-3 text-light">
                <strong>{i18n.t("cropmix.page01.section01.soy")}</strong>
                <div className="d-flex justify-content-between mb-1">
                  <small>{i18n.t("cropmix.page01.section01.lowValue")}</small>
                  <small>{i18n.t("cropmix.page01.section01.highValue")}</small>
                </div>
              </div>
              <Slider
                valueLabelDisplay="off"
                className="slider"
                defaultValue={Math.round(formData.sojaPer * 100)}
                aria-labelledby="discrete-slider-custom"
                step={null}
                marks={marks}
                onChange={(_, value) =>
                  setFormData({
                    ...formData,
                    sojaPer: Number((value / 100).toFixed(2)),
                    maizPer: Number(((100 - value) / 100).toFixed(2)),
                  }) ||
                  console.log("value", value, "/", "sec value", 100 - value)
                }
              />
              <div className="mt-3 text-light">
                <div className="d-flex justify-content-between mt-1">
                  <small>{i18n.t("cropmix.page01.section01.highValue")}</small>
                  <small>{i18n.t("cropmix.page01.section01.lowValue")}</small>
                </div>
                <strong>{i18n.t("cropmix.page01.section01.corn")}</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <h5 className="mb-mt-5 pb-3 text-left">
            {i18n.t("cropmix.page01.section02.title")}
          </h5>
          <div className="crop-2 d-flex justify-content-center align-items-center p-4 mt-2">
            <CropmixForm setFormData={setFormData} formData={formData} />
          </div>
        </div>
      </div>
      <span className="d-flex justify-content-end w-100">
        <CropmixButton
          label={i18n.t("cropmix.page01.button")}
          containerClassName="d-flex mt-4 justify-content-end w-100"
          size="col-md-3 p-2"
          disabled={
            formData.sojaRto === "0" || formData.sojaRto === ""
              ? // formData.sojaCosto === "0" ||
                // formData.sojaCosto === "" ||
                // formData.sojaPrecio === "0" ||
                // formData.sojaPrecio === "" ||
                // formData.maizRto === "0" ||
                // formData.maizRto === ""
                // formData.maizCosto === "0" ||
                // formData.maizCosto === "" ||
                // formData.maizPrecio === "0" ||
                // formData.maizPrecio === "" ||
                true
              : false
          }
        />
      </span>
    </>
  );
};

export default CropmixFormPageOne;
